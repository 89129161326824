:root {
    --tailleGd    : 42px;
    --tailleGdSpe : 10px;
    --taillePt    : 55px;
    --taillePtSpe : 12px;
}

#zoneInfoCarte {
    display       : flex;
    /*  height  : 200px;*/
    margin-bottom : 4px;
    width         : 100%;

    &.fixedTop {
        position : sticky;
        top      : var(--top-decalage);
        z-index  : 500;
    }

    &.movable {
        background-color : var(--primary-color);
        border           : 1px solid var(--primary-border-color);
        overflow         : auto;
        position         : absolute;
        resize           : both;
        width            : 1225px;
        z-index          : 100;

        #move_info_carte_button {
            cursor     : move;
            display    : initial !important;
            float      : left;
            margin     : auto;
            text-align : center;
            width      : 25px;
        }
    }
}

.cat_bordureGauche {
    border-right : 1px solid var(--primary-border-color);
}

.cat_bordureBas {
    border-bottom : 1px solid var(--primary-border-color);
}

#zoneCarte {
    background-color : var(--secondary-color);
    margin           : auto;

    table {
        tr, td, th {
            margin  : 0;
            padding : 0;
        }
    }
}

.optionCarteVisible {
    display : block;
}

.optionCarteNonVisible {
    display : none;
}

.optionCarteVisible.optionCarteKmVisible {
    display : block;
}

.optionCarteVisible.optionCarteKmNonVisible {
    display : none;
}

.c12 {
    width : calc(var(--taillePt) * 12 + 50px);
}

.c13 {
    width : calc(var(--taillePt) * 13 + 50px);
}

.c14 {
    width : calc(var(--taillePt) * 14 + 50px);
}

.c25 {
    width : calc(var(--tailleGd) * 25 + 50px);
}

.c26 {
    width : calc(var(--tailleGd) * 26 + 50px);
}

.c27 {
    width : calc(var(--tailleGd) * 27 + 50px);
}

.ligneCarte {
    display : flex;
    margin  : 0;

    td.bordHautBasCarte_gdCarte {
        height      : 25px;
        line-height : 25px;
        text-align  : center;
        width       : var(--tailleGd);
    }

    td.bordHautBasCarte_ptCarte {
        height      : 25px;
        line-height : 25px;
        text-align  : center;
        width       : var(--taillePt);
    }

    td.bordGaucheDroiteCarte_gdCarte {
        height      : var(--tailleGd);
        line-height : var(--tailleGd);
        text-align  : center;
        width       : 25px;
    }

    td.bordGaucheDroiteCarte_ptCarte {
        height      : var(--taillePt);
        line-height : var(--taillePt);
        text-align  : center;
        width       : 25px;
    }

    td.coinCarte {
        height : 25px;
        width  : 25px;
    }
}

.gdCarte {
    height : var(--tailleGd);
    width  : var(--tailleGd);
}

.ptCarte {
    height : var(--taillePt);
    width  : var(--taillePt);
}

.gdCarteSpe {
    height : var(--tailleGdSpe);
    width  : var(--tailleGdSpe);
}

.ptCarteSpe {
    height : var(--taillePtSpe);
    width  : var(--taillePtSpe);
}

.fondNoir {
    background-color : black;
    color            : white;
    text-align       : center;
    vertical-align   : center;
}

.caseCarte {
    position : relative;
}

.caseCarte > span {
    display    : block;
    height     : 100%;
    text-align : center;
    width      : 100%;
    z-index    : 15;
}

.caseVille {
    height   : 100%;
    position : absolute;
    width    : 100%;
    z-index  : 10;

    .picto_home_ville {
        align-items     : center;
        display         : flex;
        height          : 100%;
        justify-content : center;
        width           : 100%;
    }
}

svg[class^="epuise"] {
    position : absolute;
    z-index  : 6;
}

svg[class^="camp"] {
    position : absolute;
    z-index  : 7;
}


div[class^="bat_icone"] {
    position : absolute;
    z-index  : 6;

    > svg[class^="bat_svg"] {
        filter   : drop-shadow(2px 2px 2px #000000);
        position : absolute;
        z-index  : 6;
    }

    > svg[class^="bat_croix"] {
        position : absolute;
        z-index  : 7;
    }
}

div[class^="bat_square"] {
    position : absolute;
    z-index  : 6;

    > svg[class^="bat"] {
        position : absolute;
        z-index  : 8;
    }

    > svg[class^="bat_croix"] {
        position : absolute;
        z-index  : 9;
    }
}


svg[class^="balisage"] {
    position : absolute;
    z-index  : 17;
}

svg[class^="zombReel"] {
    position : absolute;
    z-index  : 17;
}

svg[class^="zombEstim"] {
    position : absolute;
    z-index  : 17;
}

svg[class^="quadrillage"] {
    color    : rgba(0, 0, 0, 0.2);
    position : absolute;
    z-index  : 10;
}

div[class^="objetSolDiv"] {
    align-items     : flex-start;
    display         : flex;
    justify-content : flex-end;
    margin-top      : 1px;
    position        : absolute;
    z-index         : 17;
}

svg[class^="objetSol"] {
    position : absolute;
    z-index  : 17;
}

svg[class^="pa_"] {
    position : absolute;
    z-index  : 11;
}

svg[class^="zonage_"] {
    position : absolute;
    z-index  : 14;
}

svg[class^="km_"] {
    position : absolute;
    z-index  : 13;
}

svg[class^="scrut"] {
    position : absolute;
    z-index  : 12;
}

svg[class^="indicVisit"] {
    position : absolute;
    z-index  : 17;
}

svg[class^="posJoueur"] {
    position : absolute;
    z-index  : 20;
}

svg[class^="consigneExpe"] {
    position : absolute;
    z-index  : 30;
}

div[class^="citoyensVilleDiv"] {
    align-items     : flex-end;
    display         : flex;
    justify-content : flex-end;
    position        : absolute;
    z-index         : 21;
}

.carteAlter {

    position : absolute;
    z-index  : 25;

    div.divCarteAlter {
        align-content   : flex-start;
        align-items     : flex-start;
        display         : flex;
        justify-content : flex-end;

        span.itemDechargeable {
            display      : inline-block;
            font-size    : 0.9rem;
            height       : 20px;
            line-height  : 20px;
            margin-right : 2px;
            padding      : 0;
            text-align   : end;
            width        : 20px;
        }
    }

}

.carteScrut {

    position : absolute;
    z-index  : 25;

    div.divCarteScrut {
        align-content   : flex-start;
        align-items     : flex-start;
        display         : flex;
        justify-content : flex-end;

        span.itemRegenerer {
            display      : inline-block;
            font-size    : 0.9rem;
            height       : 20px;
            line-height  : 20px;
            margin-right : 2px;
            padding      : 0;
            text-align   : end;
            width        : 20px;
        }
    }

}

svg[class^="select_1"] {
    position : absolute;
    z-index  : 15;
}

svg[class^="select_2"] {
    position : absolute;
    z-index  : 15;
}

svg[class^="select_3"] {
    position : absolute;
    z-index  : 15;
}

.expeditionVille {
    position : absolute;
    z-index  : 35;
}

svg[class^="visuCase"] {
    position : absolute;
    z-index  : 50;
}

svg[class^="miniIcone"], svg[class^="miniIconeMulti"] {
    padding  : 2px;
    position : absolute;
    z-index  : 45;
}

svg[class^="select_1"]:not(svg[class~="selected_carte"]),
svg[class^="select_2"]:not(svg[class~="selected_carte"]),
svg[class^="select_3"]:not(svg[class~="selected_carte"]),
svg[class^="expe_"]:not(svg[class~="selected_expe"]), svg[class^="expe_mh_"]:not(svg[class~="selected_expe"]),
svg[class^="miniIcone"]:not(svg[class~="selected_objet"]),
svg[class^="miniIconeMulti"]:not(svg[class~="selected_objet"]) {
    display : none;
}

.gdCitoyensVille {
    height        : 16px;
    margin-bottom : 2px;
    margin-left   : -4px;
    margin-right  : -3px;
    width         : 16px;
}

.ptCitoyensVille {
    height        : 22px;
    margin-bottom : 3px;
    margin-left   : -5px;
    margin-right  : -4px;
    width         : 22px;
}


.zone-NonExplo, .zone-danger0, .zone-danger1, .zone-danger2, .zone-danger3, .zone-ctrlOk, .zone-ctrlKo {
    max-height : var(--taillePt);
    max-width  : var(--taillePt);
    position   : absolute;
    z-index    : 1;
}

.planBleu {
    color : blue;
}

.planJaune {
    color : yellow;
}

.objetCasseLignObjet {
    color : var(--error-color);
}

#zoneInfoVilleAutre {
    margin-bottom : 5px;
    width         : 1220px;

    i.tabs {
        display         : block; /* On change le type d'élément, les liens deviennent des balises de type block */
        font-size       : 1rem;
        font-weight     : bold;
        min-width       : 100px; /* Largeur */
        padding         : 0 3px;
        text-decoration : none; /* on supprime le style par défaut des liens  */
    }

    i.tabsSpecifique {
        display         : block; /* On change le type d'élément, les liens deviennent des balises de type block */
        font-size       : 1rem;
        font-weight     : bold;
        padding         : 0; /* Aucune marge intérieure */
        text-decoration : none; /* on supprime le style par défaut des liens  */
        width           : 150px; /* Largeur */

        > div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            height          : 26px;
            justify-content : center;
        }
    }

    li:hover:not(.selectedOngletCarte) {
        background-color : var(--special-color);
        color            : var(--hover-font-color);
    }

    > ul {
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */

        li {
            background-color : var(--secondary-color);
            color            : var(--primary-font-color);
            float            : left;
        }

        li.selectedOngletCarte {
            background-color : var(--primary-font-color);
            border-bottom    : 2px solid var(--secondary-color);
            color            : var(--primary-color);
        }

        li:not(.disabledOnglet) {
            cursor : pointer;
        }
    }


}

#zoneInfoVilleAutre > div {
    background-color : var(--secondary-color);
    border-bottom    : 2px solid var(--primary-border-color);
    border-left      : 2px solid var(--primary-border-color);
    border-right     : 2px solid var(--primary-border-color);
    font-size        : 0.8rem;
    margin-top       : 28px;
}

.boutonMenuCarte {
    background-color        : var(--secondary-color);
    border-bottom           : 2px solid var(--primary-border-color);
    border-left             : 2px solid var(--primary-border-color);
    border-right            : 2px solid var(--primary-border-color);
    border-top              : 2px solid var(--primary-border-color);
    border-top-left-radius  : 5px;
    border-top-right-radius : 5px;
    margin-bottom           : -2px;
}

#unpinInfoCarteButton {
    cursor  : pointer;
    float   : right;
    padding : 0 0.5em;
    width   : auto;
}


.bordureHaut {
    background-color : var(--primary-border-color);
    display          : flex;
    height           : 2px;
    width            : 100%;
}

.bordureInvi, #bordureInfoVille, #bordureInfoCitoyens1, #bordureInfoCitoyens2, #bordureInfoObjet1,
#bordureInfoObjet2, #bordureInfoBatiment1, #bordureInfoBatiment2, #bordureInfoExpedition1, #bordureInfoExpedition2 {
    display : inline-block;
}

#bordureInfoVille, #bordureInfoCitoyens1, #bordureInfoCitoyens2, #bordureInfoObjet1, #bordureInfoObjet2,
#bordureInfoBatiment1, #bordureInfoBatiment2, #bordureInfoExpedition1, #bordureInfoExpedition2, #bordureInfoParam1, #bordureInfoParam2 {
    border-top : 2px solid var(--primary-border-color);
}

.bordureInvi {
    width : 250px;

}

#contenuZoneInfoCitoyens {
    background-color : var(--secondary-color);
    display          : flex;
}

#contenuZoneInfoParam {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    display          : flex;
    flex-direction   : column;
    flex-wrap        : wrap;
    gap              : 5px;
    justify-content  : center;
    padding          : 0.5em;

    .lignOption {
        width : 100%;
    }
}

.outCitoyens, .inCitoyens {
    width : 50%;
}

.outCitoyens h3, .inCitoyens h3, #zoneBatimentCamp h3, #batRuine h3, #batJaune h4, #batBleu h4 {
    text-align : center;
    width      : 100%;
}

.outCitoyens > span, .inCitoyens > span {
    display : inline-block;
}

.outCitoyens span {
    cursor : pointer;
}

.citoyenBanni {
    color       : var(--error-color);
    font-weight : bold;
}

.colCitoyen0 {
    display : none;
}

.colCitoyen1 {
    width : 20%;

    span {
        width : 100%;
    }
}

.colCitoyen2 {
    width : 40%;

    span {
        width : 50%;
    }
}

.colCitoyen3 {
    width : 60%;

    span {
        width : 33%;
    }
}

.colCitoyen4 {
    width : 80%;

    span {
        width : 25%;
    }
}

.colCitoyen5 {
    width : 100%;

    span {
        width : 20%;
    }
}

.colCitoyen1 > div, .colCitoyen2 > div, .colCitoyen3 > div, .colCitoyen4 > div, .colCitoyen5 > div {
    display        : flex;
    flex-direction : column;
    flex-wrap      : wrap;
    height         : 200px;
}

.citoyenListCarte {
    align-items     : center;
    display         : flex;
    height          : 20px;
    justify-content : space-around;
    width           : 100%;

    .nomCitoyen, .citoyenListCarte .coordCitoyen {
        display : inline-block;
    }

    .nomCitoyen {
        margin-left : 5px;
        width       : 60%;
    }

    .jobCitoyen {
        align-items     : center;
        display         : flex;
        justify-content : center;
        width           : 8%;
    }

    .coordCitoyen {
        text-align : center;
        width      : 25%;
    }
}

.lignCitInCarte {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100%;

    span.nomCitoyen {
        align-items : center;
        display     : flex;
        gap         : 1px;
        width       : 80%;
    }

    span.jobCitoyen {
        align-items     : center;
        display         : flex;
        justify-content : center;
        width           : 10%;
    }
}

.citoyensBorder {
    border-right : 1px solid var(--primary-border-color);
}

#contenuZoneInfoBatiment {
    background-color : var(--secondary-color);
    display          : flex;
}

#selectionBoutonCitoyenCarte, #selectionBoutonBatimentCarte {
    display         : flex;
    justify-content : space-evenly;
    padding-top     : 3px;
    width           : 100%;
}

#batRuine {
    border-left : 1px solid var(--primary-border-color);
    width       : 22%;
}

#batJaune, #batBleu {
    width : 50%;
}

#batJaune > div, #batBleu > div {
    display        : flex;
    flex-direction : column;
    gap            : 2px;
    height         : 120px;
    overflow       : auto;
}

#batJaune > div .lignBat, #batBleu > div .lignBat, #batRuine .lignBat {
    align-items     : center;
    cursor          : pointer;
    display         : inline-flex;
    justify-content : center;
    width           : 100%;
}

#batBleu {
    border-left : 1px solid var(--primary-border-color);
}

#regroupementBatiment {
    display : flex;
    width   : 100%;
}

#zoneBatimentCamp h3, #batRuine h3, .outCitoyens h3, .inCitoyens h3 {
    margin-bottom : 2px;
    margin-top    : 5px;
}

#batJaune h4, #batBleu h4 {
    margin-bottom : 2px;
    margin-top    : 2px;
}

/* Gestion de la ligne de batiment */
.kmBatCarte, .coordBatCarte, .etatBatCarte, .campBatCarte {
    display : inline-block;
}

#zoneBatimentCamp {
    width : 77%;

    .nomBatCarte {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        margin-left     : 2px;
        width           : 66%;
    }

    .kmBatCarte {
        width : 10%;
    }

    .coordBatCarte {
        text-align : center;
        width      : 15%;
    }

    .etatBatCarte, .campBatCarte {
        align-items     : center;
        display         : inline-flex;
        justify-content : center;
        width           : 4%;
    }
}

#batRuine {
    .nomBatCarte {
        margin-left : 2px;
        width       : 60%;
    }

    .kmBatCarte {
        width : 20%;
    }

    .coordBatCarte {
        text-align : center;
        width      : 19%;
    }
}

.itemBank {
    font-weight : bold;
}

#contenuZoneInfoObjet {
    background-color : var(--secondary-color);
    display          : flex;
    flex-wrap        : wrap;
    height           : 241px;

    > div {
        display : flex;
    }

}

.listObjetCat {
    font-size : 0.8rem;
    margin    : 0 1px 4px;
    width     : 240px;

    h3 {
        margin-bottom : 2px;
        margin-top    : 2px;
        text-align    : center;
        width         : 100%;
    }
}

.listObjetCatMax {
    font-size : 0.8rem;
    margin    : 0 1px 4px;
    width     : 960px;

    h3 {
        margin-bottom : 2px;
        margin-top    : 2px;
        text-align    : center;
        width         : 100%;
    }
}

.toggleBoutonObjet {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    height          : 117px;
    justify-content : center;
}

.titreCategorie {
    height       : 117px;
    transform    : rotate(360deg);
    writing-mode : vertical-lr;
}

.listObjetLigne {
    height   : 96px;
    overflow : auto;
    width    : 100%;
}

.listObjetLigneMax {
    height                : 236px;
    overflow              : auto;
    width                 : 960px;
    display               : grid;
    grid-template-columns : 1fr 1fr 1fr 1fr;
}

.lignObjetCarte {
    align-items     : center;
    cursor          : pointer;
    display         : flex;
    justify-content : center;
    width           : 100%;

    svg {
        height       : 16px;
        margin-left  : 1px;
        margin-right : 1px;
        width        : 16px;
    }
}

.lignObjetCarteMax {
    align-items     : center;
    cursor          : pointer;
    display         : flex;
    justify-content : center;
    width           : 235px;

    svg {
        height       : 16px;
        margin-left  : 1px;
        margin-right : 1px;
        width        : 16px;
    }
}

.ensNomNbrObjet {
    display : flex;
    width   : 205px;
}

.nomItemLigneObjet, .textCasseLigneObjet {
    display : inline-block;
    width   : 73%;
}

.textCasseLigneObjet {
    text-align : end;
}

.ensItemNonCasseLigneObjet, .objetCasseLignObjet {
    display         : flex;
    justify-content : center;
    width           : 27%;
}

.ensItemCasseLigneObjet {
    display : flex;
    width   : 100%;
}

.itemDehors, .itemBank {
    display : inline-block;
    width   : 49%;
}

.itemDehors {
    text-align : end;
}

#menuListingObjetCarte, #selectionBoutonCitoyenCarte, #selectionBoutonBatimentCarte {
    background-color : var(--secondary-color);
    display          : flex;
    justify-content  : space-around;
    padding-bottom   : 0.5em;
    padding-top      : 0.5em;
}

#selectionBoutonBatimentCarte button {
    font-size : 0.75rem;
}

#contenuZoneInfoCarte {
    background-color : var(--secondary-color);
    display          : flex;
}

#infoGénéral, #recapDefenseVille, #recapEvoChantierVille, #recapEstimStatsVille {
    font-size    : 0.9rem;
    margin-left  : 2px;
    margin-right : 2px;
    padding-top  : 0.5rem;
    width        : 25%;
}

#infoGénéral p, #recapDefenseVille p, #recapEvoChantierVille p, #recapEstimationVille p, #recapStatsVille p {
    font-weight     : bold;
    margin          : 2px 0 2px;
    text-align      : center;
    text-decoration : underline;
    width           : 100%;
}

#recapEstimationVille h4, #recapStatsVille h4 {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
    margin-bottom   : 2px;
    margin-top      : 5px;
    text-decoration : underline;
}

.divZoneInfoVille {
    display        : flex;
    padding-bottom : 0.1em;
    padding-left   : 0.1em;
}

.divZoneInfoVille:nth-child(odd) {
    background-color : var(--primary-row-color);
}

.divZoneInfoVille:nth-child(even) {
    background-color : var(--secondary-row-color);
}

.titreZoneInfoVille {
    align-items   : center;
    display       : inline-flex;
    font-weight   : bold;
    justify-items : center;
    width         : 60%;
}

.titreZoneInfoVille span {
    display : block;
    width   : 23%;
}


#derZoneInfoVille {
    margin-bottom : 4px;
}

.resultZoneInfoVille {
    align-items   : center;
    display       : inline-flex;
    justify-items : center;
    width         : 40%;
}

.detailCase {
    font-size : 0.9rem;
    padding   : 5px;
    width     : 530px;
    z-index   : 600;
}


#infoBulleCarte {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    font-size        : 0.9rem;
    padding          : 2px;
    position         : absolute;
    width            : 400px;
    z-index          : 600;
}


.enteteInfoCase {
    align-items     : center;
    display         : flex;
    justify-content : center;
}

.coordPopUp {
    display : inline-flex;
    width   : 53%;
}

.distancePopup {
    align-content   : center;
    align-items     : center;
    display         : inline-flex;
    justify-content : center;
    width           : 47%;
}

.distanceInfoCase {
    display : flex;
}

.listObjetSolCaseVille {
    display       : flex;
    flex-wrap     : wrap;
    margin-bottom : 2px;
    width         : 100%;
}

.citoyensPresentCaseVille p, .objetSolCaseVille p {
    margin          : 0;
    text-decoration : underline;
    width           : 100%;
}

.infoCaseDetail {
    display   : flex;
    flex-wrap : wrap;
    width     : 100%;
}

.infoCaseDetail > span:not(.statutCaseDetailCaseVille) {
    display : inline-block;
    width   : 100%;
}

.statutCaseDetailCaseVille {
    display : inline-block;
    width   : 100%;
}

.nomBatCaseCarte {
    font-weight : bold;
    text-align  : center;
    width       : 100%;
}

.descBatCaseCarte {
    text-align : justify;
}

.infoBatCaseCarte {
    display : flex;
    width   : 100%;

    span:not(.hypothese_bat) {
        display : inline-block;
        width   : 50%;
    }

    span.hypothese_bat {
        display : inline-block;
        width   : 100%;
    }
}

.citoyensCaseVille {
    align-items     : center;
    display         : inline-flex;
    justify-content : center;
}

.infoMiseAJour {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    margin-bottom   : 2px;
    width           : 100%;

    span {
        display   : inline-flex;
        font-size : 0.8rem;
    }
}

.statutInfoCase {
    display : none;
}


#listingObjetMajCarte {
    align-items    : center;
    display        : flex;
    flex-direction : column;
    gap            : 6px;
    padding        : 5px;
    width          : 75%;
}

#zoneInfoCase {
    padding-left : 5px;
    width        : 25%;
}

#listingObjetMajCarte_marqueurs {
    padding  : 5px;
    position : relative;
    top      : 28px;
    width    : 260px;
}

#zoneInfoCase_marqueurs {
    padding-left : 5px;
    width        : 260px;
}

#popUpMajCarte {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    left             : 50%; /* positionnement au milieu de l'écran */
    min-height       : 500px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 900px;
    z-index          : 1110;
}

#popUpMajCarte_marqueurs {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    left             : 50%; /* positionnement au milieu de l'écran */
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 534px;
    z-index          : 1110;
}

#objetCaseMaj {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : flex-start;
    margin-bottom   : 3px;
}

#infoCaseMaj {
    margin : 3px 0;
}

#infoCaseMaj p, .lignEpuise {
    font-size : 0.9rem;
    margin    : 1px 0;
}

.lignEpuise {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : flex-start;
}

.labelInfoCase {
    display : inline-block;
    width   : 50%;
}

#zoneInfoCase, #zoneInfoCase_marqueurs {
    h2 {
        margin     : 3px 0;
        text-align : center;
        width      : 100%;
    }

    #infoCaseMaj > p {
        display : flex;
        margin  : 0 0 2px;
    }

    #lignePhraseObjetSol {
        font-size       : 0.9rem;
        margin          : 0;
        text-decoration : underline;
    }
}

#distanceInfoCaseMaj {
    align-content : center;
    align-items   : center;
    display       : flex;
}

#boutonMajCase {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 20px;
    justify-content : center;
    width           : 100%;
}

#objetCaseMaj > div {
    cursor : pointer;
}

.lignOption {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : flex-start;
    margin-bottom   : 4px;
}

.lignLabelOption {
    display : block;
    width   : 150px;
}

#optionDiverseCarte, #optionDiverseKm, #optionDiversePa, #optionDiverseZone, #optionDiverseDecharge {
    align-content         : center;
    align-items           : center;
    display               : grid;
    flex-wrap             : wrap;
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width                 : 1030px;

    > div {
        cursor : pointer;

        > label {
            cursor : pointer;
        }

        > input {
            cursor : pointer;
        }
    }
}

#optionDiverseKm, #optionDiversePa, #optionDiverseZone, #optionDiverseDecharge {
    flex-wrap : wrap;
}

.quitPopUp {
    border      : 1px solid var(--primary-border-color);
    color       : var(--error-color);
    cursor      : pointer;
    display     : inline-block;
    font-weight : bold;
    text-align  : center;
    width       : 5%;
}

.batCamped {
    display : none;
}

#retourAjaxMajCaseCarte {
    margin-top : 5px;
    text-align : center;
}

#contenuZoneInfoExpedition {
    background-color : var(--secondary-color);
    display          : flex;
    padding          : 0.5em;

    table#trace_carte {
        border     : solid 1px var(--primary-border-color);
        margin     : auto;
        text-align : center;
        width      : 975px;

        thead {
            background-color : var(--tertiary-color);
        }

        tr, td, th {
            border     : solid 1px var(--primary-border-color);
            text-align : center;
        }

        .list_couleur_trace {
            border        : solid 1px var(--primary-border-color);
            border-radius : 3px;
            display       : inline-block;
            height        : 15px;
            margin-right  : 10px;
            width         : 15px;
        }

        .nom_coul_tb_trace {
            width : 400px;
        }

        .nom_coul_tb_trace div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-between;
            width           : 100%;

        }

        .jour_tb_trace, .pa_tb_trace, .collab_tb_trace {
            width : 30px;
        }

        .action_tb_trace {
            width : 85px;

            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                gap             : 5px;
                justify-content : space-around;
                width           : 100%;

                div {
                    width : 34%;
                }
            }
        }

    }

    table#trace_hordes {
        border     : solid 1px var(--primary-border-color);
        margin     : auto;
        text-align : center;
        width      : 975px;

        tr, td, th {
            border     : solid 1px var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
        }

        .list_couleur_trace {
            border        : solid 1px var(--primary-border-color);
            border-radius : 3px;
            display       : inline-block;
            height        : 15px;
            margin-right  : 10px;
            width         : 15px;
        }

        .nom_coul_tb_trace {
            width : 400px;
        }

        .nom_coul_tb_trace div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-between;
            width           : 100%;
        }

        .action_tb_trace > div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-around;
            width           : 100%;

            div {
                width : 50%;
            }
        }

        .pa_tb_trace {
            width : 30px;
        }

        .action_tb_trace {
            width : 85px;
        }

        .user_tb_trace {
            width : 175px;
        }
    }
}

#listTrace {
    width : 1000px;

    > div#zone_groupe_expedition {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        margin-bottom   : 5px;

        div#zone_groupe_bouton_expedition {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 15px;
            justify-content : flex-start;
        }
    }

    #table_trace {
        height     : 200px;
        overflow-y : scroll;
        width      : 100%;

    }
}


#formTrace {
    padding-left : 5px;
    width        : 220px;
}

.expedition_carte {
    height   : 100%;
    position : absolute;
    width    : 100%;
}

.btnMajCase {
    pointer-events : auto;
}

.quitPopUp {
    pointer-events : auto;
}

select[name="bat[camped]"], select[name="bat[empty]"] {
    pointer-events : auto;
}

.visualiser_expe {
    cursor : pointer;
}

.expe_form_zone {
    align-items     : center;
    display         : flex;
    justify-content : space-evenly;
    width           : 200px;
}

#form_expe_ligne_button {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    gap             : 8px;
    justify-content : space-around;
    margin          : auto;
    width           : 100%;
}

#infoCamping {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : block;
    left             : 50%; /* positionnement au milieu de l'écran */
    padding          : 10px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 800px;
    z-index          : 1110;
}

.bouton_action_camping {
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100%;
}

.joueurNonCp {
    color : red;
}

.joueurCp {
    color : blue;
}

.background_carte_sable {
    background-image : var(--map-bg-img-option);
}

.background_carte_lisse {
    background-image : none;
}

#objet_filtre_zoneKm {
    > div:not(#button_filtre_zone_carte_objet) {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        margin-bottom   : 3px;
    }
}

#filtre_zone_carte_objet {
    input {
        width : 40px;
    }
}

#button_filtre_zone_carte_objet {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
    margin-bottom   : 3px;
}

#infoCamping .ligne {
    align-items   : center;
    display       : grid;
    gap           : 10px; /* Espace entre les grilles */
    justify-items : start;
}

#infoCamping .ligne1 {
    grid-template-columns : 1fr 1fr; /* 2 colonnes pour la première ligne */
}

#infoCamping .ligne2 {
    grid-template-columns : repeat(3, 1fr); /* 3 colonnes pour la deuxième ligne */
}

#infoCamping .ligne3 {
    grid-template-columns : 1fr 1fr; /* 2 colonnes pour la troisième ligne */
}

#estimZombieDay, #expe_form_jour {
    width : 30px;
}

.my_trace_expedition {
    font-weight : bold;
}

.zone_bouton_detail_carte {
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    margin-bottom   : 3px;
    margin-top      : 3px;
}

#text_consigne_titre {
    font-weight : bold;
    text-align  : center;
    width       : 100%;
}

#text_retour_maj {
    text-align : center;
    width      : 100%;
}

.ligne_consigne_instruction {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;

    > span > p {
        margin : 0;
    }
}

.group-btn-color-expedition {
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

#infoExpedition {
    align-items      : center;
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    height           : 350px;
    left             : 50%; /* positionnement au milieu de l'écran */
    padding          : 10px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 900px;
    z-index          : 1110;
}

.recherche_coordonnee {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;

    .input_recherche_coord {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;

        > div input {
            width : 20px;
        }
    }
}

.tooltip-container-carte {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : 6px;
    box-shadow       : 0px 4px 8px rgba(0, 0, 0, 0.3);
    color            : var(--primary-font-color);
    font-size        : 13px;
    opacity          : 1;
    position         : absolute;
    transition       : opacity 0.2s, visibility 0.2s;
    width            : 540px;
    z-index          : 600;
}

#form_camp_batiment_identifie {
    align-content         : center;
    align-items           : center;
    display               : grid;
    grid-template-columns : repeat(5, 1fr);
    justify-items         : start;
    padding               : 5px;
    gap                   : 3px;

    input[type="number"] {
        width : 50px;
    }

    #camping_batiment_select {
        grid-column-end   : 3;
        grid-column-start : 1;
    }

    #camping_amelio_od_select {
        grid-column-end   : 4;
        grid-column-start : 1;
    }

    #camping_phare_select {
        grid-column-end   : 3;
        grid-column-start : 1;
    }

    #camping_nuit_select {
        grid-column-end   : 4;
        grid-column-start : 3;
    }

    #camping_devast_select {
        grid-column-end   : 6;
        grid-column-start : 5;
    }

    #camp_max_input {
        grid-column-end   : 6;
        grid-column-start : 4;

        > input {
            width : 50px;
        }
    }

    #camping_zombie_select {
        grid-column-end   : 5;
        grid-column-start : 4;
    }

    #camping_zombie_select input {
        width : 40px
    }
}

#camping_campeur_identifie {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 5px;
    justify-content : center;
    margin-bottom   : 10px;
    margin-top      : 10px;
    width           : 100%;

    table {
        font-size : 0.9rem;
        margin    : auto auto 2px;
        width     : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        .tbl_camping_ordre {
            width : 30px;
        }

        .tbl_camping_campeur {
            width : 250px;
        }

        .tbl_camping_nbr {
            width : 80px;
        }

        .tbl_camping_tombe {
            width : 80px;
        }

        .tbl_camping_odc {
            width : 80px;
        }

        .tbl_camping_chance {
            width : 80px;
        }

        .tbl_camping_action {
            width : 100px;
        }

    }
}