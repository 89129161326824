/** #formUser {
    font-size : 1rem;
    margin    : auto;
    padding   : 10px;
    width     : 85%;

    div.inputFormAdmin {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;

        div {
            width : 50%;
        }
    }

}

#formUserSearch {
    font-size : 1rem;
    margin    : auto;
    padding   : 10px;
    width     : 90%;

    div#form {
        align-items     : center;
        display         : flex;
        justify-content : center;
        justify-items   : center;

        div {
            align-items     : center;
            display         : flex;
            justify-content : center;
            justify-items   : center;
            margin          : 2px;
            width           : 50%;

            > * {
                margin : 2px;
            }
        }
    }
}

#gestionDispoCreneauAdmin {
    padding          : 5px;
    background-color : var(--secondary-color);
    width            : 100%;

    h2 {
        margin : 5px 0 2px;
    }

    p {
        font-size  : 0.8rem;
        font-style : italic;
        margin     : 0 0 2px;
    }
}

.intituleDispoOptionPersoAdmin {
    margin-bottom   : 2px;
    text-align      : center;
    text-decoration : solid var(--primary-border-color);
    width           : 100%;
}

.ligneDispoOptionPersoAdmin {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : flex-start;
}

.colonneDispoOptionPersoAdmin {
    align-content   : center;
    align-items     : center;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 114px;

    select {
        margin : 5px;
        width  : 108px;
    }

    div {
        background-color : var(--tertiary-color);
        align-content    : center;
        align-items      : center;
        border-bottom    : 1px solid var(--primary-border-color);
        display          : flex;
        height           : 40px;
        justify-content  : center;
        width            : 100%;

        span {
            display    : inline-block;
            text-align : center;
            width      : 100%;
        }
    }
}
**/

.user-switch {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    gap             : 10px;
    justify-content : center;
}

.user-color {
    align-content         : center;
    align-items           : center;
    display               : grid;
    flex-wrap             : wrap;
    gap                   : 10px;
    grid-template-columns : 1fr 1fr;
    justify-content       : center;
}