.graphStat {
    height : 1200px;
    margin : auto;
    width  : 1100px;
}

#graphStat1, #graphStat2 {
    background-color : var(--stats-bg-color);
    border           : solid 1px var(--primary-border-color);
    color            : var(--primary-font-color);
    height           : 600px;
    margin           : auto auto 5px;
    width            : 1000px;
}

#form_filtre {
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    margin          : auto auto 8px;
    width           : 60%;

    label {
        margin-right : 3px;
    }
}