#journalVilleTab {
    border : 1px solid var(--primary-border-color);
    margin : auto;
    width  : 80%;

    th, tr, td {
        border     : 1px solid var(--primary-border-color);
        font-size  : 0.9em;
        text-align : center;
    }

    thead tr {
        background-color : var(--tertiary-color);
        height           : 40px;
    }

    tbody tr:nth-child(even) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--primary-row-color);
    }


}

#tabJournal td p {
    margin     : 4px;
    text-align : justify;
}

.colJour {
    width : 5%;
}

.colZ {
    width : 8%;
}

.colDir {
    width : 15%;
}

.group-scrut {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

.regen-scrut {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}