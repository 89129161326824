#formNews {
    font-size : 1rem;
    margin    : auto;
    padding   : 10px;
    width     : 90%;

    div#news_site {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-around;

        div {
            margin : 2px;
        }
    }

    div#form div.boutSave {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-around;
    }
}

.tableNews {
    width : 75%;
}

.idNews {
    width : 5%;
}

.titreNews {
    width : 31%;
}

.dateNews {
    width : 12%;
}

.actionNews {
    width : 12%;
}