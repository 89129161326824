#zone_ruineGame_body {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 10px;
    padding          : 10px;

    h2 {
        color       : var(--primary-font-color);
        font-family : edo_szregular, serif;
        font-size   : 2em;
        margin      : 0 auto;
        text-align  : center;
        width       : 90%;
    }

    > ul > li {
        cursor      : pointer;
        float       : left;
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
        width       : 300px;

        > button {
            width : 300px;
        }

    }
}

#zone_tabs_game {
    align-content    : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 5px;
    justify-content  : center;
    width            : 840px;
}

#zone_option_game {
    font-size : 0.9em;
    padding   : 5px;

    #option_ruine_game {
        align-content         : center;
        align-items           : center;
        display               : grid;
        gap                   : 15px;
        grid-template-columns : 1fr 1fr;
        justify-content       : flex-start;
        justify-items         : center;
    }

    p {
        margin : 2px auto;
        width  : 100%;
    }

    h4 {
        margin-bottom : 2px;
        margin-top    : 2px;
    }
}


#ruine_exploration {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 274px;
    justify-content : center;
    width           : 274px;
}

#zone_play_game {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : 5px;
    display          : flex;
    justify-content  : space-around;
}

.ruine_progress-bar {
    align-items      : flex-end;
    background-color : #f0f0f0;
    border-radius    : 5px;
    display          : flex;
    height           : 200px;
    justify-content  : center;
    width            : 35px;
}

.progress-fill-oxygene {
    background-color : #0544dc; /* Couleur de la jauge remplie */
    border-radius    : 5px;
    line-height      : 24px;
    text-align       : center;
    width            : 100%;
}

.progress-fill-mana {
    background-color : #ac0808; /* Couleur de la jauge remplie */
    border-radius    : 5px;
    line-height      : 24px;
    text-align       : center;
    width            : 100%;
}

#ruine_exploration_jauge {
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

#ruine_action_exploration {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;

    > button {
        width : 100%;
    }
}

#zone_ruinegame_bouton {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    margin-bottom   : 6px;
    margin-top      : 10px;
}

#icone_jauge_mana_ruine_game {
    align-items     : center;
    display         : flex;
    justify-content : center;
}

.legend_jauge_ruineGame {
    height : 25px;
    width  : 100%;
}

#ruine_zoom_play {
    min-height : 400px;
    width      : 345px;
}

.zone_ruine_game_skin-bunker {
    &.zone_ruine_game_type-all {
        background : url("../img/ruine/bunker-room.png"),
        url("../img/ruine/bunker-1.png"), url("../img/ruine/bunker-2.png"), url("../img/ruine/bunker-3.png"),
        url("../img/ruine/bunker-4.png"), url("../img/ruine/bunker-5.png"), url("../img/ruine/bunker-6.png"),
        url("../img/ruine/bunker-7.png"), url("../img/ruine/bunker-8.png"), url("../img/ruine/bunker-9.png"),
        url("../img/ruine/bunker-10.png"), url("../img/ruine/bunker-11.png"), url("../img/ruine/bunker-12.png"),
        url("../img/ruine/bunker-13.png"), url("../img/ruine/bunker-14.png"), url("../img/ruine/bunker-15.png")
    }

    &.zone_ruine_game_type-16 {
        background : url("../img/ruine/bunker-16.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-room {
        background : url("../img/ruine/bunker-room.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-1 {
        background : url("../img/ruine/bunker-1.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-2 {
        background : url("../img/ruine/bunker-2.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-3 {
        background : url("../img/ruine/bunker-3.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-4 {
        background : url("../img/ruine/bunker-4.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-5 {
        background : url("../img/ruine/bunker-5.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-6 {
        background : url("../img/ruine/bunker-6.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-7 {
        background : url("../img/ruine/bunker-7.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-8 {
        background : url("../img/ruine/bunker-8.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-9 {
        background : url("../img/ruine/bunker-9.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-10 {
        background : url("../img/ruine/bunker-10.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-11 {
        background : url("../img/ruine/bunker-11.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-12 {
        background : url("../img/ruine/bunker-12.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-13 {
        background : url("../img/ruine/bunker-13.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-14 {
        background : url("../img/ruine/bunker-14.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-15 {
        background : url("../img/ruine/bunker-15.png") center/cover no-repeat;
    }

    & > .personnage {
        &.zombielocal:before {
            background : url("../img/ruine/bunker-zombie.gif") center/contain no-repeat;
        }

        &.zombie_deadlocal:before {
            background : url("../img/ruine/bunker-dead.png") center/contain no-repeat;
        }
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-1 {
        background : url("../img/ruine/bunker-ed1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-2 {
        background : url("../img/ruine/bunker-ed2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-3 {
        background : url("../img/ruine/bunker-ed3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-4 {
        background : url("../img/ruine/bunker-ed4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-5 {
        background : url("../img/ruine/bunker-ed5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-6 {
        background : url("../img/ruine/bunker-ed6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-7 {
        background : url("../img/ruine/bunker-ed7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-8 {
        background : url("../img/ruine/bunker-ed8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-1 {
        background : url("../img/ruine/bunker-do1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-2 {
        background : url("../img/ruine/bunker-do2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-3 {
        background : url("../img/ruine/bunker-do3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-4 {
        background : url("../img/ruine/bunker-do4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-5 {
        background : url("../img/ruine/bunker-do5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-6 {
        background : url("../img/ruine/bunker-do6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-7 {
        background : url("../img/ruine/bunker-do7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-8 {
        background : url("../img/ruine/bunker-do8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-1 {
        background : url("../img/ruine/bunker-eu1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-2 {
        background : url("../img/ruine/bunker-eu2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-3 {
        background : url("../img/ruine/bunker-eu3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-4 {
        background : url("../img/ruine/bunker-eu4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-5 {
        background : url("../img/ruine/bunker-eu5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-6 {
        background : url("../img/ruine/bunker-eu6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-7 {
        background : url("../img/ruine/bunker-eu7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-8 {
        background : url("../img/ruine/bunker-eu8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-1 {
        background : url("../img/ruine/bunker-dc1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-2 {
        background : url("../img/ruine/bunker-dc2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-3 {
        background : url("../img/ruine/bunker-dc3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-4 {
        background : url("../img/ruine/bunker-dc4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-5 {
        background : url("../img/ruine/bunker-dc5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-6 {
        background : url("../img/ruine/bunker-dc6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-7 {
        background : url("../img/ruine/bunker-dc7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-8 {
        background : url("../img/ruine/bunker-dc8.png") center/cover no-repeat;
    }

}

.zone_ruine_game_skin-hopital {
    &.zone_ruine_game_type-all {
        background : url("../img/ruine/hopital-room.png"),
        url("../img/ruine/hopital-1.png"), url("../img/ruine/hopital-2.png"), url("../img/ruine/hopital-3.png"),
        url("../img/ruine/hopital-4.png"), url("../img/ruine/hopital-5.png"), url("../img/ruine/hopital-6.png"),
        url("../img/ruine/hopital-7.png"), url("../img/ruine/hopital-8.png"), url("../img/ruine/hopital-9.png"),
        url("../img/ruine/hopital-10.png"), url("../img/ruine/hopital-11.png"), url("../img/ruine/hopital-12.png"),
        url("../img/ruine/hopital-13.png"), url("../img/ruine/hopital-14.png"), url("../img/ruine/hopital-15.png")
    }

    &.zone_ruine_game_type-16 {
        background : url("../img/ruine/hopital-16.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-room {
        background : url("../img/ruine/hopital-room.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-1 {
        background : url("../img/ruine/hopital-1.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-2 {
        background : url("../img/ruine/hopital-2.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-3 {
        background : url("../img/ruine/hopital-3.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-4 {
        background : url("../img/ruine/hopital-4.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-5 {
        background : url("../img/ruine/hopital-5.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-6 {
        background : url("../img/ruine/hopital-6.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-7 {
        background : url("../img/ruine/hopital-7.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-8 {
        background : url("../img/ruine/hopital-8.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-9 {
        background : url("../img/ruine/hopital-9.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-10 {
        background : url("../img/ruine/hopital-10.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-11 {
        background : url("../img/ruine/hopital-11.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-12 {
        background : url("../img/ruine/hopital-12.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-13 {
        background : url("../img/ruine/hopital-13.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-14 {
        background : url("../img/ruine/hopital-14.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-15 {
        background : url("../img/ruine/hopital-15.png") center/cover no-repeat;
    }

    & > .personnage {
        &.zombielocal:before {
            background : url("../img/ruine/hopital-zombie.gif") center/contain no-repeat;
        }

        &.zombie_deadlocal:before {
            background : url("../img/ruine/hopital-dead.png") center/contain no-repeat;
        }
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-1 {
        background : url("../img/ruine/hopital-ed1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-2 {
        background : url("../img/ruine/hopital-ed2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-3 {
        background : url("../img/ruine/hopital-ed3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-4 {
        background : url("../img/ruine/hopital-ed4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-5 {
        background : url("../img/ruine/hopital-ed5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-6 {
        background : url("../img/ruine/hopital-ed6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-7 {
        background : url("../img/ruine/hopital-ed7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-8 {
        background : url("../img/ruine/hopital-ed8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-1 {
        background : url("../img/ruine/hopital-do1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-2 {
        background : url("../img/ruine/hopital-do2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-3 {
        background : url("../img/ruine/hopital-do3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-4 {
        background : url("../img/ruine/hopital-do4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-5 {
        background : url("../img/ruine/hopital-do5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-6 {
        background : url("../img/ruine/hopital-do6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-7 {
        background : url("../img/ruine/hopital-do7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-8 {
        background : url("../img/ruine/hopital-do8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-1 {
        background : url("../img/ruine/hopital-eu1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-2 {
        background : url("../img/ruine/hopital-eu2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-3 {
        background : url("../img/ruine/hopital-eu3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-4 {
        background : url("../img/ruine/hopital-eu4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-5 {
        background : url("../img/ruine/hopital-eu5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-6 {
        background : url("../img/ruine/hopital-eu6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-7 {
        background : url("../img/ruine/hopital-eu7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-8 {
        background : url("../img/ruine/hopital-ed8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-1 {
        background : url("../img/ruine/hopital-dc1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-2 {
        background : url("../img/ruine/hopital-dc2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-3 {
        background : url("../img/ruine/hopital-dc3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-4 {
        background : url("../img/ruine/hopital-dc4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-5 {
        background : url("../img/ruine/hopital-dc5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-6 {
        background : url("../img/ruine/hopital-dc6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-7 {
        background : url("../img/ruine/hopital-dc7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-8 {
        background : url("../img/ruine/hopital-dc8.png") center/cover no-repeat;
    }
}

.zone_ruine_game_skin-hotel {
    &.zone_ruine_game_type-all {
        background : url("../img/ruine/hotel-room.png"),
        url("../img/ruine/hotel-1.png"), url("../img/ruine/hotel-2.png"), url("../img/ruine/hotel-3.png"),
        url("../img/ruine/hotel-4.png"), url("../img/ruine/hotel-5.png"), url("../img/ruine/hotel-6.png"),
        url("../img/ruine/hotel-7.png"), url("../img/ruine/hotel-8.png"), url("../img/ruine/hotel-9.png"),
        url("../img/ruine/hotel-10.png"), url("../img/ruine/hotel-11.png"), url("../img/ruine/hotel-12.png"),
        url("../img/ruine/hotel-13.png"), url("../img/ruine/hotel-14.png"), url("../img/ruine/hotel-15.png")
    }

    &.zone_ruine_game_type-16 {
        background : url("../img/ruine/hotel-16.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-room {
        background : url("../img/ruine/hotel-room.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-1 {
        background : url("../img/ruine/hotel-1.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-2 {
        background : url("../img/ruine/hotel-2.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-3 {
        background : url("../img/ruine/hotel-3.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-4 {
        background : url("../img/ruine/hotel-4.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-5 {
        background : url("../img/ruine/hotel-5.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-6 {
        background : url("../img/ruine/hotel-6.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-7 {
        background : url("../img/ruine/hotel-7.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-8 {
        background : url("../img/ruine/hotel-8.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-9 {
        background : url("../img/ruine/hotel-9.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-10 {
        background : url("../img/ruine/hotel-10.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-11 {
        background : url("../img/ruine/hotel-11.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-12 {
        background : url("../img/ruine/hotel-12.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-13 {
        background : url("../img/ruine/hotel-13.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-14 {
        background : url("../img/ruine/hotel-14.png") center/cover no-repeat;
    }

    &.zone_ruine_game_type-15 {
        background : url("../img/ruine/hotel-15.png") center/cover no-repeat;
    }

    & > .personnage {
        &.zombielocal:before {
            background : url("../img/ruine/hotel-zombie.gif") center/contain no-repeat;
        }

        &.zombie_deadlocal:before {
            background : url("../img/ruine/hotel-dead.png") center/contain no-repeat;
        }
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-1 {
        background : url("../img/ruine/hotel-ed1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-2 {
        background : url("../img/ruine/hotel-ed2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-3 {
        background : url("../img/ruine/hotel-ed3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-4 {
        background : url("../img/ruine/hotel-ed4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-5 {
        background : url("../img/ruine/hotel-ed5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-6 {
        background : url("../img/ruine/hotel-ed6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-7 {
        background : url("../img/ruine/hotel-ed7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl--1.deco_porte-8 {
        background : url("../img/ruine/hotel-ed8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-1 {
        background : url("../img/ruine/hotel-do1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-2 {
        background : url("../img/ruine/hotel-do2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-3 {
        background : url("../img/ruine/hotel-do3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-4 {
        background : url("../img/ruine/hotel-do4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-5 {
        background : url("../img/ruine/hotel-do5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-6 {
        background : url("../img/ruine/hotel-do6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-7 {
        background : url("../img/ruine/hotel-do7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-0.deco_porte-8 {
        background : url("../img/ruine/hotel-do8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-1 {
        background : url("../img/ruine/hotel-eu1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-2 {
        background : url("../img/ruine/hotel-eu2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-3 {
        background : url("../img/ruine/hotel-eu3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-4 {
        background : url("../img/ruine/hotel-eu4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-5 {
        background : url("../img/ruine/hotel-eu5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-6 {
        background : url("../img/ruine/hotel-eu6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-7 {
        background : url("../img/ruine/hotel-eu7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-open > .decos > .deco_porte_lvl-1.deco_porte-8 {
        background : url("../img/ruine/hotel-ed8.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-1 {
        background : url("../img/ruine/hotel-dc1.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-2 {
        background : url("../img/ruine/hotel-dc2.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-3 {
        background : url("../img/ruine/hotel-dc3.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-4 {
        background : url("../img/ruine/hotel-dc4.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-5 {
        background : url("../img/ruine/hotel-dc5.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-6 {
        background : url("../img/ruine/hotel-dc6.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-7 {
        background : url("../img/ruine/hotel-dc7.png") center/cover no-repeat;
    }

    &.zone_ruine_porte-closed > .decos > .deco_porte-8 {
        background : url("../img/ruine/hotel-dc8.png") center/cover no-repeat;
    }
}

.zone_ruine_game_skin-bunker, .zone_ruine_game_skin-hotel, .zone_ruine_game_skin-hopital {

    & > .decos {
        bottom   : 0;
        left     : 0;
        position : absolute;
        right    : 0;
        top      : 0;

        & > * {
            position : absolute;
        }

        & > .deco_porte {
            height : 10%;
            width  : 10%;

            &.deco_porte_lvl-0:hover {
                cursor : pointer;
                filter : drop-shadow(0 0 10px green)
            }

            &.deco_porte_lvl-1:hover {
                cursor : pointer;
                filter : drop-shadow(0 0 10px white)
            }

            &.deco_porte_lvl--1:hover {
                cursor : pointer;
                filter : drop-shadow(0 0 10px white)
            }

            &.deco_porte-1, &.deco_porte-6 {
                left : 17%
            }

            &.deco_porte-3, &.deco_porte-8 {
                right : 17%
            }

            &.deco_porte-4 {
                left : 34%
            }

            &.deco_porte-5 {
                right : 34%
            }

            &.deco_porte-2 {
                left : 45%
            }

            &.deco_porte-7 {
                right : 45%
            }

            &.deco_porte-1, &.deco_porte-2, &.deco_porte-3 {
                top : 32%
            }

            &.deco_porte-6, &.deco_porte-7, &.deco_porte-8 {
                bottom : 32%
            }

            &.deco_porte-4 {
                top : 45%
            }

            &.deco_porte-5 {
                bottom : 45%
            }
        }
    }
}

div.zone_ruine_map_game {
    width : 100%;

    div.zone_jeu {
        border      : 1px solid var(--primary-border-color);
        filter      : contrast(1.1);
        font-size   : 0;
        overflow    : hidden;
        padding-top : 100%;
        position    : relative;

        &:before, &:after {
            content        : " ";
            display        : block;
            height         : 100%;
            left           : 0;
            pointer-events : none;
            position       : absolute;
            top            : 0;
            width          : 100%;
        }

        &:before {
            background     : radial-gradient(circle, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.85) 70%, rgba(0, 0, 0, 1.0) 100%);
            opacity        : 0.9;
            pointer-events : none;
            z-index        : 3;
        }

        &.in_room:before {
            opacity : 0.5;
        }

        &:after {
            background     : url("../img/noise.gif") repeat;
            mix-blend-mode : multiply;
            opacity        : 0.09;
            pointer-events : none;
            z-index        : 5;
        }

        & .zone_ruine_game, & > .zone_ruine_game-controls {
            position   : absolute;
            transition : opacity 0.25s ease, transform 0.25s ease, top 0.75s ease, left 0.75s ease;
        }

        & .zone_ruine_game {
            display   : inline-block;
            font-size : 0;
            height    : 100%;
            left      : 0;
            margin    : 0;
            padding   : 0;
            top       : 0;
            width     : 100%;
            z-index   : 2;

            & .decos > .deco_ambiance {
                background-position : center;
                background-repeat   : no-repeat;
                background-size     : contain;
            }

            &.zone_ruine_game_skin-bunker {
                & .decos > .deco_ambiance.decos_ambiance-1a {
                    background-image : url("../img/ruine/bunker-deco-1a.png");
                    height           : 9%;
                    left             : 37%;
                    top              : 27%;
                    width            : 6%;
                }

                & .decos > .deco_ambiance.decos_ambiance-3a {
                    background-image : url("../img/ruine/bunker-deco-3a.png");
                    height           : 9%;
                    left             : 58%;
                    top              : 27%;
                    width            : 6%;
                }

                & .decos > .deco_ambiance.decos_ambiance-1b {
                    background-image : url("../img/ruine/bunker-deco-1b.png");
                    height           : 20%;
                    left             : 32%;
                    top              : 12%;
                    width            : 14%;
                }

                & .decos > .deco_ambiance.decos_ambiance-3b {
                    background-image : url("../img/ruine/bunker-deco-3b.png");
                    height           : 20%;
                    left             : 54%;
                    top              : 12%;
                    width            : 14%;
                }

                & .decos > .deco_ambiance.decos_ambiance-4 {
                    background-image : url("../img/ruine/bunker-deco-4.png");
                    height           : 8%;
                    left             : 18%;
                    top              : 31%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-7 {
                    background-image : url("../img/ruine/bunker-deco-7.png");
                    height           : 8%;
                    left             : 76%;
                    top              : 31%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-10a {
                    background-image : url("../img/ruine/bunker-deco-10a.png");
                    height           : 8%;
                    left             : 18%;
                    top              : 62%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-13a {
                    background-image : url("../img/ruine/bunker-deco-13a.png");
                    height           : 8%;
                    left             : 76%;
                    top              : 62%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-10b {
                    background-image : url("../img/ruine/bunker-deco-10b.png");
                    height           : 7%;
                    left             : 14%;
                    top              : 65%;
                    width            : 11%;
                }

                & .decos > .deco_ambiance.decos_ambiance-13b {
                    background-image : url("../img/ruine/bunker-deco-13b.png");
                    height           : 7%;
                    left             : 80%;
                    top              : 65%;
                    width            : 11%;
                }

                & .decos > .deco_ambiance.decos_ambiance-14 {
                    background-image : url("../img/ruine/bunker-deco-14.png");
                    height           : 18%;
                    left             : 34%;
                    top              : 69%;
                    width            : 4%;
                }

                & .decos > .deco-ambient.decos_ambiance-16 {
                    background-image : url("../img/ruine/bunker-deco-16.png");
                    height           : 18%;
                    left             : 62%;
                    top              : 69%;
                    width            : 4%;
                }
            }

            &.zone_ruine_game_skin-hotel {
                //noinspection DuplicatedCode
                & .decos > .deco_ambiance.decos_ambiance-1 {
                    background-image : url("../img/ruine/hotel-deco-1.png");
                    height           : 13%;
                    left             : 35%;
                    top              : 27%;
                    width            : 8%;
                }

                & .decos > .deco_ambiance.decos_ambiance-3 {
                    background-image : url("../img/ruine/hotel-deco-3.png");
                    height           : 13%;
                    left             : 57%;
                    top              : 27%;
                    width            : 8%;
                }

                & .decos > .deco_ambiance.decos_ambiance-4a {
                    background-image : url("../img/ruine/hotel-deco-4a.png");
                    height           : 12%;
                    left             : 22%;
                    top              : 31%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-7a {
                    background-image : url("../img/ruine/hotel-deco-7a.png");
                    height           : 12%;
                    left             : 70%;
                    top              : 31%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-5a {
                    background-image : url("../img/ruine/hotel-deco-5a.png");
                    height           : 9%;
                    left             : 30%;
                    top              : 39%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-5b {
                    background-image : url("../img/ruine/hotel-deco-5b.png");
                    height           : 9%;
                    left             : 30%;
                    top              : 39%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-6a {
                    background-image : url("../img/ruine/hotel-deco-6a.png");
                    height           : 9%;
                    left             : 54%;
                    top              : 39%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-6b {
                    background-image : url("../img/ruine/hotel-deco-6b.png");
                    height           : 9%;
                    left             : 54%;
                    top              : 39%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-8a {
                    background-image : url("../img/ruine/hotel-deco-8a.png");
                    height           : 25%;
                    left             : 5%;
                    top              : 37%;
                    width            : 32%;
                }

                & .decos > .deco_ambiance.decos_ambiance-8b {
                    background-image : url("../img/ruine/hotel-deco-8b.png");
                    height           : 9%;
                    left             : 20%;
                    top              : 45%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-9a {
                    background-image : url("../img/ruine/hotel-deco-9a.png");
                    height           : 25%;
                    left             : 65%;
                    top              : 37%;
                    width            : 32%;
                }

                & .decos > .deco_ambiance.decos_ambiance-9b {
                    background-image : url("../img/ruine/hotel-deco-9b.png");
                    height           : 9%;
                    left             : 75%;
                    top              : 45%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-11a {
                    background-image : url("../img/ruine/hotel-deco-11a.png");
                    height           : 9%;
                    left             : 30%;
                    top              : 53%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-11b {
                    background-image : url("../img/ruine/hotel-deco-11b.png");
                    height           : 9%;
                    left             : 30%;
                    top              : 53%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-12a {
                    background-image : url("../img/ruine/hotel-deco-12a.png");
                    height           : 9%;
                    left             : 54%;
                    top              : 53%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-12b {
                    background-image : url("../img/ruine/hotel-deco-12b.png");
                    height           : 9%;
                    left             : 54%;
                    top              : 53%;
                    width            : 18%;
                }

                & .decos > .deco_ambiance.decos_ambiance-2a {
                    background-image : url("../img/ruine/hotel-deco-2a.png");
                    height           : 32%;
                    left             : 37%;
                    top              : 5%;
                    width            : 25%;
                }

                & .decos > .deco_ambiance.decos_ambiance-15a {
                    background-image : url("../img/ruine/hotel-deco-15a.png");
                    height           : 32%;
                    left             : 37%;
                    top              : 65%;
                    width            : 25%;
                }

                & .decos > .deco_ambiance.decos_ambiance-2b {
                    background-image : url("../img/ruine/hotel-deco-2b.png");
                    height           : 18%;
                    left             : 52%;
                    top              : 6%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-15b {
                    background-image : url("../img/ruine/hotel-deco-15a.png");
                    height           : 18%;
                    left             : 40%;
                    top              : 75%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-4b {
                    background-image : url("../img/ruine/hotel-deco-4b.png");
                    height           : 8%;
                    left             : 13%;
                    top              : 34%;
                    width            : 14%;
                }

                & .decos > .deco_ambiance.decos_ambiance-7b {
                    background-image : url("../img/ruine/hotel-deco-7b.png");
                    height           : 8%;
                    left             : 72%;
                    top              : 34%;
                    width            : 14%;
                }

                & .decos > .deco_ambiance.decos_ambiance-10 {
                    background-image : url("../img/ruine/hotel-deco-10.png");
                    height           : 8%;
                    left             : 13%;
                    top              : 58%;
                    width            : 14%;
                }

                & .decos > .deco_ambiance.decos_ambiance-13 {
                    background-image : url("../img/ruine/hotel-deco-13.png");
                    height           : 8%;
                    left             : 72%;
                    top              : 58%;
                    width            : 14%;
                }
            }

            &.zone_ruine_game_skin-hopital {
                //noinspection ALL
                & .decos > .deco_ambiance.decos_ambiance-1a {
                    background-image : url("../img/ruine/hopital-deco-1a.png");
                    height           : 11%;
                    left             : 34%;
                    top              : 27%;
                    width            : 10%;
                }

                & .decos > .deco_ambiance.decos_ambiance-3a {
                    background-image : url("../img/ruine/hopital-deco-3a.png");
                    height           : 11%;
                    left             : 57%;
                    top              : 27%;
                    width            : 10%;
                }

                & .decos > .deco_ambiance.decos_ambiance-1b {
                    background-image : url("../img/ruine/hopital-deco-1b.png");
                    height           : 17%;
                    left             : 37%;
                    top              : 15%;
                    width            : 7%;
                }

                & .decos > .deco_ambiance.decos_ambiance-3b {
                    background-image : url("../img/ruine/hopital-deco-3b.png");
                    height           : 17%;
                    left             : 57%;
                    top              : 15%;
                    width            : 7%;
                }

                & .decos > .deco_ambiance.decos_ambiance-5a {
                    background-image : url("../img/ruine/hopital-deco-5a.png");
                    height           : 9%;
                    left             : 39%;
                    top              : 39%;
                    width            : 10%;
                }

                & .decos > .deco_ambiance.decos_ambiance-5b {
                    background-image : url("../img/ruine/hopital-deco-5b.png");
                    height           : 9%;
                    left             : 39%;
                    top              : 39%;
                    width            : 10%;
                }

                & .decos > .deco_ambiance.decos_ambiance-6a {
                    background-image : url("../img/ruine/hopital-deco-6a.png");
                    height           : 10%;
                    left             : 54%;
                    top              : 39%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-6b {
                    background-image : url("../img/ruine/hopital-deco-6b.png");
                    height           : 10%;
                    left             : 54%;
                    top              : 39%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-8a {
                    background-image : url("../img/ruine/hopital-deco-8a.png");
                    height           : 10%;
                    left             : 20%;
                    top              : 45%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-8b {
                    background-image : url("../img/ruine/hopital-deco-8b.png");
                    height           : 10%;
                    left             : 20%;
                    top              : 45%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-9a {
                    background-image : url("../img/ruine/hopital-deco-9a.png");
                    height           : 10%;
                    left             : 75%;
                    top              : 45%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-9b {
                    background-image : url("../img/ruine/hopital-deco-9b.png");
                    height           : 10%;
                    left             : 75%;
                    top              : 45%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-11a {
                    background-image : url("../img/ruine/hopital-deco-11a.png");
                    height           : 9%;
                    left             : 39%;
                    top              : 53%;
                    width            : 10%;
                }

                & .decos > .deco_ambiance.decos_ambiance-11b {
                    background-image : url("../img/ruine/hopital-deco-11b.png");
                    height           : 9%;
                    left             : 39%;
                    top              : 53%;
                    width            : 10%;
                }

                & .decos > .deco_ambiance.decos_ambiance-12a {
                    background-image : url("../img/ruine/hopital-deco-12a.png");
                    height           : 10%;
                    left             : 54%;
                    top              : 53%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-12b {
                    background-image : url("../img/ruine/hopital-deco-12b.png");
                    height           : 10%;
                    left             : 54%;
                    top              : 53%;
                    width            : 9%;
                }

                & .decos > .deco_ambiance.decos_ambiance-14 {
                    background-image : url("../img/ruine/hopital-deco-14.png");
                    height           : 9%;
                    left             : 33%;
                    top              : 78%;
                    width            : 4%;
                }

                & .decos > .deco_ambiance.decos_ambiance-16 {
                    background-image : url("../img/ruine/hopital-deco-16.png");
                    height           : 9%;
                    left             : 63%;
                    top              : 78%;
                    width            : 4%;
                }
            }

            & > .personnage {
                height    : 0;
                overflow  : visible;
                position  : absolute;
                transform : scale(1.5);
                width     : 0;

                &:before {
                    content  : " ";
                    height   : 24px;
                    left     : -12px;
                    position : absolute;
                    top      : -12px;
                    width    : 24px;
                }

                &.citoyen:before {
                    background : url("../img/ruine/you.gif") center/contain no-repeat;
                }

                &.zombie:before {
                    background : url("../img/ruine/zombie.gif") center/contain no-repeat;
                }

                &.zombie_dead:before {
                    background : url("../img/ruine/dead.png") center/contain no-repeat;
                }

            }
        }

        & > .personnage {
            height    : 0;
            overflow  : visible;
            position  : absolute;
            transform : scale(1.5);
            width     : 0;

            &:before {
                content  : " ";
                height   : 24px;
                left     : -12px;
                position : absolute;
                top      : -12px;
                width    : 24px;
            }

            &.citoyen:before {
                background : url("../img/ruine/you.gif") center/contain no-repeat;
            }

        }

        & > .zone_ruine_game-controls {
            height         : 100%;
            left           : 0;
            pointer-events : none;
            top            : 0;
            width          : 100%;
            z-index        : 4;

            &:not(.hide) > * {
                pointer-events : all;
            }

            &.hide {
                opacity : 0;
            }

            & > .action-move {
                background       : url(../img/fleche_2.png) 50% / contain no-repeat, url(../img/fleche_1.png) 50% / contain no-repeat;
                bottom           : auto;
                cursor           : pointer;
                height           : 41px;
                left             : auto;
                position         : absolute;
                right            : auto;
                top              : auto;
                transform-origin : center;
                width            : 81px;
                z-index          : 4;

                &.on-route {
                    filter : hue-rotate(90deg);
                }

                &:hover {
                    background : url(../img/fleche_3.png) 50% no-repeat;
                }

                &.action-move-north {
                    left      : 50%;
                    top       : 2%;
                    transform : translateX(-50%);
                }

                &.action-move-east {
                    right     : -5%;
                    top       : 50%;
                    transform : translateY(-50%) rotate(90deg);
                }

                &.action-move-south, &.action-move-unshift {
                    bottom    : 2%;
                    left      : 50%;
                    transform : translateX(-50%) rotate(180deg);
                }

                &.action-move-west {
                    left      : -5%;
                    top       : 50%;
                    transform : translateY(-50%) rotate(270deg);
                }
            }
        }
    }
}

.score_game_ruine {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

#zone_score_ruine_game {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 3px;
    justify-content : center;
    margin-bottom   : 10px;
}

.score_game_ruine_libelle {
    align-items     : center;
    display         : flex;
    gap             : 3px;
    justify-content : center;
}

.option_ruine_input {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

#ruine_gem_regle_jeu {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 6px;
    justify-content : center;

    p {
        margin-bottom : 2px;
        text-indent   : 20px;

        > span {
            margin-left : 5px;
        }
    }
}

#list_chargement_historique {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
}

#zone_historique_game {
    align-content    : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 5px;
    justify-content  : center;
    width            : 840px;
}

#zone_historique_game table {
    display   : block;
    font-size : 0.9rem;
    width     : 100%;

    tr, th, td {
        border  : solid var(--primary-border-color) 1px;
        height  : 24px;
        padding : 0;
    }

    tr {
        width : 100%;
    }

    td, th {
        text-align : center;
    }

    thead {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--primary-row-color);
    }
}

.histo_ruine_game_date {
    width : 150px;
}

.histo_ruine_game_carte {
    width : 110px;
}

.histo_ruine_game_options, .histo_ruine_game_score {
    width : 50px;
}

.histo_ruine_game_voir_ruine {
    width : 70px;
}

#etage_ruine_game_vision {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;

    & > .pagination {
        margin : 0 0 5px;

        & > .page-link {
            color           : var(--bs-pagination-color) !important;
            text-decoration : none !important;
        }
    }
}

#zone_recap_map {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;

    > fieldset {
        border        : 1px solid var(--primary-border-color);
        margin-bottom : 5px;
        width         : 80%;
    }
}

.ruineCarte_zomb_legend {
    height   : 24px;
    margin   : 0;
    position : absolute;
    width    : 24px;
}

.ruineCarte_fouille {
    height   : 24px;
    margin   : 0;
    position : absolute;
    width    : 24px;
    z-index  : 10;
}

#zone_legend_map {
    align-items           : center;
    display               : grid;
    gap                   : 5px;
    grid-template-columns : 1fr 1fr;
    justify-items         : start;
}

.legende_map {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.zone_ruine_game_bas {
    top : 100%;
}

.zone_ruine_game_haut {
    top : -100%;
}

.zone_ruine_game_gauche {
    left : -100%;
}

.zone_ruine_game_droite {
    left : 100%;
}