#tourDeGuetForm {
    align-items    : center;
    display        : flex;
    flex-direction : column;
    margin         : auto;
    width          : 950px;
}

#group-jour-tdg {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    min-width       : 400px;

    #jour {
        margin-right : 5px
    }
}

#group-estim-atk {
    align-content   : center;
    align-items     : stretch;
    display         : flex;
    gap             : 5px;
    justify-content : space-around;
    margin-bottom   : 10px;
    width           : 100%;
}

.formTourDeGuet {
    width : 540px;
}

.formTourDeGuet > div {
    padding : 0.2em;
    width   : 100%;
}

.formCalculAtk > div {
    padding : 0.2em;
    width   : 100%;
}

#zoneBlocEstimTDG {
    align-content   : center;
    align-items     : flex-start;
    /* gap: 10px; */
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    width           : 100%;

}

#zoneEstimTDG, #zoneEstimPlanif {
    border : 1px solid var(--primary-border-color);
    width  : 265px;

    p {
        margin     : 2px;
        text-align : center;
        width      : 100%;
    }

    textarea {
        display    : block;
        margin     : auto auto 5px;
        min-height : 140px;
        width      : 255px;
    }

    .zone_saisie_input_tdg {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 3px;
        justify-content : center;
        margin          : auto auto 5px;
        width           : 255px;

        > button {
            margin-top : 3px
        }
    }
}


input[name*='atkEstim'], input[name*='atkVisee'], input[name*='atkEstimMarge'], input[name*='atkPlanif'], input[name*='atkPlanifMarge'], input[name*='atkPlanifVisee'] {
    width : 60px;
}

.formCalculAtk {
    border  : 1px solid var(--primary-border-color);
    padding : 5px;
    width   : 350px;
}

.warnTDG {
    font-size : 0.8em;
}

#calculAtk_planif {
    display : none;
}

.group_input_tdg {
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 250px;
}

.input_tdg_pct {
    width : 42px;
}

.input_tdg_min, .input_tdg_max {
    width : 60px
}

.options-container-tdg {
    align-items     : center;
    display         : flex;
    justify-content : space-between;
}

.buttons-tdg {
    display : flex;
    gap     : 10px; /* Espace entre les boutons */
}

.supp_estim_tdg {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 22px;
    justify-content : center;
    padding         : 0;
    width           : 30px;
}

.icon_tdg {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 20px;
    justify-content : center;
    width           : 20px;

}

.group_add_estim_tdg {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

#calcul_tdg_theorique {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;

    fieldset {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;
    }
}

.min_max_theorique_tdg {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100px;
}