.blocNews {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : 20px;
    color            : var(--primary-font-color);
    margin           : 0.5em;
    padding          : 1em;
}

.blocNews h2 {
    margin-bottom   : 1rem;
    text-align      : center;
    text-decoration : underline;
    width           : 100%;
}

.contenuNews {
    font-size  : 1rem;
    text-align : justify;
    width      : 100%;
}

.lignAutheurDateNews {
    display         : flex;
    justify-content : space-between;
}

.authorNews, .dateModifNews {
    font-size : 0.7rem;
}

.changelog_content {
    width : 65px;
}

ul.contenu_changelog {
    li {
        align-items     : flex-start;
        /* margin-top: 0px; */
        display         : flex;
        justify-content : flex-start;
        margin-bottom   : 6px;

        div.badge {
            padding : 2px 4px;
        }
    }
}

.ligne_changelog {
    font-size    : 1rem !important;
    line-height  : 1.1rem;
    margin-left  : 5px;
    margin-right : 5px;
    width        : 1015px;
}