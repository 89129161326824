.ressourceChantier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-evenly;
    width           : 50px;
}

#ency_chantier {
    margin : auto;
    width  : 1200px;

    > ul > li {
        cursor           : pointer;
        float            : left;
        line-height      : 26px; /* on définit une hauteur pour chaque élément */
        list-style       : none; /* on supprime le style par défaut de la liste */
        margin           : 0; /* ni extérieure */
        padding          : 0; /* Pas de marge intérieure */
        text-align       : center; /* on centre le texte qui se trouve dans la liste */
        width            : 250px;

        > button {
            width : 250px;
        }

    }
}


#listChantier {

    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    justify-content  : center;
    width            : 1198px;


    table tbody {
        tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }
    }
}

#listChantier h2 {
    margin     : auto;
    text-align : center;
    width      : 250px;
}

#listEvoChantier {

    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    width            : 1198px;

    table {
        margin : 20px;
        width  : 1158px;

        tr, td, th {
            border : 1px solid var(--primary-border-color);
        }

        thead {
            background-color : var(--tertiary-color);
            border-bottom    : 2px solid var(--primary-border-color);

            th {
                height : 40px
            }
        }

        tbody {
            tr:nth-child(odd) {
                background-color : var(--primary-row-color);
            }

            tr:nth-child(even) {
                background-color : var(--secondary-row-color);
            }
        }
    }
}

#listChantierPlansRuine {

    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    width            : 1198px;

    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;
        margin-bottom   : 10px;
        margin-top      : 10px;
    }

    .ruine_list {
        border : 1px solid var(--primary-font-color);
        width  : 240px;
    }

    .plans_lvl_2, .plans_lvl_3, .plans_lvl_4 {
        align-content   : flex-start;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        flex-wrap       : wrap;
        justify-content : flex-start;
        width           : 100%;
    }

    .plans_lvl_2 {
        background-color : rgba(255, 255, 0, .1);
        min-height       : 180px;
    }

    .plans_lvl_3 {
        background-color : rgba(0, 0, 255, 0.1);
        min-height       : 200px;
    }

    .plans_lvl_4 {
        background-color : rgba(255, 0, 255, 0.1);
        min-height       : 140px;
    }

    .ligne_plans_ruine {
        padding-left : 5px;
        width        : 100%;

        > span {
            margin-left : 4px;
        }
    }

    .ligne_plans_ruine:first-child {
        margin-top : 4px;
    }
}

.nomEvoChantier {
    text-align : center;
    width      : 200px;
}

.effetEvoChantier {

    width : 170px;

    div {
        display        : flex;
        flex-direction : column;
        width          : 100%;

        > span {
            display    : inline-block;
            text-align : center;
            width      : 100%;
        }
    }


}

#listEvoChantier h2 {
    margin     : auto;
    text-align : center;
    width      : 350px;
}

.chantier_name_espacement {
    display : flex;
    gap     : 5px;
    align-items: center;
}

#cumulRessource {
    margin : auto;
    width  : 400px;

    thead {
        width : 400px;

        tr {
            background-color : var(--tertiary-color);
        }
    }

    tr, th, td {
        border : 1px solid var(--primary-border-color);

    }

    tbody {
        tr:nth-child(odd) {
            background-color : var(--primary-row-color);
        }

        tr:nth-child(even) {
            background-color : var(--secondary-row-color);
        }
    }

    .chantier_ress {
        width : 250px;

        div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 5px;
            justify-content : flex-start;
            width           : 100%;
        }
    }

    .chantier_nbr {
        text-align : center;
        width      : 75px;
    }

}

#cumul_ressources_chantier {
    margin-bottom : 10px;

    fieldset {
        border : 1px solid var(--primary-border-color);

        > div {
            align-items    : center;
            display        : flex;
            flex-direction : column;
            gap            : 10px;

            > div#option_liste_cumul {
                align-content   : center;
                align-items     : center;
                display         : flex;
                gap             : 5px;
                justify-content : center;
            }
        }
    }
}

.ency_plan_chantier {
    align-items           : center;
    display               : grid;
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
    justify-items         : center;
}