#dechargeCorps {
    width : 100%;
}

#dechargeCorps table {
    border-collapse : collapse;
    margin          : auto;

    tbody {
        tr:nth-child(odd) {
            background-color : var(--primary-row-color);
        }

        tr:nth-child(even) {
            background-color : var(--secondary-row-color);
        }
    }
}

#dechargeCorps table tr, #dechargeCorps table td, #dechargeCorps table th {
    border : solid 1px var(--primary-border-color);
}

.col_dech_def, .col_dech_itemAff, .col_dech_defApport, .col_dech_estim, .col_dech_totBanque, .col_dech_util {
    text-align : center;
}

.col_dech_estim div input, .col_dech_util div input {
    width : 50px;
}

.col_dech_defApport div input {
    width : 65px;
}

.col_dech_itemAff {
    width : 115px;
}

.col_dech_def {
    width : 50px;
}

.col_dech_estim, .col_dech_totBanque, .col_dech_util {
    width : 65px;
}

.col_dech_defApport {
    width : 80px;
}

#mention_sauvegarde_decharge span {
    display    : inline-block;
    font-size  : 0.8rem;
    text-align : center;
    width      : 100%;
}

#dechargeCorps table thead th {
    background-color : var(--tertiary-color);
    font             : var(--primary-font-color);
}

#boutonDecharge {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 50px;
    justify-content : space-around;
    margin          : auto;
    width           : 459px;
}

.ligneTotalDecharge {
    background-color : var(--tertiary-color);
    font             : var(--primary-font-color);
}