#banqueTab {
    border-left  : 1px solid var(--primary-border-color);
    border-right : 1px solid var(--primary-border-color);
    border-top   : 1px solid var(--primary-border-color);
    margin       : auto;
    width        : 50%;
}

.catBanque {
    width : 100%;
}

.catBanqueTitre {
    background-color : var(--tertiary-color);
    border-bottom    : 1px solid var(--primary-border-color);
    font-size        : 1em;
    font-weight      : bold;
    text-align       : center;
    width            : 100%;
}

.objetBanque {
    border-bottom : 1px solid var(--primary-border-color);
    display       : flex;
    flex-wrap     : wrap;
    width         : 100%;
}

.totalBanque {
    background-color : var(--tertiary-color);
    border-bottom    : 1px solid var(--primary-border-color);
    font-size        : 1em;
    font-weight      : bold;
    text-align       : left;
    width            : 100%;
}

#popUDetailItem {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    font-size        : 14px;
    left             : 50%; /* positionnement au milieu de l'écran */
    min-height       : 100px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 600px;
    z-index          : 1110;

    > div {
        max-height : 500px;
        overflow-y : auto;
    }
}