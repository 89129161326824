// Default styles
.ruleGroup {
    background     : $rqb-background-color;
    border-color   : $rqb-border-color;
    border-radius  : $rqb-border-radius;
    border-style   : $rqb-border-style;
    border-width   : $rqb-border-width;
    display        : flex;
    flex-direction : column;
    gap            : $rqb-spacing;
    padding        : $rqb-spacing;

    .ruleGroup-body {
        display        : flex;
        flex-direction : column;
        gap            : $rqb-spacing;

        &:empty {
            display : none;
        }
    }

    .ruleGroup-header,
    .rule {
        align-items : center;
        display     : flex;
        gap         : $rqb-spacing;
    }

    .rule {
        .rule-value:has(.rule-value-list-item) {
            align-items : baseline;
            display     : flex;
            gap         : $rqb-spacing;
        }
    }
}

// #region Drag-and-drop

// Hover styles
[data-inlinecombinators='disabled'] {
    .dndOver {
        &.rule,
        &.ruleGroup-header {
            border-bottom-color : $rqb-dnd-hover-border-bottom-color;
            border-bottom-style : $rqb-dnd-hover-border-bottom-style;
            border-bottom-width : $rqb-dnd-hover-border-bottom-width;
            padding-bottom      : $rqb-spacing;

            &.dndCopy {
                border-bottom-color : $rqb-dnd-hover-copy-border-bottom-color;
            }
        }
    }
}

[data-inlinecombinators='enabled'] {
    .dndOver {
        &.rule:last-child,
        &.ruleGroup-header,
        &.rule + .betweenRules,
        &.betweenRules {
            border-bottom-color : $rqb-dnd-hover-border-bottom-color;
            border-bottom-style : $rqb-dnd-hover-border-bottom-style;
            border-bottom-width : $rqb-dnd-hover-border-bottom-width;
            padding-bottom      : $rqb-spacing;

            &.dndCopy {
                border-bottom-color : $rqb-dnd-hover-copy-border-bottom-color;
            }
        }
    }
}

// Drag styles
.ruleGroup,
.rule {
    &.dndDragging {
        opacity : 0.5;
    }

    .queryBuilder-dragHandle {
        cursor : move;
    }
}

// #endregion

// #region Branches
.queryBuilder-branches {
    .ruleGroup-body {
        margin-left : calc(2 * #{$rqb-branch-indent});
    }

    .rule,
    .ruleGroup .ruleGroup {
        position : relative;

        &::before,
        &::after {
            border-color  : $rqb-branch-color;
            border-radius : 0;
            border-style  : $rqb-branch-style;
            content       : '';
            left          : calc(-#{$rqb-branch-indent} - #{$rqb-branch-width});
            position      : absolute;
            width         : $rqb-branch-indent;
        }

        &::before {
            border-width : 0 0 $rqb-branch-width $rqb-branch-width;
            height       : calc(50% + #{$rqb-spacing});
            top          : -$rqb-spacing;
        }

        &:last-child::before {
            border-bottom-left-radius : $rqb-branch-radius;
        }

        &::after {
            border-width : 0 0 0 $rqb-branch-width;
            height       : 50%;
            top          : 50%;
        }

        &:last-child::after {
            display : none;
        }
    }

    .ruleGroup .ruleGroup {
        &::before,
        &::after {
            left : calc(calc(-#{$rqb-branch-indent} - #{$rqb-branch-width}) - #{$rqb-border-width});
        }

        &::before {
            height : calc(50% + #{$rqb-spacing} + #{$rqb-border-width});
            top    : calc(-#{$rqb-spacing} - #{$rqb-border-width});
        }

        &::after {
            height : calc(50% + #{$rqb-border-width});
        }
    }

    .betweenRules {
        position : relative;

        &::before {
            border-color  : $rqb-branch-color;
            border-radius : 0;
            border-style  : $rqb-branch-style;
            border-width  : 0 0 0 $rqb-branch-width;
            content       : '';
            height        : calc(100% + #{$rqb-spacing});
            left          : calc(-#{$rqb-branch-indent} - #{$rqb-branch-width});
            position      : absolute;
            top           : -$rqb-spacing;
            width         : $rqb-branch-indent;
        }
    }
}

// #endregion
