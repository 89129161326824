.jumpCorps {
    thead {
        background-color : var(--tertiary-color);
    }
}

#list_coalition {
    tbody tr:nth-child(even) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--primary-row-color);
    }
}

#repartitionMetier {
    font-size : 1rem;
    margin    : auto;
    width     : 1100px;

    tr, th, td {
        border  : solid var(--primary-border-color) 1px;
        padding : 0;
    }

    td, th {
        text-align : center;
    }
}

.repartition_job_pseudo {
    width : 250px;

    a {
        color           : var(--primary-font-color);
        text-decoration : none;
    }
}

.repartition_job_leg, .repartition_job_arma, .repartition_job_cama, .repartition_job_comp {
    width : 20px;
}

.repartition_job_pouvoir {
    width : 170px;
}

.repartition_job_voeux {
    width : 130px;
}

.repartition_job_choix {
    width : 150px;
}

.repartition_comp_choix {
    width : 240px;
}

table.coalition {
    display   : block;
    font-size : 0.9rem;

    tr, th, td {
        border  : solid var(--primary-border-color) 1px;
        height  : 24px;
        padding : 0;
    }

    td, th {
        text-align : center;
    }

    tbody tr:nth-child(even) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--primary-row-color);
    }
}

#table_coalition {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 15px;
    justify-content : center;
    padding-bottom  : 20px;
}

.coalition_pos, .coalition_moyenContact {
    width : 20px;
}

.coalition_pseudo {
    width : 150px;
}

.coalition_metier {
    width : 120px;
}

.coalition_statut, .coalition_dispo {
    width : 85px;

    select {
        width : 80px;
    }
}

.coalition_statut select, .coalition_dispo select {
    margin  : 1px;
    padding : 1px;

}

.title_coalition {
    height : 40px;
}

.coalition_1 {
    background-color : rgba(255, 0, 20, 0.4);
}

.coalition_2 {
    background-color : rgba(255, 165, 0, 0.4);
}

.coalition_3 {
    background-color : rgba(12, 255, 0, 0.4);
}

.coalition_4 {
    background-color : rgba(0, 228, 255, 0.4);
}

.coalition_5 {
    background-color : rgba(2, 0, 255, 0.4);
}

.coalition_6 {
    background-color : rgba(237, 0, 255, 0.41);
}

.coalition_7 {
    background-color : rgba(255, 254, 0, 0.4);
}

.coalition_8 {
    background-color : rgba(146, 0, 255, 0.41);
}


#infoCoa {
    display       : flex;
    margin-bottom : 4px;
    width         : 100%;
}

#zone_total_coa {
    width : 100%;


    > ul > li {
        cursor      : pointer;
        float       : left;
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
        width       : 250px;

        > button {
            width : 250px;
        }
    }

    > div {
        border-bottom : 2px solid var(--primary-border-color);
        border-left   : 2px solid var(--primary-border-color);
        border-right  : 2px solid var(--primary-border-color);
    }
}

#zone_coalition, #zone_repartition {
    background-color : var(--secondary-color);
    width            : 100%;

    fieldset {
        border : 2px solid var(--primary-border-color);
        margin : auto;
    }
}

#zone_repartition {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;
    padding-bottom  : 10px;
    padding-top     : 10px;
}

.boutonMenuCoalition {
    background-color        : var(--secondary-color);
    border-bottom           : 2px solid var(--primary-border-color);
    border-left             : 2px solid var(--primary-border-color);
    border-right            : 2px solid var(--primary-border-color);
    border-top              : 2px solid var(--primary-border-color);
    border-top-left-radius  : 5px;
    border-top-right-radius : 5px;
    margin-bottom           : -2px;
    width                   : 100px;
}


option.presCoa {
    color       : var(--error-color);
    font-weight : bold;
}

#placementCoalition {
    > ul {
        font-size : 0.8rem;
        margin    : 0 0 5px;
    }

    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : nowrap;
        justify-content : center;
        width           : 100%;
    }

}

#blocFormCoa {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

table#coalition_compteur {
    display   : block;
    font-size : 1rem;
    margin    : auto;
    width     : 415px;

    tr, th, td {
        border  : solid var(--primary-border-color) 1px;
        padding : 0;
    }

    td, th {
        padding    : 2px;
        text-align : center;
    }

    thead {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }

    th.coalition_title_number {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }
}

table#repartitionMetier {
    tr, th, td {
        border  : solid var(--primary-border-color) 1px;
        padding : 0;
    }

    thead {
        background-color : var(--tertiary-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--primary-row-color);
    }

}

.coalition_title_number {
    width : 100px;
}

.coalition_number {
    width : 25px;
}

#formRepartitionMetier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : nowrap;
    justify-content : flex-end;
}

#list_jump_aucun {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 100%;

}

#zone-btn-extraction-coalitions {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
    margin          : 5px 0;
}

#contenuTextlistJoueurCoalitions {
    background-color : white;
    border           : 1px solid var(--primary-border-color);
    color            : black;
    font-size        : 0.8em;
    margin-bottom    : 4px;
    min-height       : 60px;
    padding          : 5px;
    width            : 99%;
}