#zone_tab_expedition {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 30px;
    justify-content : center;
    width           : 100%;

    div.zone_expedition_group_part {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 10px;
        justify-content : center;
        width           : 100%;

        div.zone_expedition_expe_part {
            width : 100%;

            table.inscription_expedition {
                background-color : var(--secondary-color);
                border           : 3px solid var(--primary-border-color);
                width            : 100%;

                td, tr, th {
                    border     : 1px solid var(--primary-border-color);
                    text-align : center;
                }

                thead {
                    background-color : var(--tertiary-color);
                }

                tbody tr.ligne_citoyen_user:nth-child(odd) {
                    background-color : var(--primary-row-color);
                }

                tbody tr.ligne_citoyen_user:nth-child(even) {
                    background-color : var(--secondary-row-color);
                }

                tbody th {
                    background-color : var(--tertiary-color);
                }

                .ligne_inscription_expedition {
                    height : 20px;
                }

                .cols40_expedition {
                    text-align : center;
                    width      : 50px;
                }

                .cols20_expedition {
                    text-align : center;
                    width      : 25px;
                }

                .pseudo_expedition {
                    width : 150px;

                    .pseudo_expedition_liste {
                        align-content   : center;
                        align-items     : center;
                        display         : flex;
                        gap             : 5px;
                        justify-content : center;
                    }
                }

                .sac_expedition {
                    width : 200px;
                }

                .ah_expedition {
                    width : 80px;
                }

                .zone_td_display {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    justify-content : center;
                }

                div.zone_sac_expeditionnaire {
                    margin : 2px;

                    > .sac_expeditionnaire {
                        align-content   : center;
                        align-items     : center;
                        display         : flex;
                        justify-content : flex-start;
                        justify-items   : center;
                        padding-left    : 5px;
                    }
                }

                .consigne_zone_checkbox > div {
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : flex-start;
                    padding-left    : 5px;
                }

                .text_consigne_zone_consigne {
                    margin : 0;

                    p {
                        margin : 0;
                    }
                }

                .description_expedition_part {
                    padding-left : 5px;
                    text-align   : start;

                    > p {
                        margin : 0;
                    }
                }

                .pdc_zone_tab > div {
                    align-items     : center;
                    display         : flex;
                    font-size       : 19px;
                    gap             : 5px;
                    justify-content : center;
                }

                .expedition_titre {
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }

                .preinscrit_expeditions {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    gap             : 10px;
                    justify-content : center;

                    .preinscrit {
                        align-content   : center;
                        align-items     : center;
                        display         : flex;
                        justify-content : center;

                        > span {
                            align-content   : center;
                            align-items     : center;
                            display         : flex;
                            gap             : 5px;
                            justify-content : center;
                        }
                    }
                }


            }

        }
    }

    div.zone_expedition_mask_group_part {
        display : none;
    }

}

#zone_tab_ouvrier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 30px;
    justify-content : center;
    width           : 100%;

    table.inscription_ouvrier {
        background-color : var(--secondary-color);
        border           : 3px solid var(--primary-border-color);
        width            : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--primary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--secondary-row-color);
        }

        tbody th {
            background-color : var(--tertiary-color);
        }

        .ligne_inscription_ouvrier {
            height : 20px;
        }

        .cols40_ouvrier {
            text-align : center;
            width      : 50px;
        }

        .pseudo_ouvrier {
            width : 150px;
        }

        .td_sac_ouvrier {
            width : 200px;
        }

        .zone_td_display {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : center;
        }

        div.zone_sac_ouvrier {
            margin : 2px;

            > .sac_ouvrier {
                align-content   : center;
                align-items     : center;
                display         : flex;
                justify-content : flex-start;
                justify-items   : center;
                padding-left    : 5px;
            }
        }

        .consigne_ouvrier_inscription {
            padding-left : 5px;

            p {
                margin : 0;
            }
        }

        .description_expedition_part {
            padding-left : 5px;
            text-align   : start;
        }

        .pdc_zone_tab > div {
            align-items     : center;
            display         : flex;
            gap             : 5px;
            justify-content : center;
        }

        .expedition_titre {
            align-items     : center;
            display         : flex;
            gap             : 5px;
            justify-content : center;
        }

    }


}

#zone-inscription_expedition {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    font-size       : 14px;
    justify-content : center;

    > fieldset {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        width            : 50%;

        legend {
            color     : var(--primary-font-color);
            font-size : 1.3em;
            padding   : 5px;
        }

        #preinscrit_zone {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-wrap       : wrap;
            gap             : 10px;
            justify-content : center;

            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                justify-content : center;

                > span {

                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }
            }
        }
    }

    h1 {
        align-content   : center;
        align-items     : center;
        display         : flex;
        font-family     : var(--title-font-family), serif;
        gap             : 5px;
        justify-content : center;
        margin          : 5px 0 0;
    }

}

#liste_expeditions_inscription, #liste_ouvriers_inscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;
    width           : 100%;

    > fieldset {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        width            : 50%;

        legend {
            color     : var(--primary-font-color);
            font-size : 1.3em;
            padding   : 5px;
        }
    }


}

#zone_fao_span {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
    margin-left     : 5px;
}

#option_masquave_expe_complete {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

.ligneCarteAppercu {
    display : flex;
    margin  : 0;

    td.bordHautBasCarteAppercu_gdCarte {
        font-size   : 12px;
        height      : 15px;
        line-height : 15px;
        text-align  : center;
        width       : var(--tailleGd);
    }

    td.bordGaucheDroiteCarteAppercu_gdCarte {
        font-size   : 12px;
        height      : var(--tailleGd);
        line-height : var(--tailleGd);
        text-align  : center;
        width       : 15px;
    }

    td.coinCarteAppercu {
        height : 15px;
        width  : 15px;
    }
}

#modal_appercu {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    display          : flex;
    flex-direction   : column;
    justify-content  : center;

    > h2 {
        margin : 0;
    }

    > button {
        margin : 10px;
    }
}

.dispo_rapide_inscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}