#formRechercheVilles {
    margin           : auto;
    width            : 100%;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : 5px;
    color            : var(--primary-font-color);
}

#listVille, #listChargement {
    margin : auto;
    width  : 1200px;
}

#listChargement {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;

    p {
        margin     : 5px 0 0;
        text-align : center;
        width      : 100%;
    }
}

#listVilleOnglet {
    align-content   : center;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    flex-wrap       : wrap;
    justify-content : flex-start;
    margin-bottom   : 2px;
}

.listVilles {
    width : 100%;

    tr, td, th {
        border : 1px solid var(--primary-border-color);
    }

    thead {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }
}

.villeDate, .villeHero, .villesCit, .villesSaison, .villesLarg, .villesEtat, .villesType, .villesJour, .villesNum, .villesVisu, .villesPointSaison, .villesLang {
    text-align : center;
}

.villesVisu {
    cursor : pointer;
    width  : 4%;
}

.villesNum {
    width : 7%;
}

.villesType, .villesEtat, .villesSaison, .villesCit, .villeHero, .villesJour, .villesLang {
    width : 4%;
}

.villesPointSaison {
    width : 6%;
}

.villesLarg {
    width : 7%;
}

.villeDate {
    width : 12%;
}

.caseChoixVilles {
    align-content   : center;
    align-items     : center;
    border          : 1px solid var(--primary-border-color);
    cursor          : pointer;
    display         : flex;
    font-size       : 0.8em;
    font-weight     : bold;
    height          : 22px;
    justify-content : center;
    padding         : 3px;
    text-align      : center;
    width           : 70px;
}

.caseChoixVilles:hover {
    background-color : var(--tertiary-color);
    color            : var(--hover-font-color);
}

.villesSelected {
    background-color : var(--hover-font-color);
    color            : var(--tertiary-font-color);
}

.masquageVille {
    display : none;
}

#inputRecherche {
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    padding         : 5px;

    > fieldset {
        border : 1px solid var(--primary-border-color);
    }
}

#nomVille {
    width : 320px;
}

#checkBoxType > div, #checkBoxStatut > div {
    display : flex;
}

#boutonVilles {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
}

.villes_jours {
    margin-right : 4px;
}

.list-colonne-filtre-villes {
    align-items           : center;
    display               : grid;
    gap                   : 2px;
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content       : center;
    justify-items         : center;
    border                : 1px solid var(--primary-border-color);
}

.arbre_chantier_comparaison {
    .chantier_nom.root {
        padding-left : 0;
    }

    .chantier_nom.lv-1 {
        padding-left : 8px;
    }

    .chantier_nom.lv-2 {
        padding-left : 16px;
    }

    .chantier_nom.lv-3 {
        padding-left : 24px;
    }

    .chantier_nom.lv-4 {
        padding-left : 32px;
    }

    .chantier_nom.lv-5 {
        padding-left : 40px;
    }

    .chantier_nom.lv-6 {
        padding-left : 48px;
    }
}

#comparaisonVilles {
    align-items     : center;
    display         : flex;
    justify-content : center;
    padding         : 5px;
    flex-direction  : column;

    > fieldset {
        border : 1px solid var(--primary-border-color);
    }
}