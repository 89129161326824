
.card {
    --bs-card-bg           : var(--primary-color);
    --bs-card-border-color : var(--primary-border-color);
    --bs-card-color        : var(--primary-font-color);
}

.list-group {
    --bs-list-group-bg : var(--primary-color);
}

:root {
    --bs-body-color : var(--primary-border-color);
}

.nav, .navbar, .nav-link {
    --bs-link-hover-color : var(--hover-font-color);
    --bs-nav-link-color   : var(--primary-font-color);
    --bs-navbar-padding-y : 0;
}

.nav-tabs {
    --bs-nav-tabs-border-color      : var(--primary-border-color);
    --bs-nav-tabs-link-active-bg    : var(--primary-font-color);
    --bs-nav-tabs-link-active-color : var(--primary-color);
}

.table {
    --bs-table-bg         : var(--secondary-row-color);
    --bs-table-color      : var(--primary-font-color);
    --bs-table-striped-bg : var(--primary-row-color);
}

.modal {
    --bs-modal-bg                  : var(--secondary-row-color);
    --bs-modal-footer-border-color : var(--primary-border-color);
}

.pagination {
    --bs-pagination-active-bg             : var(--tertiary-color);
    --bs-pagination-active-border-color   : var(--primary-font-color);
    --bs-pagination-active-color          : var(--primary-font-color);
    --bs-pagination-bg                    : var(--primary-color);
    --bs-pagination-border-color          : var(--primary-font-color);
    --bs-pagination-color                 : var(--primary-font-color);
    --bs-pagination-disabled-bg           : var(--primary-color);
    --bs-pagination-disabled-border-color : var(--primary-font-color);
    --bs-pagination-focus-bg              : var(--primary-font-color);
    --bs-pagination-focus-color           : var(--tertiary-font-color);
    --bs-pagination-hover-bg              : var(--primary-font-color);
    --bs-pagination-hover-color           : var(--tertiary-font-color);
}

