
#infoCreation {
    display       : flex;
    margin-bottom : 4px;
    width         : 100%;
}

#zone_creation {

    margin : auto;
    width  : 975px;


    > ul > li {
        cursor           : pointer;
        float            : left;
        line-height      : 26px; /* on définit une hauteur pour chaque élément */
        list-style       : none; /* on supprime le style par défaut de la liste */
        margin           : 0; /* ni extérieure */
        padding          : 0; /* Pas de marge intérieure */
        text-align       : center; /* on centre le texte qui se trouve dans la liste */
        width            : 250px;

        > button {
            width : 250px;
        }

        &:hover {
            background-color : var(--special-color);
            color            : var(--hover-font-color);
        }
    }

    > div {
        border-bottom : 2px solid var(--primary-border-color);
        border-left   : 2px solid var(--primary-border-color);
        border-right  : 2px solid var(--primary-border-color);
    }
}

#zone_jump, #zone_event {
    background-color : var(--secondary-color);
    width            : 100%;
}

#formJump, #formEvent {
    padding : 5px;
}

#formEvent {
    align-items    : flex-start;
    display        : flex;
    flex-direction : column;
    gap            : 5px;
}

.boutonMenuCreation {
    background-color        : var(--secondary-color);
    border-bottom           : 2px solid var(--primary-border-color);
    border-left             : 2px solid var(--primary-border-color);
    border-right            : 2px solid var(--primary-border-color);
    border-top              : 2px solid var(--primary-border-color);
    border-top-left-radius  : 5px;
    border-top-right-radius : 5px;
    margin-bottom           : -2px;
    width                   : 100px;
}

#bordureCreaJump {
    width : 1120px;
}


.simpleForm {
    width : 100%;
}

.doubleForm .dateJumpCreation {
    width : 48%
}

.doubleForm .dateEventCreation {
    width : 48%
}

.doubleForm .checkEventCreation {
    width : 48%
}

.dateJumpCreation, .dateEventCreation {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 463px;

    > input {
        width : 180px;
    }
}

.checkEventCreation {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : flex-start;
    width           : 463px;

    > input {
        width : 180px;
    }
}


#creation_dateApproxJump {
    margin-left : 17px;
}

#nomJumpCreation, #nomEventCreation {
    display : flex;
    width   : 100%;

    > label {
        display : inline-block;
        width   : 150px;
    }

    > input {
        display : inline-block;
        width   : 484px;
    }
}

.label_banniere_creation {
    margin : 0;
}

#banniereJumpCreation, #banniereEventCreation {
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;
}

.banniere_jump_event {
    align-items     : center;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    justify-content : center;
    max-height      : 300px;
    max-width       : 750px;
    min-height      : 100px;
    min-width       : 500px;
}

#textRemplacant {
    text-align : center;
    width      : 100%;
}

.bouton_banniere {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
    margin-bottom   : 5px;
    padding-left    : 10px;
    width           : 190px;
}

#objectifJumpCreation {
    width : 100%;
}

#creation_objectif {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    gap             : 10px;
    justify-content : flex-start;
    width           : 100%;

    div > label {
        display : flex;
        gap     : 5px;
        margin  : 2px 10px 2px 0;
    }

    div {
        display : flex;
        gap     : 5px;
    }
}

#typeJumpCreation, #typeEventCreation {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 400px;

    > label {
        width : 120px;
    }
}

#creation_typeVille, #event_typeVille {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 285px;
}

#creation_communityVille_jump, #creation_communityVille_event {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : space-evenly;
    width           : 240px;

    div {
        align-items     : center;
        display         : flex;
        justify-content : center;

    }
}

#communityJumpCreation {
    display : flex;
}

#typeVilleJumpCreation, #typeVilleEventCreation {
    display         : flex;
    justify-content : center;
    width           : 400px;
}

#creation_save {
    font-size : 1rem;
    height    : 35px;
    width     : 150px;
}

#textAreaJumpCreation, #textAreaEventCreation {
    display   : flex;
    flex-wrap : wrap;
    width     : 100%;

    label {
        width : 100%;
    }
}

#form_event_button_add_jump {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    margin-top      : 5px;
}

#form_jumps {

    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 5px;
    justify-content : center;
    width           : 500px;

    > table {
        width : 100%;
    }
}

#form_orgas {
    margin-top : 5px;

    .ligneJump, .ligneGestionOrga {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-between;
        margin-top      : 5px;
        width           : 250px;

        div > div > div > select {
            width : 150px;
        }
    }
}

#event_one_metier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

#fieldset_event_list_jump {
    width : 340px;
}