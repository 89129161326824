#ency_bat {
    margin : auto;
    width  : 1200px;

    > ul > li {
        cursor      : pointer;
        float       : left;
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
        width       : 250px;

        > button {
            width : 250px;
        }

    }

    div.container-tabs {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        padding-top      : 15px;
        width            : 1198px;
    }

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 1100px;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_icone {
            width : 150px;
        }

        td.tab_ency_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_desc {
            width : 375px;
        }

        td.tab_ency_desc {
            padding    : 8px;
            text-align : justify;
        }

        .tab_ency_objet {
            padding : 8px;
            width   : 300px;
        }

        td.tab_ency_objet {
            > div {
                align-content         : center;
                align-items           : start;
                display               : grid;
                flex-wrap             : wrap;
                grid-template-columns : 1fr 1fr 1fr;
                justify-content       : center;
                justify-items         : center;
                gap                   : 3px;

                > span {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    justify-content : center;
                }
            }

        }


    }


}

#form_search_bat {

    background-color : var(--secondary-row-color);
    margin           : auto auto 10px;
    width            : 900px;

    h3 {
        margin     : 0;
        text-align : center;
        width      : 100%;
    }

    #form {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-evenly;
        width           : 100%;

        > div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 5px;
            justify-content : center;
            margin          : 5px;
            width           : 350px;

            #form_km {
                width : 50px;
            }
        }
    }
}

#form_camp_batiment_anonyme {
    align-content         : center;
    display               : grid;
    grid-template-columns : repeat(5, 1fr);
    justify-items         : start;
    justify-content       : center;
    align-items           : center;
    padding: 5px;
    gap: 3px;

    input[type="number"] {
        width : 50px;
    }

    #camping_km_select {
        grid-column-end   : 1;
        grid-column-start : 1;
    }

    #camping_batiment_select {
        grid-column-end   : 4;
        grid-column-start : 2;
    }

    #camping_amelio_od_select {
        grid-column-end   : 4;
        grid-column-start : 1;
    }

    #camping_phare_select {
        grid-column-end   : 2;
        grid-column-start : 1;
    }

    #camping_hard_select {
        grid-column-end   : 4;
        grid-column-start : 3;
    }

    #camping_nuit_select {
        grid-column-end   : 3;
        grid-column-start : 2;
    }

    #camping_devast_select {
        grid-column-end   : 6;
        grid-column-start : 5;
    }

    #camp_max_input {
        grid-column-end   : 6;
        grid-column-start : 4;

        > input {
            width : 50px;
        }
    }

    #camping_zombie_select {
        grid-column-end   : 5;
        grid-column-start : 4;
    }

    #camping_zombie_select input {
        width : 40px
    }
}

#camping_campeur_anonyme {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 5px;
    justify-content : center;
    margin-bottom   : 10px;
    margin-top      : 10px;
    width           : 100%;

    table {
        font-size : 0.9rem;
        margin    : auto auto 2px;
        width     : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        .tbl_camping_ordre {
            width : 30px;
        }

        .tbl_camping_campeur {
            width : 250px;
        }

        .tbl_camping_nbr {
            width : 80px;
        }

        .tbl_camping_tombe {
            width : 80px;
        }

        .tbl_camping_cp {
            width : 80px;
        }

        .tbl_camping_odc {
            width : 80px;
        }

        .tbl_camping_chance {
            width : 80px;
        }

        .tbl_camping_action {
            width : 100px;
        }

    }
}

#zone_information_batiment {
    border    : 2px solid var(--primary-border-color);
    font-size : 13px;
    padding   : 10px;

    hr {
        border : 1px solid var(--primary-border-color);
        color  : var(--primary-border-color);
        margin : 5px auto;
        width  : 80%;
    }

    .info_titre_bat h2 {
        display         : flex;
        gap             : 5px;
        justify-content : center;
        margin          : 0 0 5px;
    }

    .info_contenu_bat {
        align-content   : center;
        align-items     : flex-start;
        display         : flex;
        gap             : 10px;
        justify-content : center;


    }

    .info_image_bat {
        background-color : rgb(121, 78, 34);
        height           : 62px;
        margin           : 2px;
    }

    .info_texte_bat {
        h3, p {
            margin : 0;
        }

        h3 {
            margin-bottom : 5px;
        }
    }

    .info_items_bat {
        align-content         : center;
        align-items           : center;
        display               : grid;
        grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
        justify-content       : center;
        justify-items         : center;

        > span {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : center;
        }
    }

    .info_button_bat {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;
        margin-top      : 5px;
    }
}