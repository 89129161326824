#ency_citoyens {
    font-size : 14px;
    margin    : auto;
    width     : 100%;

    > ul > li {
        cursor           : pointer;
        float            : left;
        line-height      : 26px; /* on définit une hauteur pour chaque élément */
        list-style       : none; /* on supprime le style par défaut de la liste */
        margin           : 0; /* ni extérieure */
        padding          : 0; /* Pas de marge intérieure */
        text-align       : center; /* on centre le texte qui se trouve dans la liste */
        width            : 140px;
    }

}

#zone_tabs_ency_citoyens {


    h2 {
        margin          : 10px 2px 2px;
        text-align      : left;
        text-decoration : underline;
        width           : 100%;
    }

    p {
        font-size : 0.8rem;
        margin    : 0 0 0 10px;
    }

    > div {
        border-bottom : 2px solid var(--primary-border-color);
        border-left   : 2px solid var(--primary-border-color);
        border-right  : 2px solid var(--primary-border-color);
        margin-top    : 28px;
    }
}

#zone_pda, #zone_pc, #zone_pp, #zone_kz, #zone_expHeros, #zone_skillHeros {
    background-color : var(--secondary-color);
    border-bottom    : 2px solid var(--primary-border-color);
    border-left      : 2px solid var(--primary-border-color);
    border-right     : 2px solid var(--primary-border-color);
    margin           : auto;
    width            : 1216px;
}

#zonePda, #zonePc, #zonePp, #zoneKillDespoir, #zoneExpHeros, #zoneSkillHeros {
    display        : flex;
    flex-direction : column;
    gap            : 10px;
    margin         : auto;
    padding-bottom : 10px;
    padding-top    : 10px;

    > h2 {
        margin          : 0 2px 2px;
        text-align      : left;
        text-decoration : underline;
        width           : 100%;
    }

    > p {
        font-size : 0.8rem;
        margin    : 0 0 0 7px;
    }


}

.boutonMenuEncyCitoyens {
    background-color        : var(--secondary-color);
    border-bottom           : 2px solid var(--primary-border-color);
    border-left             : 2px solid var(--primary-border-color);
    border-right            : 2px solid var(--primary-border-color);
    border-top              : 2px solid var(--primary-border-color);
    border-top-left-radius  : 5px;
    border-top-right-radius : 5px;
    margin-bottom           : -2px;
    width                   : 170px;

}


#bordureEncyCitoyens, #bordureEncyCitoyensRepar1, #bordureEncyCitoyensRepar2 {
    border-top : 2px solid var(--primary-border-color);
    display    : inline-block;
}

#bordureEncyCitoyens {
    width : 975px;
}


.enteteTab_ency {
    background-color : var(--tertiary-color);
}

.point_d_ame {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;

    .pda_jour_point {
        align-content    : center;
        align-items      : center;
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        display          : flex;
        flex-direction   : column;
        flex-wrap        : nowrap;
        justify-content  : center;
        margin-bottom    : 5px;
        width            : 48px;

        .pda_jour {
            background-color : var(--tertiary-color);
            border-bottom    : 1px solid var(--primary-border-color);
            height           : 20px;
            line-height      : 20px;
            text-align       : center;
            width            : 100%;
        }

        .pda_point {
            height      : 20px;
            line-height : 20px;
            text-align  : center;
            width       : 100%;
        }
    }
}

.point_clean {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;

    .pc_jour_point {
        align-content    : center;
        align-items      : center;
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        display          : flex;
        flex-direction   : column;
        flex-wrap        : nowrap;
        justify-content  : center;
        margin-bottom    : 5px;
        width            : 48px;

        .pc_jour {
            background-color : var(--tertiary-color);
            border-bottom    : 1px solid var(--primary-border-color);
            height           : 20px;
            line-height      : 20px;
            text-align       : center;
            width            : 100%;
        }

        .pc_point {
            height      : 20px;
            line-height : 20px;
            text-align  : center;
            width       : 100%;
        }
    }
}

.point_pande {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;

    .pp_jour_point {
        align-content    : center;
        align-items      : center;
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        display          : flex;
        flex-direction   : column;
        flex-wrap        : nowrap;
        justify-content  : center;
        margin-bottom    : 5px;
        width            : 48px;

        .pp_jour {
            background-color : var(--tertiary-color);
            border-bottom    : 1px solid var(--primary-border-color);
            height           : 20px;
            line-height      : 20px;
            text-align       : center;
            width            : 100%;
        }

        .pp_point {
            height      : 20px;
            line-height : 20px;
            text-align  : center;
            width       : 100%;
        }
    }
}

.kill_despoir {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;


    .kd_zz_kill {
        align-content    : center;
        align-items      : center;
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        display          : flex;
        flex-direction   : column;
        flex-wrap        : nowrap;
        justify-content  : center;
        margin-bottom    : 5px;
        width            : 48px;

        .kd_zombie {
            background-color : var(--tertiary-color);
        }

        .kd_zombie, .kd_kill, .kd_killCas, .kd_MortJ1 {
            border-bottom : 1px solid var(--primary-border-color);
            height        : 20px;
            line-height   : 20px;
            text-align    : center;
            width         : 100%;
        }

        .kd_Mort, .kd_MortJ2 {
            height      : 20px;
            line-height : 20px;
            text-align  : center;
            width       : 100%;
        }
    }
}

#ency_experience_heros {
    margin : auto auto 10px;
    width  : 100%;

    tr, td, th {
        border     : 1px solid var(--primary-border-color);
        text-align : center;
    }

    thead {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--primary-row-color);
    }

    .tab_ency_heros_img {
        font-size : 0.8rem;
        width     : 65px;
    }


    td.tab_ency_heros_img {
        > div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-direction  : column;
            justify-content : center;
        }

    }

    .tab_ency_heros_nom {
        width : 200px;
    }

    td.tab_ency_heros_nom {
        padding : 4px;
    }

    .tab_ency_heros_desc {
        width : 720px;
    }

    td.tab_ency_heros_desc {
        padding    : 4px;
        text-align : left;
    }

    .tab_ency_heros_jour {
        width : 90px;
    }

    .tab_ency_heros_jourCumul {
        width : 90px;
    }

}

.ency_skill_basique {
    font-size : 13px;
    width     : 20%;

    tr, td, th {
        border     : 1px solid var(--primary-border-color);
        text-align : center;
    }

    thead {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--primary-row-color);
    }

}

.ency_skill_heros {

    font-size : 13px;

    tr, td, th {
        border     : 1px solid var(--primary-border-color);
        text-align : center;
    }

    thead {
        background-color : var(--tertiary-color);
        color            : var(--primary-font-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--primary-row-color);
    }

    .taille_ency_skill_heros_comp {
        width : 100px;
    }

    .taille_ency_skill_heros {
        width : 280px;
    }

    .accentuated-top-row {
        border-top : 3px solid var(--primary-border-color); /* Exemple d'accentuation des bordures */
    }

    .accentuated-bottom-row {
        border-bottom : 3px solid var(--primary-border-color);
    }

}