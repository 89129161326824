@font-face {
    font-family : edo_szregular;
    src         : url('./polices/edosz-webfont.eot');
    src         : url('./polices/edosz-webfont.eot?#iefix') format('embedded-opentype'),
    url('./polices/edosz-webfont.woff2') format('woff2'),
    url('./polices/edosz-webfont.woff') format('woff'),
    url('./polices/edosz-webfont.ttf') format('truetype'),
    url('./polices/edosz-webfont.svg#edo_szregular') format('svg');
    font-weight : normal;
    font-style  : normal;
}


/*html, body, div#app {
	bottom    : 0;
	left      : 0;
	margin    : auto;
	min-width : 1300px;
	position  : absolute;
	right     : 0;
	top       : 0;
	width     : auto;
}*/

div#app {
    background-color : var(--background-color);
    min-height       : 100vh;
}

body {
    background       : var(--html-bg-img);
    background-color : var(--primary-color);
    background-size  : 100%;
    color            : var(--primary-font-color);
    font-family      : var(--primary-font-family), serif;
    font-size        : 1em;
    height           : auto;
    margin           : auto;
    min-height       : 100vh;

    header#general {
        align-items      : center;
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        flex-direction   : row;
        height           : 54px;
        justify-content  : space-evenly;
        margin           : auto auto 1em;
        position         : fixed;
        width            : 100%;
        z-index          : 2000;
    }
}

#entete {
    align-items     : center;
    background      : var(--primary-bg-img);
    display         : flex;
    flex-wrap       : nowrap;
    justify-content : space-between;
    width           : 100%;

    #titre_principale {

        display         : flex;
        flex-direction  : column;
        min-width       : 900px;
        position        : relative;
        text-decoration : none;
        width           : 73%;

        #groupMenuMaj {
            align-items     : center;
            display         : flex;
            justify-content : flex-start;
            margin-right    : 0;
            width           : 250px;
        }

        #entete_link {
            margin-right : 0;
            min-width    : 630px;
            position     : relative;
            top          : 8px;
            width        : 75%;
        }

        #groupImgText {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : center;
        }

        #groupImage {

            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-between;


            #imageBanniere {
                background      : var(--entete-bg-img);
                background-size : 100%;
                height          : 40px;
                margin-right    : 8px;
                width           : 40px;
            }

            #textCentrale {

                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;

                h1 {
                    align-content : center;
                    color         : var(--tertiary-font-color);
                    font-family   : var(--title-font-family), serif;
                    font-size     : 4em;
                    margin        : 0;
                    position      : relative;

                }

                #entente_devise {
                    color       : var(--tertiary-font-color);
                    font-family : var(--title-font-family), serif;
                    font-size   : 1.3em;
                    margin      : 0;
                    position    : relative;
                }

                #entete_nomV {
                    color       : var(--primary-font-color);
                    font-family : var(--primary-font-family), serif;
                    font-size   : 0.7em;
                    font-weight : bold;
                    margin      : 0 0 4px;
                    position    : relative;
                }
            }
        }

        #textDerMaj {
            margin-bottom : 2px;

            #entete_majV {
                color       : var(--primary-font-color);
                font-family : var(--primary-font-family), serif;
                font-size   : 0.6em;
                margin      : 0 0 0 5px;
                position    : relative;
            }
        }


    }

    #Espace_perso {
        align-items : center;
        display     : flex;
        height      : 54px;
        position    : relative;
        width       : 400px;

        #avatar_pseudo {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-direction  : row;
            height          : 54px;
            justify-content : space-around;
            width           : 180px;
        }


    }
}

.avatar_banniere {
    max-height : 45px;
    max-width  : 45px;
}

#perso_admin {
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    justify-content : center;
    width           : 260px;

    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        height          : 50px;
        justify-content : center;
        margin          : 3px;
        width           : 50px;

        a {
            width : 100%;
        }
    }


    /* sélecteur de langue */

    > .select_wrap {
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        height          : 27px;
        justify-content : flex-start;
        width           : 43px;

        div {
            cursor      : pointer;
            display     : block;
            line-height : 11px;
            width       : 40px;

            div > img {
                display : inline-block;
                height  : 27px;
                width   : 40px;
            }
        }

        ul {
            align-content   : center;
            align-items     : center;
            background      : var(--hover-font-color);
            border-top      : 0;
            display         : flex;
            flex-direction  : column;
            gap             : 3px;
            justify-content : center;
            list-style-type : none;
            margin          : 0;
            padding         : 0;
            position        : relative;
            top             : 2px;
            width           : 40px;
            z-index         : 9999;

            li {
                cursor  : pointer;
                display : block;
                height  : 27px;
                width   : 40px;

                div {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    height          : 27px;
                    justify-content : center;
                    width           : 40px;

                    img {
                        display        : inline-block;
                        height         : 27px;
                        object-fit     : fill;
                        vertical-align : middle;
                        width          : 40px;
                    }
                }


            }

            li:hover {
                color : blue;
            }
        }
    }

    > .deconnexion {
        width : 100px;
    }
}


.imgPanel {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}


#avatar {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;

    img {
        border : 1px solid var(--primary-border-color);
    }
}

#pseuCo p {
    font-size  : 0.8rem;
    margin     : 0 0 0 0;
    text-align : center;
}

/* Navigation */

#menuSite {
    font-family : var(--primary-font-family), serif;
    font-size   : 1em;
    font-weight : bold;
    margin      : 0;
    padding     : 0;

    .accordion-item {
        border-radius : 0;

        &:hover {
            background-color : var(--primary-font-color);
            color            : var(--secondary-color);
        }

        h2 {
            background         : var(--menu-button-bg-img);
            background-color   : var(--secondary-color);
            background-size    : cover;
            -moz-border-radius : 0;
            border-radius      : 0;
            color              : var(--primary-font-color);
            cursor             : pointer;
            font-size          : 1.0em;
            line-height        : 22px;
            margin             : 0;
            padding            : 0;
            text-align         : center;
            text-decoration    : none;
        }
    }

    .accordion-button {
        background         : none;
        background-color   : var(--secondary-color);
        background-size    : cover;
        -moz-border-radius : 0;
        border-radius      : 0;
        color              : var(--primary-font-color);
        cursor             : pointer;
        font-size          : 1em;
        line-height        : 22px;
        padding            : 0;
        text-align         : center;
        text-decoration    : none;
        width              : 100%;

        .list-group {
            width : 100%;
        }

        &:focus {
            box-shadow : none;
        }
    }

    .accordion-body {
        padding : 0;
    }


    .custom-menu-button, .custom-menu-button-alone {
        align-items        : center;
        background         : none;
        background-color   : var(--secondary-color);
        background-size    : cover;
        -moz-border-radius : 0;
        border-radius      : 0;
        color              : var(--primary-font-color);
        cursor             : pointer;
        display            : flex;
        font-size          : 1em;
        height             : 40px;
        justify-content    : center;
        line-height        : 22px;
        padding            : 0;
        text-align         : center;
        text-decoration    : none;

        &:hover {
            background-color : var(--primary-font-color);
            color            : var(--secondary-color);
        }
    }

    .custom-menu-button-event {
        align-items        : center;
        background         : none;
        background-color   : var(--specifique-color);
        background-size    : cover;
        -moz-border-radius : 0;
        border-radius      : 0;
        color              : var(--primary-font-color);
        cursor             : pointer;
        display            : flex;
        font-size          : 1em;
        height             : 40px;
        justify-content    : center;
        line-height        : 22px;
        padding            : 0;
        text-align         : center;
        text-decoration    : none;

        &:hover {
            background-color : var(--primary-font-color);
            color            : var(--specifique-color);
        }
    }


    .list-group-item-action {
        width : 99%;

        &:active, .active {
            border-color : var(--primary-font-color);
        }
    }

}

#menuSite-hor {
    font-family : var(--primary-font-family), serif;
    font-size   : 1em;
    font-weight : bold;
    margin      : 0;
    padding     : 0;

    .nav-link, .nav-item, .dropdown-item {
        border  : 1px solid var(--primary-border-color) !important;
        height  : 40px !important;
        padding : 0 !important;
    }

    .nav-item, .dropdown-item {
        min-width : 70px !important;
    }

    .nav-link {
        align-content    : center;
        align-items      : center;
        background-color : var(--secondary-color);
        color            : var(--primary-font-color);
        display          : flex;
        justify-content  : center;
        min-width        : 70px !important;

        &:hover {
            background-color : var(--primary-font-color);
            color            : var(--secondary-color);
        }
    }

    .nav-link.show {
        background-color : var(--primary-font-color);
        color            : var(--secondary-color);
    }


    .dropdown-menu {
        border-radius : 0;
        padding       : 0 !important;
    }

    .custom-menu-button, .dropdown {
        align-items        : center;
        background         : none;
        background-color   : var(--secondary-color);
        background-size    : cover;
        -moz-border-radius : 0;
        border-radius      : 0;
        color              : var(--primary-font-color);
        cursor             : pointer;
        display            : flex;
        font-size          : 1em;
        height             : 40px;
        justify-content    : center;
        line-height        : 22px;
        padding            : 0;
        text-align         : center;
        text-decoration    : none;

        &:hover {
            background-color : var(--primary-font-color);
            color            : var(--secondary-color);
        }
    }


    .custom-menu-button {
        width : 100%;
    }

    .custom-menu-button-event {
        align-items        : center;
        background         : none;
        background-color   : var(--specifique-color);
        background-size    : cover;
        -moz-border-radius : 0;
        border-radius      : 0;
        color              : var(--primary-font-color);
        cursor             : pointer;
        display            : flex;
        font-size          : 1em;
        height             : 40px;
        justify-content    : center;
        line-height        : 22px;
        padding            : 0;
        text-align         : center;
        text-decoration    : none;
        width              : 100%;

        &:hover {
            background-color : var(--primary-font-color);
            color            : var(--specifique-color);
        }
    }


    .list-group-item-action {
        width : 99%;

        &:active, .active {
            border-color : var(--primary-font-color);
        }
    }

}

.list-group-item {
    &:focus {
        box-shadow : none;
    }
}

.menu-open#menuSite {
    background-color : var(--secondary-color);
    bottom           : 0;
    box-shadow       : 0 0 10px rgba(0, 0, 0, 0.6);
    left             : 0;
    position         : absolute;
    top              : 56px;
    transition       : left 0.3s ease;
    width            : 170px;
    z-index          : 9999;
}

.burger-icon {
    cursor          : pointer;
    display         : flex;
    flex-direction  : column;
    height          : 24px;
    justify-content : space-between;
    position        : absolute;
    width           : 24px;
}

.burger-icon span {
    background-color : var(--primary-font-color);
    display          : block;
    height           : 2px;
    width            : 100%;
}

.burger-icon.open span:nth-child(1) {
    transform : translateY(8px) rotate(45deg);
}

.burger-icon.open span:nth-child(2) {
    opacity : 0;
}

.burger-icon.open span:nth-child(3) {
    transform : translateY(-8px) rotate(-45deg);
}

.boutonJ {
    background         : var(--menu-button-bg-img);
    background-size    : cover;
    border             : 2px solid var(--primary_border-color);
    -moz-border-radius : 0;
    border-radius      : 0;
    color              : var(--primary-font-color);
    cursor             : pointer;
    font-size          : 1.0em;
    line-height        : 22px;
    text-align         : center;
    text-decoration    : none;
}

.boutonDeco {
    background-color   : var(--primary-color);
    background-size    : cover;
    border             : 1px solid var(--primary_border-color);
    -moz-border-radius : 0;
    border-radius      : 0;
    color              : var(--error-color);
    cursor             : pointer;
    font-size          : 1.0em;
    line-height        : 22px;
    text-align         : center;
    text-decoration    : none;
}

.boutonJBug {
    background         : var(--menu-button-bg-img);
    background-size    : cover;
    border             : 2px solid var(--primary_border-color);
    -moz-border-radius : 0;
    border-radius      : 0;
    color              : var(--error-color);
    cursor             : pointer;
    font-size          : 1.0em;
    padding            : 5px 10px 5px 10px;
    text-align         : center;
    text-decoration    : none;
}

.sourisPointer {
    cursor : pointer;
}

a.lineNone:link, a.lineNone:visited {
    color           : var(--primary-font-color);
    text-decoration : none;
}


/* Corps */

#bloc_page {
    background       : var(--primary-bg-img);
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    margin           : auto;
    position         : relative;
    top              : var(--top-decalage);
    width            : var(--desktop-width);
}

#corps {
    font-size : 1em;
    margin    : 0 40px;
}


#entetecorps {
    align-content   : center;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    margin-bottom   : 10px;
    width           : var(--desktop-width);

    img {
        background-size : cover;
        height          : 100%;
    }

    h1 {
        color       : var(--primary-font-color);
        font-family : var(--title-font-family), serif;
        font-size   : 3em;
        margin      : auto;
        text-align  : center;
        width       : 90%;
    }
}

#piedcorps {

    display         : flex;
    flex-direction  : row;
    height          : 10px;
    justify-content : space-between;

    img {
        height : 100%;
    }
}

/* Footer */

footer {
    background       : var(--primary-bg-img);
    background-color : var(--secondary-color);
    background-size  : cover;
    border           : 1px solid var(--primary-border-color);
    color            : var(--primary-font-color);
    font-size        : 12px;
    margin           : 1em auto 100px;
    padding          : 10px;
    position         : relative;
    text-align       : center;
    top              : var(--top-decalage);
    width            : 1280px;

    p {
        margin : 0;

        a {
            color           : var(--primary-font-color);
            font-weight     : bold;
            text-decoration : underline;
            text-underline  : var(--primary-font-color);
        }
    }
}

#texfoot {
    margin    : auto;
    max-width : 700px;
    width     : 80%;
}


footer, #texfoot {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
}

#suggestion_discord {
    margin-top : 5px;
}

#remerciement_maj {
    margin-top : 5px;
}

#listPseudoRemerciement {
    font-weight : bold;
}

table {
    border-collapse : collapse;
}

div#app {
    display        : flex;
    flex-direction : column;
}

/*div#content {
	flex-grow   : 1;
	overflow-y  : auto;
	padding-top : 15px
}*/


/* Pop Up */

.BlocNoir {
    background : #000;
    display    : none; /*--masqué par défaut--*/
    height     : 100%;
    left       : 0;
    opacity    : 0.5;
    position   : fixed;
    top        : 0;
    width      : 100%;
    z-index    : 1100;
}

.PanneauElements {
    background : var(--primary-color);
    border     : 1px solid var(--primary-font-color);
    color      : var(--primary-font-color);
    display    : none;
    position   : absolute;
    z-index    : 1200;
}

.titrePopUp {
    border     : 1px solid var(--primary-font-color);
    margin     : 5px;
    text-align : center;
}

.popTitreAvert {
    background-color : rgba(239, 153, 51, 0.4);
    font-size        : 1.5em;
}

.popTitreSuccess {
    background-color : rgba(142, 255, 128, 0.4);
    font-size        : 1.5em;
}

.popTitreImportant {
    color     : var(--error-color);
    font-size : 1.5em;
}

.zoneTextPopUp p {
    background-color : rgba(255, 255, 255, 0.4);
    margin           : 0 10px 10px;
}

.boutPopUp {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100%;
}

.boutPopUp button {
    display : block;
    width   : 80px;
}

/*#titrePopUpNews {
	display        : flex;
	flex-direction : row;
	font-size      : 1.2em;
	margin-bottom  : 0;
}

#titrePopUpNewsSite {
	width     : 92%;
	font-size : 1em;
}

#popUpNewsSiteBout {
	width  : 25px;
	height : 25px;
	margin : 5px;
}

#popUpNewsSite {
	display    : none;
	min-height : 100px;
	max-height : 350px;
	width      : 40%;
}

#textPopUpNewsSite {
	background-color : rgba(255, 255, 255, 0.3);
	margin           : 0 5px 5px;
	overflow-x       : auto;
	max-height       : 300px;
}
*/

/* Generalité */

.fondWhite01 {
    background-color : var(--secondary-row-color);
}

.fondBlack01 {
    background-color : var(--primary-row-color);
}

.fondWhite02 {
    background-color : var(--secondary-row-color);
}

.fondBlack02 {
    background-color : var(--primary-row-color);
}

.fondWhite03 {
    background-color : var(--secondary-row-color);
}

.fondBlack03 {
    background-color : var(--primary-row-color);
}

.pasVille {
    margin     : auto;
    text-align : center;
    width      : 100%;
}

.banCit {
    color       : var(--error-color);
    font-weight : bold;
}

.textAreaAlign {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

.textAreaAlign > * {
    margin : 3px;
}

/* Color pour fa */
.color-red {
    color : var(--error-color);
}

.color-green {
    color : var(--success-color);
}

/* Form style */
.erreurForm {
    border     : 1px solid var(--primary-font-color);
    color      : var(--error-color);
    margin     : auto;
    text-align : center;
    width      : 80%;
}

.successForm {
    border     : 1px solid var(--primary-font-color);
    color      : var(--success-color);
    margin     : auto;
    text-align : center;
    width      : 80%;
}


/* classe général couleur */
.advertissement {
    color      : var(--alert-color);
    text-align : center;
    width      : 100%;
}

/* classe général couleur */
.warning {
    color       : var(--error-color);
    font-weight : bold;
    text-align  : center;
    width       : 100%;
}

/* Masquage element */
.elementNonVisible {
    display : none;
}

.elementVisible {
    display : block;
}

.zoneText {
    background-color : white;
    border           : 1px solid var(--primary-font-color);
    font-size        : 0.8em;
    margin           : 5px auto;
    min-height       : 100px;
    width            : 40%;
}

.boutonCopy {
    display : block;
    margin  : auto;
    width   : 120px;
}

/* infoBulle */

.infoBulle {
    position : relative;
}

.infoBulleHelpCursor {
    cursor : help;
}

.infoBulle span[class*=info] {
    background-color   : var(--secondary-color);
    background-image   : var(--button-bg-img);
    border             : 1px solid var(--primary-font-color);
    -moz-border-radius : 0;
    border-radius      : var(--radius-value);
    color              : var(--primary-font-color);
    display            : none;
    font-size          : 0.9rem;
    left               : 50%;
    overflow           : hidden;
    position           : absolute;
    top                : 100%;
    transform          : translateX(-50%) translateY(10px);
    z-index            : 999;
}


.infoBulle span[class*=info] img {
    display : inline-flex;
    margin  : 0 8px 8px 0;
}

.infoBulle:hover span[class*=info] {
    box-shadow : 0px 4px 8px rgba(0, 0, 0, 0.3);
    display    : block;
    transition : opacity 0.2s, visibility 0.2s;
}

.infoBulle:hover span[class~=info] {
    max-width : 700px;
    min-width : 150px;
    padding   : 5px;
    width     : auto;
}

.infoBulle:hover span[class~=infoChantier] {
    max-width : 700px;
    min-width : 300px;
    padding   : 5px;
    width     : auto;
}

.infoBulle:hover span[class~=infoExpedition] {
    margin-left : -150px;
    margin-top  : 20px;
    padding     : 5px;
    width       : 150px;
}

.infoBulle:hover span[class~=infoRuineGame] {
    margin-left : 5px;
    margin-top  : 20px;
    padding     : 5px;
    width       : 550px;
}

.infoBulle:hover span[class~=descriptionJump] {
    max-width : 700px;
    min-width : 500px;
    padding   : 5px;
    width     : auto;
}

.infoBulle:hover span[class~=infoHelpPerso] {
    max-width : 700px;
    min-width : 330px;
    padding   : 5px;
    width     : auto;
}

.infoBulle:hover span[class~=infoBis] {
    min-width : 10px;
    padding   : 0;
    z-index   : 999;
}

.infoBulle:hover span[class~=infoImg] {
    height  : 100px;
    margin  : auto;
    padding : 2px;
    width   : 100px;
    z-index : 999;
}

.infoBulle:hover span[class~=infoBanque] {
    cursor  : default;
    left    : 15px;
    margin  : auto;
    padding : 2px;
    top     : 15px;
    width   : 300px;
    z-index : 999;

    .listBatItem {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;

        .itemBat {
            width : 100%;
        }
    }
}


/* div séparateur pour mettre des bordures */
.separateurVertical {
    background-color : var(--primary-font-color);
    width            : 1px;
}

.seperateurHorizontal {
    background-color : var(--primary-font-color);
    height           : 1px;
    margin           : 0;
    width            : 100%;
}

.hrBlack40 {
    background-color : var(--primary-font-color);
    border-color     : var(--primary-font-color);
    width            : 40%;
}


/* Modification pour formulaire de type range

input[type="range"] {
	margin-left : 1em;
	position    : relative;
}

input[type="range"]:after,
input[type="range"]:before {
	color    : #aaa;
	position : absolute;
	top      : 1em;
}

input[type="range"]:before {
	content : attr(min);
	left    : 0;
}

input[type="range"]:after {
	content : attr(max);
	right   : 0;
}*/

/* Modification pour formulaire avec une liste déroulante cochable*/

.listFiltre1 {
    font-family : var(--primary-font-family), serif;
    font-size   : 1em;

    li {
        background-image : var(--button-bg-img);
        list-style-type  : none;
        max-width        : 200px;
        min-width        : 140px;
        position         : relative;
    }

    ul {
        line-height : 20px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
    }

    a {
        display         : block; /* On change le type d'élément, les liens deviennent des balises de type block */
        padding         : 0; /* Aucune marge intérieure */
        text-decoration : none; /* on supprime le style par défaut des liens  */
        width           : 60px; /* Largeur */
    }

    form {
        display         : block; /* On change le type d'élément, les liens deviennent des balises de type block */
        max-width       : 200px;
        min-width       : 140px; /* Largeur */
        padding         : 0; /* Aucune marge intérieure */
        text-decoration : none; /* on supprime le style par défaut des liens  */
    }

    li {
        display : flex;
    }

    li ul {
        left      : -999em;
        max-width : 200px;
        min-width : 140px;
        position  : absolute;
        z-index   : 9999;
    }

    ul li ul {
        margin : 0 0 0 0;
    }

    a:hover {
        background : #fff;
        color      : #000;
    }

    form:hover {
        background : #fff;
        color      : #000;
    }

    li:hover ul ul, li.sfhover ul ul {
        left : -999em;
    }

    li:hover ul, .listFiltre1 li li:hover ul, .listFiltre1 li.sfhover ul, .listFiltre1 li li.sfhover ul {
        left       : auto;
        min-height : 0;
    }
}

/* Querybuilder */

.query-builder {
    box-sizing  : border-box;
    font-family : sans-serif;
    margin      : 0;
    padding     : 0;

    * {
        box-sizing : border-box;
        margin     : 0;
        padding    : 0;
    }

    .hide {
        display : none;
    }

    .pull-right {
        float : right !important;
    }

    .btn {
        background-image : var(--button-bg-img);
        border           : 1px solid transparent;
        border-radius    : 4px;
        cursor           : pointer;
        display          : inline-block;
        font-size        : 14px;
        font-weight      : 400;
        line-height      : 1.42857;
        margin-bottom    : 0;
        padding          : 6px 12px;
        text-align       : center;
        text-transform   : none;
        touch-action     : manipulation;
        user-select      : none;
        vertical-align   : middle;
        white-space      : nowrap;
    }

    .btn.focus, .btn:focus, .btn:hover {
        color           : #333;
        text-decoration : none;
    }

    .btn.active, .btn:active {
        background-image : var(--button-bg-img);
        box-shadow       : 0 3px 5px rgba(0, 0, 0, 0.125) inset;
        outline          : 0 none;
    }

    .btn-success {
        background-color : #5CB85C;
        border-color     : #4CAE4C;
        color            : #FFF;
    }

    .btn-success.active, .btn-success.focus,
    .btn-success:active, .btn-success:focus,
    .btn-success:hover {
        background-color : #449D44;
        border-color     : #398439;
        color            : #FFF;
    }

    .btn-primary {
        background-color : #337AB7;
        border-color     : #2E6DA4;
        color            : #FFF;
    }

    .btn-primary.active, .btn-primary.focus,
    .btn-primary:active, .btn-primary:focus,
    .btn-primary:hover {
        background-color : #286090;
        border-color     : #204D74;
        color            : #FFF;
    }

    .btn-danger {
        background-color : #D9534F;
        border-color     : #D43F3A;
        color            : #FFF;
    }

    .btn-danger.active, .btn-danger.focus,
    .btn-danger:active, .btn-danger:focus,
    .btn-danger:hover {
        background-color : #C9302C;
        border-color     : #AC2925;
        color            : #FFF;
    }

    .btn-group {
        display        : inline-block;
        position       : relative;
        vertical-align : middle;

        > .btn {
            float    : left;
            position : relative;

            > .btn:first-child {
                margin-left : 0;
            }
        }

        > .btn:first-child:not(:last-child) {
            border-bottom-right-radius : 0;
            border-top-right-radius    : 0;
        }

        > .btn:last-child:not(:first-child) {
            border-bottom-left-radius : 0;
            border-top-left-radius    : 0;
        }

        .btn + .btn, .btn + .btn-g.btn-group, + .btn, .btn-group + .btn-group {
            margin-left : -1px;
        }

    }

    .btn-xs, .btn-group-xs > .btn {
        border-radius : 3px;
        font-size     : 12px;
        line-height   : 1.5;
        padding       : 1px 5px;
    }

    label {
        color : black;
    }
}

/* Form général */
.simpleForm, .doubleForm {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 100%;
}

.simpleFormButton {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;
}

.doubleFormButton {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100%;
}

.alert {
    margin-bottom : 5px;
}

.ck-editor__editable {
    color : black;
}

.dialog-ovelay {
    background-color : rgba(0, 0, 0, 0.50);
    height           : 100%;
    left             : 0;
    position         : fixed;
    top              : 0;
    width            : 100%;
    z-index          : 999999
}

.dialog-ovelay .dialog {
    background-color : #fff;
    border-radius    : 3px;
    box-shadow       : 0 0 20px rgba(0, 0, 0, .2);
    margin           : 100px auto 0;
    overflow         : hidden;
    width            : 400px;
}

.dialog-ovelay .dialog header {
    background-color : #f6f7f9;
    border-bottom    : 1px solid #e5e5e5;
    padding          : 10px 8px;
}

.dialog-ovelay .dialog header h3 {
    color     : #555;
    display   : inline-block;
    font-size : 14px;
    margin    : 0;
}

.dialog-ovelay .dialog header .fa-close {
    border-radius : 1px;
    color         : #c4c5c7;
    cursor        : pointer;
    float         : right;
    padding       : 0 2px;
    transition    : all .5s ease;
}

.dialog-ovelay .dialog header .fa-close:hover {
    color : #b9b9b9;
}

.dialog-ovelay .dialog header .fa-close:active {
    box-shadow : 0 0 5px #673AB7;
    color      : #a2a2a2;
}

.dialog-ovelay .dialog .dialog-msg {
    padding : 12px 10px
}

.dialog-ovelay .dialog .dialog-msg p {
    color     : #333;
    font-size : 15px;
    margin    : 0;
}

.dialog-ovelay .dialog footer {
    border-top : 1px solid #e5e5e5;
    padding    : 8px 10px;
}

.dialog-ovelay .dialog footer .controls {
    direction : rtl;
}

.dialog-ovelay .dialog footer .controls .button {
    border-radius : 3px;
    padding       : 5px 15px;
}

.button {
    border         : 1px solid transparent;
    border-radius  : 0.25rem;
    cursor         : pointer;
    display        : inline-block;
    font-size      : 1rem;
    font-weight    : 400;
    line-height    : 1.5;
    padding        : 0.375rem 0.75rem;
    text-align     : center;
    user-select    : none;
    vertical-align : middle;
    white-space    : nowrap;
}

.button-default {
    background-color : rgb(248, 248, 248);
    border           : 1px solid rgba(204, 204, 204, 0.5);
}

.button-danger {
    background-color : #f44336;
    border           : 1px solid #d32f2f;
    color            : #f5f5f5;
}

.button-success {
    background-color : #28a745;
    border           : 1px solid #28a745;
    color            : #f5f5f5;
}

.button-warning {
    background-color : #ffc107;
    border           : 1px solid #ffc107;
    color            : #212529;
}

.link {
    cursor  : pointer;
    padding : 5px 10px;
}

.lienVersAme {
    color           : var(--primary-font-color);
    text-decoration : none;
}

.popUpGeneral {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : block;
    left             : 50%; /* positionnement au milieu de l'écran */
    padding          : 10px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    z-index          : 1200;
}

/* Styles pour les divs d'en-tête de colonne */
.entete_tri {
    align-items     : center;
    cursor          : pointer;
    display         : flex;
    justify-content : center;
}

/* Styles pour les triangles */
.triangle {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 20px;

    &.asc > .triangle-down > i {
        visibility : hidden;
    }

    &.desc > .triangle-up > i {
        visibility : hidden;
    }

}

.triangle-up {
    position : relative;
    top      : 4px;
}

.triangle-down {
    bottom   : 4px;
    position : relative;
}

.erreur-form {
    color     : var(--error-color);
    font-size : 0.8rem;
    width     : 100%
}

.general_space {
    margin-left  : 5px;
    margin-right : 5px;
}

.modal-header-error {
    background-color : $danger;
}

.modal-header-succes {
    background-color : $success;
}

.modal-header-info {
    background-color : $primary;
}

.modal-header-warning {
    background-color : $warning;
    color            : black;
}


.header-content {
    align-items     : center;
    display         : flex;
    height          : 40px;
    justify-content : center;
    margin-left     : 25px;
    width           : 40px;
}

.card-name-header-menu {
    margin-left : 20px;
    text-align  : center;
    width       : 127px;
}

.groupNameChevron {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 100%;

    i {
        margin-right : 5px;
    }
}


.colorMapVisu {
    align-content   : center;
    align-items     : center;
    border          : 1px solid black;
    display         : flex;
    justify-content : center;
    margin-right    : 5px;
    width           : 40px;


    span {
        display   : inline-block;
        height    : 15px;
        min-width : 2px;
    }
}

.drapeau {
    height : 16px;
    width  : 20px;

    img {
        border     : 1px solid black;
        max-height : 20px;
        max-width  : 20px;
        min-height : 14px;
        min-width  : 14px;
    }
}

.drapeau_int {
    height : 20px;
    width  : 20px;

    img {
        border     : 1px solid black;
        max-height : 20px;
        max-width  : 20px;
        min-height : 14px;
        min-width  : 14px;
    }
}

.chargement_page {
    align-content   : center;
    align-items     : center;
    color           : var(--primary-font-color);
    display         : flex;
    flex-direction  : column;
    height          : 150px;
    justify-content : center;

    img {
        height : 150px;
    }

    span {
        position : relative;
        top      : -30px;
    }
}

.bouton_chargement_maj {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 32px;
    justify-content : center;
}

/*input:checked {
	border  : none;
	outline : 1px solid blue;
}*/

.ql-toolbar {
    background-color : var(--tertiary-color);
}

#zoneDropImage_contact {
    align-items     : center;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    flex-wrap       : wrap;
    gap             : 10px;
    justify-content : flex-start;
    min-height      : 100px;
    padding         : 5px;
    width           : 100%;
}

.zone_image_legend {
    display        : flex;
    flex-direction : column;
    gap            : 5px;
    position       : relative;
    width          : 20%;
}

#group_image_paste_insert {
    align-content   : flex-start;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

#zone_contact_body {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 10px;
    padding          : 10px;

    h2 {
        color       : var(--primary-font-color);
        font-family : edo_szregular, serif;
        font-size   : 2em;
        margin      : 0 auto;
        text-align  : center;
        width       : 90%;
    }
}

// Custom Swtich Toggle
.switch {
    overflow : hidden;
    position : relative;

    .btn-group-xs > .btn, .btn-xs {
        border-radius : .2rem;
        font-size     : .875rem;
        line-height   : .5;
        padding       : .35rem .4rem .25rem .4rem;
    }
}

.switch.btn.btn-light, .switch.btn.btn-outline-light {
    border-color : rgba(0, 0, 0, .15); /* Add a border so switch is delineated */
}

.switch input[type="checkbox"] {
    display : none;
}

.switch-group {
    bottom              : 0;
    left                : 0;
    position            : absolute;
    top                 : 0;
    -webkit-transition  : left 0.35s;
    transition          : left 0.35s;
    -moz-user-select    : none;
    -webkit-user-select : none;
    width               : 200%;
}

.switch.off .switch-group {
    left : -100%;
}

.switch-on {
    border        : 0;
    border-radius : 0;
    bottom        : 0;
    left          : 0;
    margin        : 0;
    position      : absolute;
    right         : 50%;
    top           : 0;
}

.switch-off {
    border        : 0;
    border-radius : 0;
    bottom        : 0;
    box-shadow    : none;
    left          : 50%;
    margin        : 0;
    position      : absolute;
    right         : 0;
    top           : 0;
}

.switch-handle {
    background-color : #fff;
    border-width     : 0 1px;
    height           : 100%;
    margin           : 0 auto;
    padding-bottom   : 0;
    padding-top      : 0;
    position         : relative;
    width            : 0;
}

.switch.btn-outline-primary .switch-handle {
    background-color : var(--primary);
    border-color     : var(--primary);
}

.switch.btn-outline-secondary .switch-handle {
    background-color : var(--secondary);
    border-color     : var(--secondary);
}

.switch.btn-outline-success .switch-handle {
    background-color : var(--success);
    border-color     : var(--success);
}

.switch.btn-outline-danger .switch-handle {
    background-color : var(--danger);
    border-color     : var(--danger);
}

.switch.btn-outline-warning .switch-handle {
    background-color : var(--warning);
    border-color     : var(--warning);
}

.switch.btn-outline-info .switch-handle {
    background-color : var(--info);
    border-color     : var(--info);
}

.switch.btn-outline-light .switch-handle {
    background-color : var(--light);
    border-color     : var(--light);
}

.switch.btn-outline-dark .switch-handle {
    background-color : var(--dark);
    border-color     : var(--dark);
}

.switch[class*="btn-outline"]:hover .switch-handle {
    background-color : var(--light);
    opacity          : 0.5;
}

/* NOTE: Must come first, so classes below override as needed */
/* bootstrap-4.x .form-control {height} values used as `min-height` to mirror output sizes */

.switch.btn {
    min-height : calc(1rem + 2px);
    min-width  : 3.7rem;
}

.switch-on.btn {
    padding-right : 1.5rem;
}

.switch-off.btn {
    padding-left : 1.5rem;
}

.switch.btn-lg {
    line-height : 1.5;
    min-height  : calc(1.5rem + 2px);
    min-width   : 5rem;
}

.switch-on.btn-lg {
    padding-right : 2rem;
}

.switch-off.btn-lg {
    padding-left : 2rem;
}

.switch-handle.btn-lg {
    width : 2.5rem;
}

.switch.btn-sm {
    min-height : calc(0.75rem + 2px);
    min-width  : 3.25rem;
}

.switch-on.btn-sm {
    padding-right : 1rem;
}

.switch-off.btn-sm {
    padding-left : 1rem;
}

/* `xs` (bootstrap-3.3 - .btn - h:22px) */
.switch.btn-xs {
    min-height : 0.6rem;
    min-width  : 3.125rem;
}

.switch-on.btn-xs {
    padding-right : .8rem;
}

.switch-off.btn-xs {
    padding-left : .8rem;
}

.selectcustom-select-container {
    /* Positionnez votre div ici */
    position : relative; /* Position relative pour permettre le positionnement absolu des enfants */
}

.selectcustom-options-container {
    /* Positionnez votre menu déroulant ici */
    left     : 0; /* Alignez-le à gauche avec la div parent */
    position : absolute; /* Position absolue pour le positionner par rapport à la div parent */
    z-index  : 100;
}

.selectcustom-selected-option {
    background-color : var(--secondary-color);
    color            : var(--primary-font-color); /* Couleur du texte */
    cursor           : pointer;
}

.selectcustom-option {
    /* Ajoutez vos styles pour les options ici */
    background-color : var(--secondary-color);
    color            : var(--primary-font-color); /* Couleur du texte */
    cursor           : pointer; /* Change le curseur en main lors du survol */
    padding          : 2px; /* Espacement interne */
}

.selectcustom-option:hover {
    /* Ajoutez vos styles pour les options lors du survol ici */
    background-color : var(--tertiary-color); /* Change la couleur de fond lors du survol */
}

.selectcustom-empty {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.btn-xs, .btn-group-xs > .btn {
    border-radius : var(--bs-border-radius-sm);
    font-size     : 12px;
    padding       : 2px 5px;
}

.ck-toolbar-container {
    width : 575px;
}

.ck-reset_all {
    width : none;
}

.font-weight-bold {
    font-weight : bold;
}

.modal-centralisation {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
}

.url-contact {
    text-decoration : underline;
}

.card-menu:hover {
    background-color : var(--secondary-color);
}

.border-dash-1 {
    border : 1px dashed var(--primary-border-color);
}

.border-dash-2 {
    border : 2px dashed var(--primary-border-color);
}

.border-solid-1 {
    border : 1px solid var(--primary-border-color);
}

.border-solid-2 {
    border : 2px solid var(--primary-border-color);
}

.text-primary-gh {
    color : var(--primary-font-color);
}

.text-secondary-gh {
    color : var(--secondary-color);
}

.tooltip-container {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : 6px;
    box-shadow       : 0px 4px 8px rgba(0, 0, 0, 0.3);
    color            : var(--primary-font-color);
    font-size        : 13px;
    max-width        : 300px;
    min-width        : 100px;
    opacity          : 1;
    padding          : 10px;
    position         : absolute;
    transform        : translateX(-50%);
    transition       : opacity 0.2s, visibility 0.2s;
    z-index          : 1200;
}

fieldset {
    border : 2px solid var(--primary-border-color);
}

.background-primary {
    background-color : var(--primary-color);
}

.background-secondary {
    background-color : var(--secondary-color);
}

.background-tertiary {
    background-color : var(--tertiary-color);
}

.fs-13-gh {
    font-size : 13px;
}

.fs-12-gh {
    font-size : 12px;
}

.fs-11-gh {
    font-size : 11px;
}

.fs-10-gh {
    font-size : 10px;
}

.fs-8-gh {
    font-size : 8px;
}

.lien_gh {
    cursor          : pointer;
    font-weight     : bold;
    text-decoration : underline;
    color           : var(--primary-font-color);

    > a {
        text-decoration : none;
        color           : inherit;
    }
}

.icon-container {
    display    : inline-block; /* Important pour que la transition s'applique correctement */
    transition : transform 0.2s ease; /* Animation fluide */
}

.icon-container:hover {
    transform : scale(1.3); /* Grossissement de 30% au survol */
}