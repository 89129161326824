.ressOK {
    color       : var(--success-color);
    font-weight : bold;
}

.color-check {
    color       : var(--success-color);
    font-weight : bold;
}

.ressKO {
    color       : var(--error-color);
    font-weight : bold;
}

.legendeCh {
    align-items     : center;
    display         : flex;
    justify-content : center;
    margin-top      : 10px;
    text-align      : center;
    width           : 100%;

    .legCh {
        border       : 1px solid var(--primary-border-color);
        display      : inline-block;
        height       : 20px;
        margin-right : 3px;
        width        : 20px;
    }
}

.bloc_legende {
    align-items     : center;
    display         : flex;
    justify-content : center;
}

.legende_chantier {
    align-items     : center;
    cursor          : pointer;
    display         : flex;
    justify-content : space-around;
    margin-left     : 3px;
    margin-right    : 5px;
}

.ligne_filtre_tri_option {
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : flex-start;
}

.chantier_nonaff {
    text-decoration : line-through;
}

.groupChantierCategorie {
    margin : 10px auto;
}

.titreCategorieChantier {
    align-items      : center;
    background-color : var(--tertiary-color);
    border           : 1px solid var(--primary-border-color);
    cursor           : pointer;
    display          : flex;
    font-size        : 1.5em;
    gap              : 10px;
    justify-content  : center;
    margin           : 0;
    text-align       : center;
    width            : 1043px;
}


.listChantier {
    width : 1045px;

    thead {
        width : 1045px;

        tr {
            background-color : var(--tertiary-color);
        }
    }

    tr, th, td {
        border : 1px solid var(--primary-border-color);

    }

    td:not(.chantier_nom,.chantier_ressources) {
        text-align : center;
    }

    td.chantier_nom, td.chantier_ressources {
        padding-left : 4px;
    }

    td.chantier_ressources {
        div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-wrap       : wrap;
            justify-content : flex-start;
        }
    }

    th {
        a {
            color           : var(--primary-font-color);
            text-decoration : none;
        }
    }

    tr.ligneChantier {
        width : 1045px;
    }

    .chantier_nom.root > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : flex-start;
        padding-left    : 0;

    }

    .chantier_nom.lv-1 > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : flex-start;
        padding-left    : 16px;

    }

    .chantier_nom.lv-2 > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        padding-left    : 32px;

    }

    .chantier_nom.lv-3 > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        padding-left    : 48px;

    }

    .chantier_nom.lv-4 > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        padding-left    : 64px;

    }

    .chantier_nom.lv-5 > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        padding-left    : 80px;

    }

    .chantier_nom.lv-6 > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
        padding-left    : 96px;

    }

    .chantier_nom {
        width : 250px;
    }

    .chantier_plan, .chantier_temp, .chantier_repa {
        width : 25px;
    }

    .chantier_pa, .chantier_pv, .chantier_def, .chantier_eau {
        width : 45px;
    }

    .chantier_ressources {
        width : 500px;

    }

}

#zoneOutilsChantierConteneur {
    margin-bottom : 5px;
    width         : 1220px;


    > ul > li {
        cursor      : pointer;
        float       : left;
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
    }

}

#tabs_outils_chantiers {

    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between;
    width           : 100%;

    #recap_def_chantiers {
        display : flex;
        width   : 100%;
    }

    #group_tableur_outils_chantiers {

        align-content   : center;
        align-items     : flex-start;
        display         : flex;
        justify-content : space-between;
        margin-bottom   : 10px;
        margin-left     : 4px;
        margin-right    : 4px;
        width           : 1216px;

        #tabs_gestion_outils_chantiers {
            width : 790px;

            ul {
                li {
                    background-color : var(--primary-color);
                    color            : var(--primary-font-color);
                    cursor           : pointer;
                    float            : left;
                    line-height      : 26px; /* on définit une hauteur pour chaque élément */
                    list-style       : none; /* on supprime le style par défaut de la liste */
                    margin           : 0; /* ni extérieure */
                    padding          : 0; /* Pas de marge intérieure */
                    text-align       : center; /* on centre le texte qui se trouve dans la liste */

                    &:hover {
                        background-color : var(--special-color);
                        color            : var(--hover-font-color);
                    }
                }
            }
        }

        #tabs_recap_outils_chantiers {
            width : 410px;

            ul {
                li {
                    background-color : var(--primary-color);
                    color            : var(--primary-font-color);
                    cursor           : pointer;
                    float            : left;
                    line-height      : 26px; /* on définit une hauteur pour chaque élément */
                    list-style       : none; /* on supprime le style par défaut de la liste */
                    margin           : 0; /* ni extérieure */
                    padding          : 0; /* Pas de marge intérieure */
                    text-align       : center; /* on centre le texte qui se trouve dans la liste */

                    &:hover {
                        background-color : var(--special-color);
                        color            : var(--hover-font-color);
                    }
                }
            }
        }
    }


}

#def_chantier_actuelle, #def_chantier_prevu, #recap_pa {
    width : 33%;
}

.zoneOutilsChantiersPlans > div:not(.bordureHautChantier) {
    border-bottom : 2px solid var(--primary-border-color);
    border-left   : 2px solid var(--primary-border-color);
    border-right  : 2px solid var(--primary-border-color);
}

.selectedOngletOutilsChantiers {
    background-color : var(--secondary-color);
    border-bottom    : 2px solid var(--secondary-color);
}


.bordureHautChantier {
    background-color : var(--primary-border-color);
    display          : flex;
    height           : 2px;
    padding-right    : 4px;
    width            : 100%;
}

#tabs_outils_chantiers {
    width : 100%;
}

.tab_recapchantier {

    width : 619px;

    thead, tr, th, td {
        background-color : var(--tertiary-color);
        border           : 1px solid var(--primary-border-color);
    }

    th.nom, td.nom {
        width : 250px;
    }

    th.bde, td.bde {
        width : 40px;

    }

    th.pa_restant, td.pa_restant, th.def, td.def {
        width : 60px;
    }

    th.pa_laisser, td.pa_laisser {
        width : 90px;

        input {
            width : 90px;
        }
    }

    th.priority, td.priority {
        width : 100px;
    }

    div.centrage_info {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;

    }
}

#recapChantier {
    align-content   : center;
    align-items     : flex-start;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    justify-content : space-around;
    padding         : 5px;
}

#recap_general {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 150px;
}

#group_recap_pa_def {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;
    width           : 100%;

    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;
    }
}


#reparationArme {
    align-content   : center;
    align-items     : flex-start;
    border          : 1px solid var(--primary-font-color);
    display         : flex;
    justify-content : center;
    padding         : 6px;


    #bloc_repa {
        width : 300px;
    }

    #option_repa {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 10px;
        justify-content : center;
        width           : 600px;

        #bloc_option_repa {
            align-items           : start;
            display               : grid;
            grid-template-columns : 6fr 8fr;
            justify-content       : center;
            justify-items         : center;
        }

        .option_repaArme {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 5px;
            justify-content : center;

            #input_nbr_vert {
                width : 40px;
            }
        }

        #bloc_list_repa_arme {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-direction  : column;
            gap             : 5px;
            justify-content : center;
        }
    }
}

.tab_armesRepa {

    width : 300px;

    thead, tr, th, td {
        background-color : var(--tertiary-color);
        border           : 1px solid var(--primary-font-color);
    }

    th.nom, td.nom {
        width : 220px;
    }

    td.nom span {
        display : flex;
        gap     : 5px;
    }

    th.nbr, td.nbr {
        text-align : center;
        width      : 80px;

    }
}

.tab_armesARepa {

    width : 450px;

    thead, tr, th, td {
        background-color : var(--tertiary-color);
        border           : 1px solid var(--primary-font-color);
    }

    th.nom, td.nom {
        width : 260px;
    }

    th.nbr, td.nbr {
        width : 95px;

        input {
            width : 90px;
        }
    }
}

#upAmeliorationHabitation {
    align-content   : center;
    align-items     : flex-start;
    border          : 1px solid var(--primary-font-color);
    display         : flex;
    justify-content : center;
    padding         : 6px;


    #bloc_recapCitoyen {
        width : 400px;

        .tab_recapCitoyen {

            width : 400px;

            thead, tr, th, td {
                background-color : var(--tertiary-color);
                border           : 1px solid var(--primary-font-color);
            }

            th.pseudo, td.pseudo {
                width : 170px;
            }

            td.pseudo {
                font-size : 12px;
            }

            th.lvl, td.lvl {
                font-size  : 12px;
                text-align : center;
                width      : 25px;
            }
        }
    }

    #bloc_up_habitations {
        width : 360px;

        .tab_upHabitation {

            width : 360px;

            thead, tr, th, td {
                background-color : var(--tertiary-color);
                border           : 1px solid var(--primary-font-color);
            }

            th.pseudo, td.pseudo {
                width : 160px;
            }

            th.choix, td.choix {
                width : 160px;
            }

            th.action, td.action {
                width : 40px;
            }
        }
    }


    #option_upAme {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 10px;
        justify-content : center;
        width           : 400px;

        .titre_up {
            margin : 0;
        }

        /*#bloc_up_habitation {
            align-items           : start;
            display               : grid;
            grid-template-columns : 6fr 8fr;
            justify-content       : center;
            justify-items         : center;
        }

        #bloc_up_amelerioration {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-direction  : column;
            gap             : 5px;
            justify-content : center;
        }*/
    }
}


#upChantiers {
    align-content   : center;
    align-items     : flex-start;
    border          : 1px solid var(--primary-font-color);
    display         : flex;
    justify-content : center;
    padding         : 6px;
}

#bloc_pa_ouvrier {
    align-content   : center;
    align-items     : flex-start;
    border          : 1px solid var(--primary-font-color);
    display         : flex;
    justify-content : center;
    padding         : 6px;
}

#ressources_outils_chantier {
    align-content   : center;
    align-items     : flex-start;
    border          : 1px solid var(--primary-font-color);
    display         : flex;
    justify-content : center;
    padding         : 6px;
}

.alignIcone_chantier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 3px;
    justify-content : center;
}