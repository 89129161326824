#logListing {
    margin : 5px;
    width  : 100%;

    thead {
        background-color : var(--tertiary-color);
    }

    tr, td, th {
        border : 1px solid var(--primary-border-color);
    }

    .log_date {
        text-align : center;
        width      : 100px;
    }

    .log_tag {
        text-align : center;
        width      : 100px;

        .tag {
            border-radius : 3px;
            padding       : 2px;
        }
    }

    .log_action {
        text-align : center;
        width      : 150px;
    }

    .log_file {
        padding : 3px;
        width   : 860px;
    }
}