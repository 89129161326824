#admin_zone_ville_info {
    h2 {
        margin     : 0;
        text-align : center;
    }

    #admin_ville_info_disable, #admin_ville_info_edit {
        align-content         : center;
        align-items           : center;
        display               : grid;
        gap                   : 5px;
        grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
        justify-content       : center;
        justify-items         : center;
    }
}
