#formBat {
    font-size : 1rem;
    margin    : auto;
    padding   : 10px;
    width     : 65%;

    div#form {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-around;

        div.boutSave {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-wrap       : wrap;
            justify-content : space-around;
        }

        div:not(.textAreaAlign,.boutSave) {
            width : 50%;
        }

        div.textAreaAlign, div.boutSave {
            width : 100%;
        }
    }

}