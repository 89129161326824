.formPlansChantier, .formPlansChantier form {
    background-color : var(--secondary-color);
    display          : flex;
    width            : 100%;
}

.listChantierPlans {
    align-items      : center;
    background-color : var(--secondary-color);
    display          : flex;
    flex-wrap        : wrap;
    justify-content  : center;
    width            : 1216px;
}


#zonePlansChantier {
    font-size : 14px;
    margin    : 0 2px 0 0;
    width     : 94%;
}

.plansSpanRuine {
    width : 100%;
}

.listPlansRareRuine, .listPlansInhabRuine, .listPlansTresRareRuine {
    align-content   : flex-start;
    align-items     : flex-start;
    display         : flex;
    flex-direction  : column;
    flex-wrap       : wrap;
    justify-content : flex-start;
    width           : 100%;

}

.listPlansCommun, .listPlansInhab, .listPlansRare, .listPlansTresRare {
    align-items           : start;
    display               : grid;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    justify-items         : start;
    padding-bottom        : 10px;
    width                 : 100%;
}

.listPlansCommun {
    background-color : rgba(0, 255, 0, 0.1);
}

.listPlansInhab {
    background-color : rgba(255, 255, 0, 0.1);
}

.listPlansRare {
    background-color : rgba(0, 0, 255, 0.1);
}

.listPlansTresRare {
    background-color : rgba(255, 0, 255, 0.1);
}

.listPlansInhabRuine {
    background-color : rgba(255, 255, 0, 0.1);
    min-height       : 190px;
}

.listPlansRareRuine {
    background-color : rgba(0, 0, 255, 0.1);
    min-height       : 220px;
}

.listPlansTresRareRuine {
    background-color : rgba(255, 0, 255, 0.1);
    min-height       : 140px;
}

#formPlansFooter {
    display         : flex;
    justify-content : space-between;
    width           : 100%;
}

.blocPlan {
    text-decoration : line-through;
}


#zoneBoutonPlansRuine {
    background-color : rgba(255, 255, 255, 0.2);
    border           : 2px solid var(--primary-border-color);
    cursor           : pointer;
    font-size        : 1.3em;
    text-align       : center;
    writing-mode     : vertical-rl;

}

#declenchRuine {
    color       : blue;
    font-size   : 0.7em;
    font-weight : bold;

}

#zoneRuinePlan {
    display  : flex;
    margin   : 0 0 0 2px;
    position : absolute;
    width    : 1148px;

}

#zoneInviPlan {
    visibility : hidden;
    width      : 437px;
}

#listRuinePlans {
    background-image : var(--primary-bg-img);
    border           : 2px solid var(--primary-border-color);
    display          : block;
    height           : 560px;
    width            : 880px;
}

#ruineFlex {
    background-color : var(--primary-color);
    background-image : var(--primary-bg-img);
    display          : flex;
}

#ruineBunker, #ruineHopital, #ruineHotel {
    font-size : 0.9rem;
    width     : 33%;
}

.titreRuine {
    background-color : var(--tertiary-color);
    border-bottom    : 1px solid var(--primary-border-color);
    font-weight      : bold;
    text-align       : center;
    width            : 100%;
}

#ruineBunker, #ruineHopital {
    border-right : 2px solid var(--primary-border-color);
}

#boutonFormChantier {
    align-items     : center;
    display         : flex;
    gap             : 15px;
    justify-content : flex-start;
    margin          : 5px 5px 5px 10px;
}

#corpsPlanChantier {
    display       : flex;
    margin-bottom : 4px;
    width         : 100%;

    h2 {
        margin     : 0 0 5px;
        text-align : center;
        width      : 100%;
    }
}


#zonePlansChantierConteneur {
    margin-bottom : 5px;
    width         : 1220px;

    > ul > li {
        cursor      : pointer;
        float       : left;
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
        width       : 200px;

        > button {
            width : 200px;
        }
    }
}

#zonePlansChantierConteneur > div {
    border-bottom : 2px solid var(--primary-border-color);
    border-left   : 2px solid var(--primary-border-color);
    border-right  : 2px solid var(--primary-border-color);
}

.boutonMenuPlans {
    background-color        : var(--secondary-color);
    border-bottom           : 2px solid var(--primary-border-color);
    border-left             : 2px solid var(--primary-border-color);
    border-right            : 2px solid var(--primary-border-color);
    border-top              : 2px solid var(--primary-border-color);
    border-top-left-radius  : 5px;
    border-top-right-radius : 5px;
    margin-bottom           : -2px;
}

.selectedOngletPlans {
    background-color : var(--secondary-color);
    border-bottom    : 2px solid var(--secondary-color);
}

.item_banque {
    color : white;
}

.ressourceChantierNotGeneric {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-evenly;
    width           : 75px;
}

#compteurPlans {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

#zonePlansChantiersPlans {
    background-color : var(--secondary-color);
}

#listEvoChantierPlans {

    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    width            : 1215px;

    table {
        margin : 20px;
        width  : 1158px;

        tr, td, th {
            border : 1px solid var(--primary-border-color);
        }

        thead {
            background-color : var(--tertiary-color);
            border-bottom    : 2px solid var(--primary-border-color);

            th {
                height : 40px
            }
        }

        tbody {
            tr:nth-child(odd) {
                background-color : var(--primary-row-color);
            }

            tr:nth-child(even) {
                background-color : var(--secondary-row-color);
            }
        }
    }
}