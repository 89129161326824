#formHeros {
    font-size : 1rem;
    margin    : auto;
    padding   : 10px;
    width     : 40%;

    div#form {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-around;

        div.boutSave {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-wrap       : wrap;
            justify-content : space-around;
            width           : 100%;
        }

        div.textAreaAlign {
            width : 100%;
        }
    }
}