#zoneListingPictosClassement {
    #classementPicto_general, #classementPicto_ville {
        align-content   : center;
        align-items     : flex-start;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : center;
    }

    #classementPicto_ville {
        display : none;
    }

}

.pictoRare {
    border : 4px solid var(--gold-border-color);
}

.pictoCommun {
    border : 4px solid var(--primary-border-color);
}

.pictoRareAme {
    border : 2px solid var(--gold-border-color);
}

.titreRareAme {
    border-bottom : 2px solid var(--gold-border-color);
}

.pictoCommunAme {
    border : 2px solid var(--primary-border-color);
}

.titreCommunAme {
    border-bottom : 2px solid var(--primary-border-color);
}

.myLine {
    background-color : var(--error-color);
    color            : white;
}

.tablePicto {
    background-color : var(--secondary-color);
    margin           : 10px;
    width            : 210px;

    .entete_classement_picto {
        align-content    : center;
        align-items      : center;
        background-color : var(--tertiary-color);
        display          : flex;
        height           : 40px;
        justify-content  : center;
        text-align       : center;

        a {
            color           : var(--primary-font-color);
            cursor          : pointer;
            text-decoration : none;
        }
    }


    table {
        width : 100%;

        tr, td {
            border     : 1px solid var(--primary-border-color);
            text-align : center;

            .tab_classement_picto_id {
                width : 20px;
            }

            .tab_classement_picto_pseudo {
                width : 130px;

                a {
                    color           : var(--primary-font-color);
                    text-decoration : none;
                }
            }

            .tab_classement_picto_nombre {
                width : 60px;
            }
        }


    }

}

.classement_picto_part {
    margin     : auto;
    max-height : 400px;
    width      : 100%;

    table {
        width : 100%;

        tbody, thead {
            width : 100%;

            tr, td, th {
                border     : 1px solid var(--primary-border-color);
                text-align : center;

                .tab_classement_picto_id {
                    width : 20px;
                }

                .tab_classement_picto_pseudo {
                    width : 130px;

                    a {
                        color           : var(--primary-font-color);
                        text-decoration : none;
                    }
                }

                .tab_classement_picto_nombre {
                    width : 60px;
                }
            }
        }

        tbody {
            background-color : var(--secondary-color);
        }

        thead {
            background-color : var(--tertiary-color);
        }
    }
}

.titrePicto {
    display : inline-flex;
    height  : 32px;
    width   : 32px;
}

#corpsAme {
    display : flex;
    width   : 100%;

    #zone_groupe_avatar_listing {
        width : 480px;

        #info_perso_ame {
            display : flex;
            width   : 100%;

            .avatar_ame {
                height : 100px;
                margin : 4px;
                width  : 100px;

                img {
                    max-height : 100px;
                    max-width  : 100px;
                }

            }

            #container_infoAme_general {
                background-color : var(--secondary-color);
                border           : 1px solid var(--primary-border-color);
                height           : 130px;
                margin           : 4px 2px 5px;
            }

            #container_infoAme {
                background-color : var(--secondary-color);
                height           : 100px;
                overflow         : hidden;
                position         : relative;
                width            : 375px;
            }

            .panel_ame {
                background-color : var(--secondary-color);
                border           : 1px solid var(--primary-border-color);
                height           : 88px;
                left             : 0;
                padding          : 5px;
                position         : absolute;
                top              : 0;
                transition       : transform 0.5s ease;
                width            : 375px;
            }

            .left-panel {
                left    : 0;
                z-index : 1;
            }

            .right-panel {
                right     : 0;
                transform : translateX(350px); /* Partiellement visible (100px visibles) */
                z-index   : 2;
            }

            /* Lorsque le panneau de droite est ouvert, il glisse par-dessus le panneau de gauche */
            .right-panel.open {
                transform : translateX(-300px); /* Glisse complètement vers la gauche */
            }

            /* Masque le panneau gauche lorsqu'il est couvert par le panneau droit */
            .left-panel.hidden {
                z-index : 0; /* Masque la gauche sous le panel droit */
            }

            .toggle-button_ame {
                transform : rotate(90deg) translateX(-38px) translateY(6px);
                width     : 25px;
                z-index   : 10;

                > button {
                    width : 100px;
                }
            }

            .toggle-button_ame.open {
                transform : rotate(-90deg) translateY(-6px) translateX(-38px);
                width     : 25px;
                z-index   : 10;

                > button {
                    width : 100px;
                }
            }

            .info_complementaire_ame {
                /*align-content   : flex-start;
                align-items     : center;
                display         : flex;
                flex-wrap       : wrap;
                justify-content : flex-start;
                margin          : 4px;*/


                .nomVille_ame {
                    a {
                        color           : var(--primary-font-color);
                        font-weight     : bold;
                        text-decoration : none;
                    }
                }
            }
        }

        #zoneListingPictos {
            align-content    : center;
            align-items      : flex-start;
            background-color : var(--secondary-color);
            display          : flex;
            flex-wrap        : wrap;
            justify-content  : center;
            width            : 100%;

            .tablePictoAme {
                background-color : var(--primary-color);
                height           : 45px;
                margin           : 2px;
                width            : 45px;

                .ensemblePicto_Nombre {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    flex-wrap       : wrap;
                    height          : 100%;
                    justify-content : center;
                    width           : 100%;

                    .picto_ame_nombre {
                        display    : inline-block;
                        text-align : center;
                        width      : 100%;
                    }

                    .picto_ame_img {
                        display : inline-block;
                        height  : 16px;
                        width   : 16px;
                    }
                }
            }
        }

        #zoneListingTitres {
            align-content    : center;
            align-items      : flex-start;
            background-color : var(--secondary-color);
            display          : flex;
            flex-direction   : column;
            flex-wrap        : wrap;
            justify-content  : center;
            width            : 100%;

            .tableTitreAme {
                margin : 2px;
                width  : 100%;
            }
        }
    }

    #zoneListingPictosTitre {
        margin : 0 auto auto;
        width  : 480px;

        h2 {
            text-align : center;
            width      : 100%;
        }

        .nomVille_listing_ame {
            a {
                color           : var(--primary-font-color);
                text-decoration : none;
            }
        }

        > ul > li {
            cursor           : pointer;
            float            : left;
            line-height      : 26px; /* on définit une hauteur pour chaque élément */
            list-style       : none; /* on supprime le style par défaut de la liste */
            margin           : 0; /* ni extérieure */
            padding          : 0; /* Pas de marge intérieure */
            text-align       : center; /* on centre le texte qui se trouve dans la liste */
            width            : 200px;

            > button {
                width : 200px;
            }

        }

    }

    #zoneListingVille {
        margin : 0 auto auto;
        width  : 710px;

        h2 {
            text-align : center;
            width      : 100%;
        }

        .nomVille_listing_ame {
            a {
                color           : var(--primary-font-color);
                text-decoration : none;
            }
        }

        > ul > li {
            cursor           : pointer;
            float            : left;
            line-height      : 26px; /* on définit une hauteur pour chaque élément */
            list-style       : none; /* on supprime le style par défaut de la liste */
            margin           : 0; /* ni extérieure */
            padding          : 0; /* Pas de marge intérieure */
            text-align       : center; /* on centre le texte qui se trouve dans la liste */
            width            : 300px;

            > button {
                width : 300px;
            }
        }

    }

    .listHistoVille {
        align-content   : center;
        align-items     : flex-start;
        border          : 2px solid var(--primary-border-color);
        display         : flex;
        flex-wrap       : wrap;
        justify-content : center;
        margin          : 2px;
        width           : 695px;


        .nomVille_listing_ame {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : center;
            width           : 100%;
        }
    }

    .zoneHistoPictos {
        align-content   : center;
        align-items     : flex-start;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : center;
        min-height      : 35px;
        width           : 100%;

        .tablePictoAme {
            background-color : var(--primary-color);
            height           : 35px;
            margin           : 2px;
            min-width        : 30px;

            .ensemblePicto_Nombre {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-wrap       : wrap;
                height          : 100%;
                justify-content : center;
                width           : 100%;

                .picto_ame_nombre {
                    display    : inline-block;
                    font-size  : 0.7rem;
                    text-align : center;
                    width      : 100%;
                }

                .picto_ame_img {
                    display : inline-block;
                    height  : 16px;
                    width   : 16px;
                }
            }
        }
    }

    .lien_carte_ame {
        cursor          : pointer;
        font-weight     : bold;
        text-decoration : underline;
    }

    .nom_cleaneur {
        font-weight : bold;
    }


}

.ame_chargement_ville {
    display : block;
    margin  : 5px auto auto;
}

.menu_ame_ville {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;
}

.filtre_saison_ville {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 100%;
}

.bouton_moins_ville {
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 20%;
}

#zone_tabs_picto {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 5px;
    justify-content  : center;
    padding-top      : 7px;
    width            : 478px;
}

#zone_tabs_titre {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 5px;
    justify-content  : center;
    padding-top      : 7px;
    width            : 478px;
}

#zone_tabs_histo {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 5px;
    justify-content  : center;
    padding-top      : 7px;
    width            : 710px;
}

#zone_tabs_voisin {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 10px;
    justify-content  : center;
    padding-bottom   : 10px;
    padding-top      : 10px;
    width            : 710px;
}

.lien_to_ame a {
    color           : var(--primary-font-color);
    display         : flex;
    gap             : 5px;
    text-decoration : none;
}

#tabs_voisin_ame {
    tr, td, th {
        border     : 1px solid var(--primary-border-color);
        text-align : center;
    }

    thead {
        background-color : var(--tertiary-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--primary-row-color);
    }
}

.listHistoVille.ville_standard {
    background-color : var(--secondary-color);
}

.legend-color-commune {
    align-content   : center;
    align-items     : flex-end;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.grille_stats_ame {
    display               : grid;
    font-size             : 12px;
    gap                   : 5px;
    grid-template-columns : repeat(2, 1fr);
    width                 : 100%;
}
