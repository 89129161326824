#formChantiers {
    font-size : 1rem;
    margin    : auto;
    padding   : 10px;
    width     : 70%;

    form {
        display   : flex;
        flex-wrap : wrap;
    }

    input[type='number'] {
        width : 45px;
    }
}

.ligneFormChantier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    margin          : 2px;
    width           : 100%;

    div {
        margin : 2px;
    }
}

#ruineChantier {
    justify-content : center;
}


.ligneFormChantierRessource, .ligneFormChantierUpChantier {
    width : 100%;

    h3 {
        margin     : 2px;
        text-align : center;
    }
}

ul#form_ressources {
    margin               : 2px;
    padding-inline-start : 0;

    li {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-around;
        width           : 100%;

        > div {
            width : 70%;

            > div {
                display         : flex;
                justify-content : space-around;
            }
        }

        button {
            width : 30%;
        }

        div[id^='form_ressources_'] {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-around;
            width           : 100%;
        }
    }

}

ul#form_levelUps {
    margin               : 2px;
    padding-inline-start : 0;

    li {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-around;
        width           : 100%;

        > div {
            width : 80%;

            > div {
                display         : flex;
                justify-content : space-around;
            }
        }

        button {
            width : 20%;
        }

        div[id^='form_levelUps_'] {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-around;
            width           : 100%;
        }
    }

}

.levelEvo_proto {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 60px;
    justify-content : space-between;
    width           : 15%;
}

.labelBonusEvo_proto {
    display    : block;
    text-align : center;
    width      : 100%;
}

.bonusEvo_proto {
    width : 40%;
}

.bonusEvo_form_proto {
    align-items    : flex-start;
    display        : flex;
    flex-direction : column;
    flex-wrap      : nowrap;

    div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-between;
        width           : 100%;

        label {
            display : block;
        }
    }
}