#corps_Expe {
    font-size : 1rem;
    width     : 100%;
}

.zoneExpedition {
    width : 100%;
}

#formTrace_ligne_1 {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-around;
    margin          : auto;
    width           : 100%;
}

#contenuZoneInfoExpedition table#trace_expe {
    margin : auto;
}


#contenuZoneInfoExpedition table#trace_expe, #contenuZoneInfoExpedition table#trace_expe tr, #contenuZoneInfoExpedition table#trace_expe td, #contenuZoneInfoExpedition table#trace_expe th {
    border     : solid 1px var(--primary-border-color);
    text-align : center;
}

#trace_expe .list_couleur_trace {
    border        : solid 1px var(--primary-border-color);
    border-radius : 3px;
    display       : inline-block;
    height        : 15px;
    margin-right  : 10px;
    width         : 15px;
}

#trace_expe .nom_coul_tb_trace {
    width : 400px;
}

#trace_expe .nom_coul_tb_trace div {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 100%;
}

#trace_expe .action_tb_trace > div {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100%;
}

#trace_expe .jour_tb_trace, #trace_expe .pa_tb_trace, #trace_expe .collab_tb_trace, #trace_expe .visible_tb_trace {
    width : 30px;
}

#trace_expe .action_tb_trace {
    width : 85px;
}

#trace_expe .user_tb_trace {
    width : 175px;
}

#popUpExpe {
    height : 140px;
    width  : 430px;
}

#popUpExpeSuccess {
    height : 65px;
    width  : 430px;
}

#zone_outils_expedition {
    background-color : var(--secondary-color);
    margin           : auto;
    padding          : 5px;

    fieldset {
        margin : 0 5px 5px;
    }

    #zone_recap_expe {
        background-color : var(--primary-color);
        border           : 1px solid var(--primary-border-color);
        display          : flex;
        flex-direction   : column;
        gap              : 5px;
        padding          : 5px;

        div.preinscrit_liste_exped {
            display        : flex;
            flex-direction : column;
            gap            : 5px;
        }

        div.group_bouton_outils_expedition {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-evenly;
        }

        div.zone_sac_ouvriers {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-between;
            padding-left    : 5px;
            padding-right   : 5px;

            .sac_ouvriers {
                align-content         : center;
                align-items           : center;
                display               : grid;
                flex-wrap             : wrap;
                gap                   : 5px;
                grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
                justify-content       : center;
                justify-items         : center;
                width                 : 65%;
            }
        }

        > fieldset {
            background-color : var(--secondary-row-color);

            legend {
                font-size   : 1.5em;
                font-weight : bold;
            }

            table#expeditions {
                width : 100%;

                tbody tr:nth-child(odd) {
                    background-color : var(--secondary-row-color);
                }

                tbody tr:nth-child(even) {
                    background-color : var(--primary-row-color);
                }

                thead {
                    background-color : var(--tertiary-color);
                }

                tr, td, th {
                    border     : 1px solid var(--primary-border-color);
                    text-align : center;
                }

                .xsmall_cols {
                    width : 5%;

                    > div {
                        align-items     : center;
                        display         : flex;
                        gap             : 10px;
                        justify-content : center;
                    }
                }

                .small_cols {
                    width : 9%;

                    > div {
                        align-items     : center;
                        display         : flex;
                        gap             : 10px;
                        justify-content : center;
                    }
                }

                .small_cols_cons {
                    width : 10%;

                    > div.coordonnees {
                        align-content   : center;
                        align-items     : center;
                        display         : flex;
                        flex-direction  : row;
                        gap             : 8px;
                        justify-content : center;
                    }
                }

                .medium_cols {
                    width : 14%;

                    > div {
                        align-items     : center;
                        display         : flex;
                        gap             : 10px;
                        justify-content : center;
                    }
                }

                .big_cols {
                    width : 20%;

                    input {
                        width : 98%;
                    }
                }

                .sm_cols {
                    width : 4%;

                    > div {
                        align-items     : center;
                        display         : flex;
                        gap             : 10px;
                        justify-content : center;
                    }
                }
            }

            table#ouvriers {
                width : 100%;

                tbody tr:nth-child(odd) {
                    background-color : var(--secondary-row-color);
                }

                tbody tr:nth-child(even) {
                    background-color : var(--primary-row-color);
                }

                thead {
                    background-color : var(--tertiary-color);
                }

                tr, td, th {
                    border     : 1px solid var(--primary-border-color);
                    text-align : center;
                }

                .small_cols_ouvrier {
                    width : 7%;

                    > div {
                        align-items     : center;
                        display         : flex;
                        justify-content : center;
                    }
                }

                .xsmall_cols_ouvrier {
                    width : 3%;

                    > div {
                        align-items     : center;
                        display         : flex;
                        justify-content : center;
                    }
                }

                .small_cols_cons {
                    width : 10%;

                    > div.coordonnees {
                        align-content   : center;
                        align-items     : center;
                        display         : flex;
                        flex-direction  : row;
                        gap             : 8px;
                        justify-content : center;
                    }
                }

                .medium_cols {
                    width : 14%;
                }

                .big_cols {
                    width : 20%;

                    input {
                        width : 98%;
                    }
                }

                .sm_cols {
                    width : 4%;
                }
            }

            #bouton_ouvrier_gestion {
                align-content   : center;
                align-items     : center;
                display         : flex;
                gap             : 20px;
                justify-content : flex-start;
                margin          : 10px;
            }
        }
    }
}


#choix_type_expe {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : flex-start;
}

.radio_type_expe {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;

    label {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;
    }
}

#outils_expedition_carac {
    align-content   : center;
    align-items     : flex-start;
    display         : flex;
    flex-direction  : column;
    gap             : 5px;
    justify-content : center;
}

#outils_expedition_gestion {

    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    margin           : auto;
    padding          : 5px;

    div#nom_expe {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;

        input {
            width : 200px;
        }
    }

    div#pdc_min {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;

        input {
            width : 30px;
        }
    }

    div.zone_sac_expeditionnaire {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-between;
        padding-left    : 5px;
        padding-right   : 5px;

        .sac_expeditionnaire {
            align-content         : center;
            align-items           : center;
            display               : grid;
            flex-wrap             : wrap;
            grid-template-columns : 1fr 1fr 1fr 1fr 1fr 1fr;
            justify-content       : center;
            justify-items         : center;
            width                 : 65%;
        }
    }

    fieldset {
        background-color : var(--secondary-color);
    }

    > .expedition_part {

        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 7px;
        justify-content : center;

        table {
            width : 100%;

            tbody tr:nth-child(odd) {
                background-color : var(--secondary-row-color);
            }

            tbody tr:nth-child(even) {
                background-color : var(--primary-row-color);
            }

            thead {
                background-color : var(--tertiary-color);
            }

            tr, td, th {
                border     : 1px solid var(--primary-border-color);
                text-align : center;
            }

            .small_cols {
                width : 9%;

                > div {
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }
            }

            .xsmall_cols {
                width : 5%;

                > div {
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }
            }

            .small_cols_cons {
                width : 10%;


                > div.coordonnees {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    flex-direction  : row;
                    gap             : 8px;
                    justify-content : center;
                }
            }

            .medium_cols {
                width : 14%;

                > div {
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }
            }

            .big_cols {
                width : 30%;
            }

            .sm_cols {
                width : 4%;

                > div {
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }
            }
        }

        .zone_choix_trace_expedition_select {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 7px;
            justify-content : center;
        }

        .zone_choix_description_expedition {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 7px;
            justify-content : center;
            width           : 800px;

            > span.bloc_label_bouton_description {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                gap             : 10px;
                justify-content : center;
            }

            > span.consultation_expedition {
                width : 600px;
            }
        }

        .zone_consigne_expedition {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 7px;
            justify-content : flex-start;
            padding-left    : 5px;

            .consultation_expedition {
                text-align : left;
                width      : 85%;
            }
        }
    }

    #zone_button {
        align-items     : center;
        display         : flex;
        justify-content : space-around;

        > div {
            align-items     : center;
            display         : flex;
            justify-content : space-around;
            width           : 450px;
        }
    }
}

.choix_direction_fao {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 7px;
    justify-content : center;
}

#zone_choix_fao_ouvrier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.historique_modification_outils_expe {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : flex-end;
    margin-top      : 12px;
    width           : 99%;
}


#popUpSacExpeditionnaire {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    left             : 50%; /* positionnement au milieu de l'écran */
    min-height       : 500px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 900px;
    z-index          : 1110;

    #zoneInfoSacExpeditionnaire {
        align-content   : center;
        display         : flex;
        flex-direction  : column;
        gap             : 10px;
        justify-content : flex-start;
        padding-left    : 5px;
        width           : 25%;

        h2 {
            margin     : 3px 0;
            text-align : center;
            width      : 100%;
        }

        form > p {
            margin : 0 0 2px;
        }
    }

    #objetSacMaj {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;

        > div {
            cursor : pointer;
        }
    }

    #boutonMajSacExpeditionnaire {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 30px;
        justify-content : center;
        width           : 100%;
    }

    #listingObjetSacExpeditionnaireObjet {
        padding : 5px;
        width   : 75%;
    }


}

#popUpSacOuvrier {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    left             : 50%; /* positionnement au milieu de l'écran */
    min-height       : 500px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 900px;
    z-index          : 1110;

    #zoneInfoSacOuvrier {
        align-content   : center;
        display         : flex;
        flex-direction  : column;
        gap             : 10px;
        justify-content : flex-start;
        padding-left    : 5px;
        width           : 25%;

        h2 {
            margin     : 3px 0;
            text-align : center;
            width      : 100%;
        }

        form > p {
            margin : 0 0 2px;
        }
    }

    #objetSacMaj {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;

        > div {
            cursor : pointer;
        }
    }

    #boutonMajSacOuvrier {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 30px;
        justify-content : center;
        width           : 100%;
    }

    #listingObjetSacOuvrierObjet {
        padding : 5px;
        width   : 75%;
    }


}

.itemSacOutilsExpedition {
    align-content    : center;
    align-items      : center;
    background-image : url(../images/iconHordes/vide.svg);
    display          : flex;
    font-size        : 0.9em;
    gap              : 2px;
    height           : 16px;
    justify-content  : flex-end;
    margin           : 3px 1px;
    width            : 25px;
}

#zone_choix_jour {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

#popUpConsigne {
    align-items      : center;
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    gap              : 10px;
    justify-content  : center;
    left             : 50%; /* positionnement au milieu de l'écran */
    min-height       : 160px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 700px;
    z-index          : 1110;


    #consigneZone {
        width : 90%;
    }

    #boutonMajConsigne {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 50px;
        justify-content : center;
        width           : 100%;
    }


}

#zone_consigne_ouvrier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : space-between;
    padding-left    : 5px;
    padding-right   : 5px;

}

#total_place_expedition {
    align-content   : center;
    align-items     : center;
    display         : flex;
    font-weight     : bold;
    gap             : 2px;
    justify-content : flex-start;
}

#statut_ouverture_ouvrier {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.help_compteurs {
    li {
        text-align : left;
        width      : 220px;
    }
}

.help_compteurs_zone {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

table.compteur_metier_expedition {
    margin : auto;
    width  : 80%;

    tbody tr:nth-child(odd) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(even) {
        background-color : var(--primary-row-color);
    }

    thead {
        background-color : var(--tertiary-color);
    }

    tr, td, th {
        border     : 1px solid var(--primary-border-color);
        text-align : center;
    }

    th > div {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;
    }

    .cols_compteur {
        width : 10%;
    }

    td.cols_compteur {
        background-color : white;
        color            : black;
    }
}

#zone_phrase_prefaite {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    gap             : 5px;
    justify-content : center;
    margin-top      : 10px;
}


.appercu-expeditions, #infoExpedition {
    align-items      : center;
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    flex-direction   : column;
    font-size        : 12px;
    gap              : 10px;
    justify-content  : flex-start;
    left             : 50%; /* positionnement au milieu de l'écran */
    max-height       : 500px;
    min-height       : 160px;
    overflow-y       : auto;
    padding          : 10px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 850px;
    z-index          : 1110;

    div.zone_expedition_expe_part {
        width : 100%;

        table.inscription_expedition {
            background-color : var(--secondary-color);
            border           : 3px solid var(--primary-border-color);
            width            : 100%;

            td, tr, th {
                border     : 1px solid var(--primary-border-color);
                text-align : center;
            }

            thead {
                background-color : var(--tertiary-color);
            }

            tbody tr.ligne_citoyen_user:nth-child(odd) {
                background-color : var(--primary-row-color);
            }

            tbody tr.ligne_citoyen_user:nth-child(even) {
                background-color : var(--secondary-row-color);
            }

            tbody th {
                background-color : var(--tertiary-color);
            }

            .ligne_inscription_expedition {
                height : 20px;
            }

            .cols40_expedition {
                text-align : center;
                width      : 50px;
            }

            .pseudo_expedition {
                width : 150px;

                .pseudo_expedition_liste {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    gap             : 5px;
                    justify-content : center;
                }
            }

            .sac_expedition {
                width : 200px;
            }

            .ah_expedition {
                width : 80px;
            }

            .zone_td_display {
                align-content   : center;
                align-items     : center;
                display         : flex;
                justify-content : center;
            }

            div.zone_sac_expeditionnaire {
                margin : 2px;

                > .sac_expeditionnaire {
                    align-content         : center;
                    align-items           : center;
                    display               : grid;
                    grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
                    justify-content       : center;
                    justify-items         : center;
                    padding-left          : 5px;
                }
            }

            .consigne_zone_checkbox > div {
                align-items     : center;
                display         : flex;
                gap             : 5px;
                justify-content : flex-start;
                padding-left    : 5px;
            }

            .text_consigne_zone_consigne {
                margin     : 0;
                text-align : left;

                p {
                    margin : 0;
                }
            }

            .description_expedition_part {
                padding-left : 5px;
                text-align   : start;

                > p {
                    margin : 0;
                }
            }

            .pdc_zone_tab > div {
                align-items     : center;
                display         : flex;
                font-size       : 19px;
                gap             : 5px;
                justify-content : center;
            }

            .expedition_titre {
                align-items     : center;
                display         : flex;
                gap             : 5px;
                justify-content : center;
            }

            .preinscrit_expeditions {
                align-content   : center;
                align-items     : center;
                display         : flex;
                gap             : 10px;
                justify-content : center;

                .preinscrit {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    justify-content : center;

                    > span {
                        align-content   : center;
                        align-items     : center;
                        display         : flex;
                        gap             : 5px;
                        justify-content : center;
                    }
                }
            }


        }

    }
}

#retourControleSac {
    color      : var(--error-color);
    text-align : center;
}

#zoneSacRapideOuvrier, #zoneSacRapideExpeditionnaire {
    align-content   : center;
    align-items     : center;
    display         : flex;
    font-size       : 12px;
    gap             : 5px;
    justify-content : center;

    > select {
        width : 140px;
    }
}

#zoneGenerationExpeditionList {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    margin-bottom   : 5px;
    margin-top      : 10px;
}

#zoneListCitoyensExpedition {
    color : black;
    width : 100%;

    .zoneText {
        background-color : white;
        border           : 1px solid var(--primary-font-color);
        font-size        : 12px;
        margin           : 5px auto;
        min-height       : 100px;
        width            : 40%;
    }
}

#modal_ducplicate_day {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    height          : 50px;
    justify-content : center;
}

.listingItem_categorie {
    border : 1px solid var(--primary-border-color);
}

