html[data-theme='hordes'] {
    --stats-bg-color       : #252525FF;
    --button-bg-img        : none;
    --entete-bg-img        : url(../img/MapAndCompass3.png) no-repeat;
    --html-bg-img          : url(../img/topography02hordes1.jpg);
    --primary-bg-img       : none;
    --map-bg-img-option    : url(../img/desert3.jpg);
    --gold-border-color    : #ffd700;
    --primary-border-color : #DDDDDDFF;
    --menu-button-bg-img   : none;
    --alert-color          : #FF6347FF;
    --background-color     : #00000000;
    --error-color          : #ff0000FF;
    --myLine-color         : rgba(35, 0, 180, 0.3);
    --primary-color        : #5C2B20FF;
    --secondary-color      : #7E4D2AFF;
    --shadow-color         : none;
    --special-color        : #47271CFF;
    --success-color        : #00f300FF;
    --tertiary-color       : #47271CFF;
    --hover-font-color     : #FFFFFFFF;
    --primary-font-color   : #FFFFFFFF;
    --tertiary-font-color  : #eba475FF;
    --primary-font-family  : Arial, Verdana, Helvetica;
    --title-font-family    : edo_szregular, serif;
    --primary-row-color    : #653A26FF;
    --secondary-row-color  : #8A5432FF;
    --radius-value         : 0px;
    --desktop-width        : 1300px;
}
