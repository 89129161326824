.nav-item {
    .nav-link {
        border           : 1px solid var(--primary-border-color);
        cursor           : pointer;
        background-color : var(--secondary-color);

        &:hover {
            background-color : var(--special-color);
            color            : var(--hover-font-color);
        }
    }
}

/* Surcharge pagination */
.page-link {
    text-decoration : none;
}

/* Surcharge modal */
.modal-footer {
    align-items     : center;
    justify-content : center;
    padding         : 0;
}

.modal-header {
    justify-content : center;
}

.modal-body a {
    color           : var(--primary-font-color);
    text-decoration : underline;
}

/* Surcharge des cards dans le menu */
#menuSite {
    .card {
        border        : var(--bs-card-border-width) solid var(--primary-border-color);
        border-radius : 0;
        padding       : 0;
    }

    .card-header {
        padding : 0;
    }
}
