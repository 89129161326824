.jumpCorps form {
    width : 100%;
}


.ligneGestionnaireJump {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 250px;
}

.ligneLeadJump {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 150px;
}

#gestion_leadJump, #gestion_apprentiLeadJump {
    align-content   : flex-start;
    align-items     : flex-start;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-around;
    padding         : 5px;

    fieldset {
        min-height : 75px;
        padding    : 3px;

        > legend {
            font-size : 0.8rem;
        }
    }
}

.leadGestionJump {
    border : 1px solid var(--primary-border-color);
    width  : 150px;
}

#gestionCandidature {
    border        : 1px solid var(--primary-border-color);
    border-radius : 10px;
    margin        : 10px auto auto;
    padding       : 5px;
    width         : 1000px;

    h3 {
        margin          : 0;
        text-align      : center;
        text-decoration : underline var(--primary-border-color);
        width           : 100%;
    }

    h4 {
        margin          : 0;
        text-align      : left;
        text-decoration : underline var(--primary-border-color);
        width           : 100%;
    }

    > fieldset {
        margin-bottom : 7px;
        margin-top    : 7px;
    }
}


#formGestion, #listJoueur, #listJump, #listLog {

    border        : 1px solid var(--primary-border-color);
    border-radius : 10px;
    margin        : auto auto 5px;
    padding       : 5px;
    width         : 1000px;

    form > div {
        margin : 4px;
    }

    h3 {
        margin          : 0;
        text-align      : center;
        text-decoration : underline var(--primary-border-color);
        width           : 100%;
    }

    table {
        width : 100%;
    }

    > fieldset {
        margin-bottom : 7px;
        margin-top    : 7px;
    }
}

#listingCandidatureJump {
    display               : grid;
    grid-template-columns : 1fr 1fr;
}

.blocCandidatureJump {
    min-height : 100px;
    width      : 460px;
}

.zoneBlocCandidatureJump {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-around;
}

.candidatureJump {
    align-content    : center;
    align-items      : center;
    background-color : var(--tertiary-color);
    border           : solid 1px var(--primary-border-color);
    border-radius    : 4px;
    cursor           : pointer;
    display          : flex;
    justify-content  : flex-start;
    width            : 140px;
}

.avatarCandidature {
    background-color : var(--primary-border-color);
    height           : 50px;
    width            : 50px;

    img {
        height : 100%;
        width  : 100%;
    }
}


.pseudoCandidature {
    font-size     : 0.9rem;
    text-align    : left;
    text-overflow : clip;
    width         : 180px;

    a {
        color           : var(--primary-font-color);
        text-decoration : none;
    }

    .infoBulle:hover {
        position : absolute;
        z-index  : 10;

        > .info {
            width : 400px;
        }
    }
}

.groupJump {
    background-color : var(--secondary-row-color);
    border           : 1px solid var(--primary-border-color);
    margin           : auto auto 5px;
    width            : 1200px;
}

#banniereEvent, #banniereJump {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;
}

.listingInscription {

    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : center;
    margin          : 10px;

}

.candidatureEvent {
    border  : 1px solid var(--primary-border-color);
    display : flex;
    height  : 60px;
    width   : 200px;

    .imageCandidature {

        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;
        width           : 50px;

        img {
            height : 40px;
            width  : 40px;
        }
    }


}

.zonePseudoMetierStatut {
    align-content   : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    padding         : 4px;
    width           : 150px;

    .pseudoAndMetierCandidatureEvent {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-between;

        .listMetierCandidatureEvent {
            max-width : 60px;
        }
    }

    .statutCandidatureEvent {
        font-size : 0.7rem;
    }
}

.zoneAvatarPseudoCandidature {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : flex-start;
    width           : 100%;

    .avatarCandidature {
        margin : 0;
        width  : 50px;
    }

    .zonePseudoCandidature {
        align-content   : center;
        align-items     : flex-start;
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        margin          : 0;
        padding         : 3px;
        width           : 160px;

    }

    .zoneActionCandidature {
        margin : 0;
        width  : 90px;
    }
}

table#listingCandidatureTable {
    tr {
        font-size : 0.8rem;
        width     : 100%;
    }

    .col-candidaturePseudo {
        width : 300px;
    }

    .col-candidatureBlocNote {
        width : 350px;
    }

    .col-candidatureDateCrea, .col-candidatureDateMod {
        width : 100px;
    }

    .col-candidatureStatut {
        div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : center;


            div.bordureSelectStatutGestionJump {
                height : 20px;
                width  : 120px;

                select {
                    height : 20px;
                    width  : 120px;
                }
            }
        }

    }

}

#recapJumpInscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    flex-wrap       : nowrap;
    justify-content : center;
}

#filtreFormCandidature {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-between;
    width           : 100%;
}

.listStatutFiltre {
    display : flex;
    width   : 80%;

    span {
        display : inline-block;
    }
}

.compteurCandidature {
    margin-bottom : 10px;

    table {
        width : 100%;
    }
}

#popUpErreurGestion {
    height : 130px;
    width  : 400px;
}

#contenuTextlistJoueur {
    background-color : white;
    border           : 1px solid var(--primary-border-color);
    color            : black;
    font-size        : 0.8em;
    margin-bottom    : 4px;
    min-height       : 60px;
    padding          : 5px;
    width            : 100%;
}

#generationListJoueur {
    margin-bottom : 4px;
}

.gestion_blocNote_candidature {
    background-color : white;
    color            : black;
    margin           : 2px;
    padding          : 1px;
    text-align       : justify;
}

#titre_candidature {
    font-size : 1.5rem;
}

#listLog {
    font-size : 12px;
}

.td_log_date {
    width : 100px;
}

.td_log_user {
    width : 100px;
}

.td_log_valeur_av {
    width : 200px;
}

.td_log_valeur_ap {
    width : 200px;
}

#zone-btn-extraction {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
    margin          : 5px 0;
}

#active_jump, #archive_jump {
    width : 100%;

    div.container-tabs {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        padding-top      : 15px;
        width            : 1220px;
    }

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);

            tr {
                height : 35px;
            }
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

    }
}