#popUpConfirmationRepa {
    height : 150px;
    width  : 450px;
}

#formRepa {
    margin : 0;
    width  : 724px;
}

#textRepa {
    margin : 0 0 0 15px;
    width  : 38%;
}

#aucuneRepa {
    margin : 40px auto;
}

#zoneReparation {
    display : flex;
    margin  : auto;
    width   : 99%;
}


#contenuTextRepa {
    background-color : white;
    border           : 1px solid var(--primary-border-color);
    color            : black;
    font-size        : 0.8em;
    min-height       : 300px;
    padding          : 5px;
    width            : 100%;
}

#formRepa table {
    background-color : var(--secondary-color);
}

#formRepa table thead {
    background-color : var(--tertiary-color);
}

#formRepa table, #formRepa table tr, #formRepa table tr td, #formRepa table tr th {

    border          : 1px solid var(--primary-border-color);
    border-collapse : collapse;
    font-size       : 1rem;
}

#formRepa table {
    margin : auto;

    tbody tr:nth-child(even) {
        background-color : var(--secondary-row-color);
    }

    tbody tr:nth-child(odd) {
        background-color : var(--primary-row-color);
    }

}

#formRepa table td {
    text-align : center;
}

#zoneBoutonRepa {
    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        gap             : 5px;
        justify-content : center;
        margin          : 5px;
    }
}

#boutFormRepa {
    display         : flex;
    justify-content : flex-end;
    margin-right    : 11px;
}

#ligneChoixTwoStep {
    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;
    }

    label {
        font-size : 0.8rem;
    }
}

#phraseMajRepa p {
    display   : inline-block;
    float     : right;
    font-size : 0.7em;
}

#phraseMajRepa p strong {
    font-weight     : bold;
    text-decoration : underline;
}

.repa_name {
    width : 190px;
}

.repa_def_actu {
    width : 65px;
}

.repa_def_max, .repa_def_min, .repa_pv_max, .repa_pv_min {
    width : 30px;
}

.repa_pv_actu {
    width : 50px;
}

.repa_ratio {
    width : 50px;
}

.repa_gain {
    width : 40px;
}

.repa_cout {
    width : 45px;

    input {
        width : 35px;
    }
}

.repa_choix {
    width : 115px;
}

.iconNomChantierRepa {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 100%;
}