html[data-theme='vintage'] {
    --stats-bg-color       : #fffFFFFF;
    --button-bg-img        : url(../img/bouton.png) repeat;
    --entete-bg-img        : url(../img/MapAndCompass3.png) no-repeat;
    --html-bg-img          : url(../img/fond2.jpg);
    --primary-bg-img       : url(../img/Fondcorps_corps7.jpg);
    --map-bg-img-option    : url(../img/desert3.jpg);
    --gold-border-color    : #ffd700;
    --primary-border-color : rgba(0, 0, 0, 0.5);
    --menu-button-bg-img   : url(../img/bouton.png) repeat;
    --alert-color          : #FF6347FF;
    --background-color     : #00000000;
    --error-color          : #ff0000FF;
    --myLine-color         : #FFA4004C;
    --primary-color        : #fff2d4FF;
    --secondary-color      : #fff2d4FF;
    --shadow-color         : none;
    --special-color        : #ffffffFF;
    --success-color        : #198754FF;
    --tertiary-color       : #fbc379FF;
    --hover-font-color     : #000000FF;
    --primary-font-color   : #000000FF;
    --tertiary-font-color  : #000000FF;
    --primary-font-family  : Arial, Verdana, Helvetica;
    --title-font-family    : edo_szregular, serif;
    --primary-row-color    : #ffd991FF;
    --secondary-row-color  : #fff2d4FF;
    --radius-value         : 0px;
    --desktop-width        : 1300px;
}

