.RaLayout-content {
    padding-left  : 0 !important;
    padding-right : 0 !important;
}

.MuiPaper-root.RaSidebar-paper {
    width : 150px !important;
}

.RaSidebar-fixed {
    position : absolute !important;
    width    : 150px !important;
}

.RaMenu-open {
    width : 150px !important;
}