.espacePerso {
    margin     : auto;
    text-align : justify;
    width      : 90%;
}

#info_gestion_perso {
    display       : flex;
    margin-bottom : 4px;
    width         : 100%;
}

#zone_option {

    font-size : 14px;
    margin    : auto;
    width     : 100%;

    table {
        margin : auto;
    }


    > ul > li {
        cursor      : pointer;
        float       : left;
        line-height : 26px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0; /* ni extérieure */
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
        width       : 150px;

        > button {
            width : 150px;
        }
    }

    > div {
        border-bottom : 2px solid var(--primary-border-color);
        border-left   : 2px solid var(--primary-border-color);
        border-right  : 2px solid var(--primary-border-color);
    }

}


#zone_perso, #zone_carte, #zone_opt_jump, #zone_opt_expe, #zone_menu {
    background-color : var(--secondary-color);
    width            : 100%;

    h2 {
        margin : 5px 0;
    }

    h3 {
        margin          : 5px 0;
        text-decoration : underline var(--primary-font-color);
    }
}

#zonePerso, #zoneCarte, #zoneoOptJump, #zoneoOptExpe, #zoneMenu {
    display        : flex;
    flex-direction : column;
    gap            : 10px;
    padding        : 5px;
}


.boutonMenuOptionPerso {
    background-color        : var(--secondary-color);
    border-bottom           : 2px solid var(--primary-border-color);
    border-left             : 2px solid var(--primary-border-color);
    border-right            : 2px solid var(--primary-border-color);
    border-top              : 2px solid var(--primary-border-color);
    border-top-left-radius  : 5px;
    border-top-right-radius : 5px;
    margin-bottom           : -2px;
    width                   : 100px;

}

.selectedOngletOP {
    background-color : var(--secondary-color);
    border-bottom    : 2px solid var(--secondary-color);
}


#bordureOptionPerso, #bordureOptionPersoRepar1, #bordureOptionPersoRepar2 {
    border-top : 2px solid var(--primary-border-color);
    display    : inline-block;
}

#bordureOptionPerso {
    width : 975px;
}


.advertOption {
    margin  : 3px 0 0;
    padding : 6px;
}

#optionCouleurCarte {
    display : flex;
    width   : 100%;
}

#zonePrevisu {
    align-items    : center;
    display        : flex;
    flex-direction : column;
    gap            : 5px;
    width          : 525px;
}

#miniCartePrevisu {
    align-items     : center;
    display         : flex;
    justify-content : center;

    .detailCase {
        display : none;
    }
}

#miniTabExpePrevisu {
    margin-top : 5px;
    width      : 100%;

    table#trace_carte {
        border     : solid 1px var(--primary-border-color);
        margin     : auto;
        text-align : center;

        thead {
            background-color : var(--tertiary-color);
        }

        tr, td, th {
            border     : solid 1px var(--primary-border-color);
            text-align : center;
        }

        .list_couleur_trace {
            border        : solid 1px var(--primary-border-color);
            border-radius : 3px;
            display       : inline-block;
            height        : 15px;
            margin-right  : 10px;
            width         : 15px;
        }

        td.nom_coul_tb_trace {
            width : 270px;
        }

        td.nom_coul_tb_trace div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-between;
            width           : 100%;
        }

        td.jour_tb_trace, .pa_tb_trace {
            width : 30px;
        }


    }
}

#choixCouleurCarte {
    display        : flex;
    flex-direction : column;
    width          : 50%;

    span:not(.option_specifique) {
        display : inline-block;
        width   : 100%;

        label {
            display : inline-block;
        }
    }
}

#gestionCouleurCarte h2, #gestionDispoCreneau h2 {
    margin : 5px 0 2px;
}

#gestionCouleurCarte p:not(.optionPerso_infoSelection), #gestionDispoCreneau p {
    font-size  : 0.8rem;
    font-style : italic;
    margin     : 0 0 2px;
}

.optionPerso_infoSelection {
    font-size       : 1rem;
    margin          : 0 0 2px;
    text-decoration : underline;
}

.intituleDispoOptionPerso {
    margin-bottom   : 2px;
    text-align      : center;
    text-decoration : solid var(--primary-border-color);
    width           : 100%;
}

.ligneDispoOptionPerso {
    display               : grid;
    grid-template-columns : 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.colonneDispoOptionPerso {
    align-content   : center;
    align-items     : center;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 134px;

    select {
        margin : 5px;
        width  : 125px;
    }

    div {
        align-content    : center;
        align-items      : center;
        background-color : var(--tertiary-color);
        border-bottom    : 1px solid var(--primary-border-color);
        display          : flex;
        height           : 40px;
        justify-content  : center;
        width            : 100%;

        span {
            display    : inline-block;
            text-align : center;
            width      : 100%;
        }
    }
}

.zoneBoutonGestionOptionPerso {
    align-content         : center;
    align-items           : start;
    display               : grid;
    grid-template-columns : 2fr 1fr;
    justify-content       : center;
    justify-items         : stretch;
}

#option_date_maj {
    font-size  : 0.8rem;
    text-align : end;
}

#option_bouton_gestion {
    align-content         : center;
    align-items           : center;
    display               : grid;
    grid-template-columns : 1fr 1fr;
    justify-content       : center;
    justify-items         : center;
}

#import_export_couleur {
    margin : 10px auto auto;

    #zone_bouton_export {
        align-content         : center;
        align-items           : center;
        display               : grid;
        gap                   : 10px;
        grid-template-columns : 1fr 1fr;
        justify-content       : center;
        justify-items         : stretch;
        margin                : 5px 0;
    }

    #zone_text_import_export {

        textarea {
            height : 100px;
        }
    }
}

#export_couleur_perso, #import_couleur_perso {
    white-space : normal;
    word-wrap   : break-word;
}


.option_perso_form {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

#regroupement_charge_op {
    input {
        width : 45px;
    }
}
#regroupement_nb_comp {
    input {
        width : 45px;
    }
}

#groupement_form_option_perso, #groupement_theme_option_perso, #groupement_citoyen_option_perso, #groupement_form_gestion_affichage {
    align-content   : center;
    align-items     : center;
    display         : grid;
    justify-content : center;
    justify-items   : start;
}

#groupement_form_option_perso, #groupement_theme_option_perso, #groupement_form_gestion_affichage {
    grid-template-columns : 1fr 1fr;
}

#groupement_citoyen_option_perso {
    grid-template-columns : 1fr;
}

#groupe_fuseau {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
    margin-top      : 5px;
}

#option_messageAvertissement {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
    margin          : 0;
    text-align      : center;
    width           : 100%;
}

.gestion_option_couleur_carte {
    align-content         : center;
    align-items           : center;
    display               : grid;
    grid-template-columns : 6fr 1fr;
    justify-content       : center;
    justify-items         : stretch;
}

#option_couleur_carte_choix {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
}

#option_gestion_carte_alter {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;

    > div:not(.colorMapVisu) {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        height          : 30px;
        justify-content : center;

        > span {
            display : flex;
        }
    }
}

#option_gestion_carte_estim {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    margin-bottom   : 10px;

    > div {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        height          : 30px;
        justify-content : center;

        > span {
            display : flex;
        }
    }
}

#gestion_option_ruine {
    align-items           : center;
    display               : grid;
    grid-template-columns : 1fr 1fr;
    justify-content       : center;
    justify-items         : start;
    margin-bottom         : 10px;

    > div {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        height          : 30px;
        justify-content : center;

        > span {
            display : flex;
        }
    }
}

#gestion_option_exped_gen {
    align-items           : center;
    display               : grid;
    grid-template-columns : 1fr 1fr;
    justify-content       : center;
    justify-items         : start;
    margin-bottom         : 10px;

    > div {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        height          : 30px;
        justify-content : center;

        > span {
            display : flex;
        }
    }
}

#gestion_option_exped {
    align-items           : center;
    display               : grid;
    grid-template-columns : 1fr 1fr;
    justify-content       : center;
    justify-items         : start;
    margin-bottom         : 10px;

    > div {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        height          : 30px;
        justify-content : center;

        > span {
            display : flex;
        }
    }
}

#gestion_appercu_exped {
    align-items           : center;
    display               : flex;
    gap                   : 20px;
    grid-template-columns : 1fr 1fr 1fr;
    justify-content       : flex-start;
    justify-items         : start;
    margin-bottom         : 10px;

    > div {
        align-items     : center;
        border-radius   : 5px;
        display         : flex;
        font-weight     : bold;
        gap             : 5px;
        height          : 30px;
        justify-content : center;
        text-align      : center;
        width           : 40px;

        > span {
            display : flex;
        }
    }
}

#groupement_theme_gestion_option_perso {
    align-content         : center;
    align-items           : start;
    display               : grid;
    grid-template-columns : 1fr 3fr;
    justify-content       : center;
    justify-items         : center;
    margin-bottom         : 10px;

    > div {
        align-items     : center;
        display         : flex;
        gap             : 5px;
        height          : 30px;
        justify-content : center;

        > span {
            display : flex;
        }
    }
}

#gestion_theme_user_option_perso {
    align-content         : center;
    align-items           : start;
    display               : grid;
    grid-template-columns : 2fr 7fr;
    justify-content       : center;
    justify-items         : center;
    margin-bottom         : 10px;

    > div {
        width : 100%;
    }
}

#panel_gestion_couleur_theme_user {
    border-left : 1px solid var(--primary-border-color);
}

#group_gestion_couleur_appercu {
    align-content         : center;
    align-items           : start;
    display               : grid;
    grid-template-columns : 2fr 3fr;
    justify-content       : center;
    justify-items         : center;
    margin-bottom         : 10px;
}

.appercu_site_bg_img_dark, .appercu_site_bg_img_light, .appercu_site_bg_img_hordes, .appercu_site_bg_img_perso, .appercu_site_bg_img_vintage {
    background-size : 100%;
    border          : 1px solid var(--primary-border-color);
    height          : 650px;
    margin          : 0;
    width           : 100%;

    #appercu_site_background {
        background-size : 100%;
        height          : 650px;
        margin          : 0;
        width           : 100%;
    }
}

.appercu_site_bg_img_dark {
    background : url(../img/topography02dark1.jpg);
}

.appercu_site_bg_img_light {
    background : url(../img/topography02c.jpg);
}

.appercu_site_bg_img_vintage {
    background : url(../img/Fondcorps_corps7.jpg);
}

.appercu_site_bg_img_hordes {
    background : url(../img/topography02hordes1.jpg);
}

.appercu_site_bg_img_perso {
    background : url(../img/topography02perso1.jpg);
}

#zone_appercu_site {
    width : 100%;
}

#appercu_site_primary {
    height : 94%;
    margin : 15px auto 15px auto;
    width  : 90%;
}

#appercu_site_secondary {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    height          : 93%;
    justify-content : center;
    margin          : 20px auto 20px auto;
    width           : 90%;

    p {
        font-family : edo_szregular, serif;
        font-size   : 1.3em;
        margin      : 0 auto;
        position    : relative;
        width       : 80%;
    }
}

#listing_theme_user {
    display         : flex;
    flex-direction  : column;
    gap             : 5px;
    justify-content : flex-start;
}

.theme_user_ligne {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.theme_user_ligne_name {
    width : 70%;
}

#appercu_site_myline, #appercu_site_stats {
    height : 40px;
    margin : auto;
    width  : 90%;

    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        height          : 100%;
        justify-content : center;
        margin          : 0;
        padding-left    : 3%;
        width           : 97%;
    }
}

#appercu_site_hover {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 40px;
    justify-content : center;
    margin          : auto;
    width           : 90%;


    > div {
        align-content   : center;
        align-items     : center;
        border-radius   : 6px;
        display         : flex;
        height          : 100%;
        justify-content : center;
        margin          : 0;
        width           : 33%;
    }
}

#appercu_site_stats {
    height : 240px;
}

#appercu_site_myline > div {
    > div {
        height : 30px;
        margin : auto;
        width  : 30px;
    }
}

.group_label_couleur_gestion_theme_user {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
}

#zone_gestion_couleur_pour_appercu {
    align-content   : flex-start;
    align-items     : flex-start;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : flex-start;
    padding-left    : 10px;
    width           : 97%;

    > div {
        align-items     : flex-start;
        display         : flex;
        flex-direction  : column;
        justify-content : flex-start;
    }
}

#zone_action_theme_user_listing_option_perso {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    margin          : 10px;
}

#zone_group_action_theme_user_gestion {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
    margin          : 10px;
}

#group-btn-theme {
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

.theme_user_ligne_bouton_action {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

#dispo_type_group_gestion_option {
    align-content   : center;
    align-items     : flex-start;
    display         : flex;
    justify-content : center;
    width           : 100%;
}

#dispo_type_list_gestion_option {
    align-content   : center;
    align-items     : flex-start;
    display         : flex;
    justify-content : center;
    width           : 32%;

    fieldset {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 10px;
        justify-content : center;
        width           : 100%;
    }
}

#dispo_type_form_gestion_option {
    align-content   : center;
    align-items     : flex-start;
    display         : flex;
    justify-content : center;
    width           : 70%;

    > fieldset {
        display        : flex;
        flex-direction : column;
        gap            : 10px;
        width          : 100%;

        > div:not(#zone-button) {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 10px;
            justify-content : flex-start;
        }

        #zone-button {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 10px;
            justify-content : center;
        }

        > div > table {
            width : 100%;

            tr, td, th {
                border : 1px solid var(--primary-border-color);
            }

            th {
                background-color : var(--tertiary-color);
                text-align       : center;
            }

            .cols_dispo {
                width : 9%;

                > .zone_td_display {
                    align-content   : center;
                    align-items     : center;
                    display         : flex;
                    justify-content : center;
                }
            }
        }
    }
}

table#table_dispo_type_option {
    width : 300px;

    tr, td, th {
        border : 1px solid var(--primary-border-color);
    }

    th {
        background-color : var(--tertiary-color);
        text-align       : center;
    }

    .cols_name {
        width : 75%;
    }

    .cols_action {
        width : 25%;

        > div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            gap             : 10px;
            justify-content : center;
        }
    }
}

div.option_expedition_expe_part {
    width : 400px;

    table.inscription_expedition {
        background-color : var(--secondary-color);
        border           : 3px solid var(--primary-border-color);
        width            : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
        }

        tbody tr.ligne_citoyen_user:nth-child(odd) {
            background-color : var(--primary-row-color);
        }

        tbody tr.ligne_citoyen_user:nth-child(even) {
            background-color : var(--secondary-row-color);
        }

        tbody th {
            background-color : var(--tertiary-color);
        }

        .ligne_inscription_expedition {
            height : 20px;
        }

        .cols40_expedition {
            text-align : center;
            width      : 50px;
        }

        .pseudo_expedition {
            width : 150px;

            .pseudo_expedition_liste {
                align-content   : center;
                align-items     : center;
                display         : flex;
                gap             : 5px;
                justify-content : center;
            }
        }

        .sac_expedition {
            width : 200px;
        }

        div.zone_sac_expeditionnaire {
            margin : 2px;

            > .sac_expeditionnaire {
                align-content         : center;
                align-items           : center;
                display               : grid;
                grid-template-columns : 1fr 1fr 1fr 1fr 1fr;
                justify-content       : center;
                justify-items         : center;
                padding-left          : 5px;
            }
        }
    }
}

#option_gestion_resizable_carte {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;
}

#option_resizable_carte {
    display         : flex;
    justify-content : center;
    position        : relative;
    width           : 100%;
    z-index         : 101;
}

#option_form_resizable_carte {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;
    width           : 300px;
}

#option_show_apercu_carte_btn {
    position : relative;
    z-index  : 101;
}

#zoneApercuTaille {
    position : relative;
    z-index  : 101;

    & div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 10px;
        justify-content : center;
    }
}

#option_apercu_taille_carte {
    align-items      : center;
    background-color : rgba(0, 0, 0, 0.75);
    display          : flex;
    flex-direction   : column;
    gap              : 10px;
    justify-content  : center;
    left             : 0;
    padding-bottom   : 20px;
    padding-top      : 10px;
    position         : absolute;
    top              : 60px;
    width            : 100%;
    z-index          : 100;
}

#option_apercu_taille_carte_horizontal {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
}

#option_apercu_taille_carte_vertical {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : flex-start;
}

#option_resizable_carte_range {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    position        : relative;
    width           : 100%;
    z-index         : 101;

    > span {
        width : 100%;

        > input {
            width : 100%;
        }
    }
}