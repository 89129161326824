#chantier_form_prototype {
    align-content         : center;
    align-items           : center;
    display               : grid;
    gap                   : 10px;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    justify-content       : center;
    justify-items         : center;
}

#chantier_form_prototype_ressources {
    align-content         : center;
    align-items           : center;
    display               : grid;
    gap                   : 10px;
    grid-template-columns : 1fr;
    justify-content       : center;
    justify-items         : center;
}

.admin_ressource_chantier ul {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 10px;
    justify-content : center;
}

.admin_groupe_level {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}


.arbre_chantier {

    align-items     : flex-start;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    min-width       : 310px;

    .root {
        width : 450px;
    }

    .header_chantier {
        align-items     : center;
        display         : flex;
        height          : 35px;
        justify-content : space-between;
        width           : 300px;
    }

    .chantier_nom.lv-1 > span {
        display : inline-block;
        width   : 16px;
    }

    .chantier_nom.lv-2 > span {
        display : inline-block;
        width   : 32px;
    }

    .chantier_nom.lv-3 > span {
        display : inline-block;
        width   : 48px;
    }

    .chantier_nom.lv-4 > span {
        display : inline-block;
        width   : 64px;
    }

    .chantier_nom.lv-5 > span {
        display : inline-block;
        width   : 80px;
    }

    .chantier_nom.lv-6 > span {
        display : inline-block;
        width   : 96px;
    }

    .chantier_nom {
        align-items : center;
        display     : flex;
        width       : 300px;
    }

    .childrenline {
        align-content : center;
        align-items   : flex-start;
        display       : flex;
    }

    .children-group-btn {
        align-items : center;
        display     : flex;
        height      : 35px;
    }
}