#encyclopedie_objet {
    margin : auto;
    width  : 1200px;

    > ul > li {
        cursor           : pointer;
        float            : left;
        line-height      : 26px; /* on définit une hauteur pour chaque élément */
        list-style       : none; /* on supprime le style par défaut de la liste */
        margin           : 0; /* ni extérieure */
        padding          : 0; /* Pas de marge intérieure */
        text-align       : center; /* on centre le texte qui se trouve dans la liste */
        width            : 200px;

        > button {
            width : 200px;
        }

    }
}

#ency_items {
    width : 100%;

    div.container-tabs {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        padding-top      : 15px;
        width            : 1200px;
    }

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);

            tr {
                height : 35px;
            }
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_items_icone {
            font-size : 0.8rem;
            width     : 45px;
        }

        td.tab_ency_items_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_items_nom {
            width : 140px;
        }

        td.tab_ency_items_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_items_cat {
            width : 120px;
        }

        td.tab_ency_items_cat {
            padding    : 4px;
            text-align : center;
        }

        .tab_ency_items_desc {
            width : 300px;
        }

        .tab_ency_items_carac {
            width : 300px;
        }

        td.tab_ency_items_desc {
            padding    : 4px;
            text-align : justify;
        }

        .tab_ency_items_act {
            width : 300px;
        }

        td.tab_ency_items_act {
            padding    : 4px;
            text-align : justify;

            p {
                margin          : 0 0 2px;
                text-decoration : underline;
            }
        }
    }

    #entete_ency_items_name {
        width : 120px;
    }

    #entete_ency_items_categorie {
        width : 100px;
    }
}

#ency_weapons {
    border : 1px solid var(--primary_border-color);
    width  : 100%;

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 1200px;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_items_icone {
            font-size : 0.8rem;
            width     : 60px;
        }

        td.tab_ency_items_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_weapons_nom {
            width : 320px;
        }

        td.tab_ency_weapons_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_weapons_kill {
            width : 80px;

            > div {
                align-items     : center;
                display         : flex;
                justify-content : center;
            }
        }


        td.tab_ency_weapons_kill {
            padding : 4px;
        }

        .tab_ency_weapons_pct_kill {
            text-align : center;
            width      : 120px;
        }

        td.tab_ency_weapons_pct_kill {
            padding : 4px;
        }

        .tab_ency_weapons_pct_cass {
            width : 120px;
        }

        td.tab_ency_weapons_pct_cass {
            padding : 4px;
        }

        .tab_ency_weapons_recup {
            width : 180px;
        }

        td.tab_ency_weapons_recup {
            padding : 4px;

            span.weapons_recup {
                align-items     : center;
                display         : flex;
                justify-content : center;
            }
        }
    }
}

#ency_veilles {
    border : 1px solid var(--primary_border-color);
    width  : 100%;

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 1200px;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_items_icone {
            font-size : 0.8rem;
            width     : 60px;
        }

        td.tab_ency_items_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_veilles_nom {
            width : 320px;
        }

        td.tab_ency_veilles_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_veilles_def {
            width : 80px;

            > div {
                align-items     : center;
                display         : flex;
                justify-content : center;
            }
        }


        td.tab_ency_veilles_def {
            padding : 4px;
        }

        .tab_ency_veilles_armurerie, .tab_ency_veilles_magasin, .tab_ency_veilles_tourelle, .tab_ency_veilles_bete, .tab_ency_veilles_encombrant, .tab_ency_veilles_reparable, .tab_ency_veilles_unique {
            text-align : center;
            width      : 100px;
        }

        td.tab_ency_veilles_armurerie, td.tab_ency_veilles_magasin, td.tab_ency_veilles_tourelle, td.tab_ency_veilles_bete, td.tab_ency_veilles_encombrant, td.tab_ency_veilles_reparable, td.tab_ency_veilles_unique {
            padding : 4px;
        }

    }
}

#ency_decharges {
    width : 100%;

    div.container-tabs {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-border-color);
        padding-top      : 15px;
        width            : 1200px;
    }

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 1200px;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_items_icone {
            font-size : 0.8rem;
            width     : 60px;
        }

        td.tab_ency_items_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_decharges_nom {
            width : 320px;
        }

        td.tab_ency_decharges_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_decharges_def {
            width : 80px;

            > div {
                align-items     : center;
                display         : flex;
                justify-content : center;
            }
        }


        td.tab_ency_decharges_def {
            padding : 4px;
        }

        .tab_ency_decharges_bois, .tab_ency_decharges_feraille, .tab_ency_decharges_od, .tab_ency_decharges_appat, .tab_ency_decharges_arme, .tab_ency_decharges_anim {
            text-align : center;
            width      : 100px;
        }

        td.tab_ency_decharges_bois, td.tab_ency_decharges_feraille, td.tab_ency_decharges_od, td.tab_ency_decharges_appat, td.tab_ency_decharges_arme, td.tab_ency_decharges_anim {
            padding : 4px;
        }

    }
}

#ency_poubelles {
    border : 1px solid var(--primary_border-color);
    width  : 100%;

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 1200px;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_items_icone {
            font-size : 0.8rem;
            width     : 60px;
        }

        td.tab_ency_items_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_poubelles_nom {
            width : 320px;
        }

        td.tab_ency_poubelles_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_poubelles_proba {
            width : 100px;

            > div {
                align-items     : center;
                display         : flex;
                justify-content : center;
            }
        }

        td.tab_ency_poubelles_proba {
            padding : 4px;
        }
    }
}


.groupAssemblage, .groupAssemblageProba, .groupAssemblageNombre {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    justify-content : space-around;
}

.groupAssemblageProba {
    width : 84px;
}

.groupAssemblageNombre {
    width : 40px;
}

.item_proba_nombre {
    width : 60px;
}

.ligneAssemblage {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : wrap;
    justify-content : flex-start;
    margin-bottom   : 4px;
}

.itemAssemblage {
    align-content    : center;
    align-items      : center;
    background-image : url('../images/iconHordes/vide.svg');
    display          : flex;
    height           : 16px;
    justify-content  : center;
    margin           : 0;
    width            : 16px;
}


.itemPrincipale {
    border : 2px dashed blue;
}

#form_search_items {
    font-size : 0.9rem;
    margin    : auto auto 10px;
    position  : sticky;
    top       : var(--top-decalage);
    width     : 1000px;
    z-index   : 500;
}

.ency_space {
    margin-left  : 5px;
    margin-right : 5px;
}

#entete_ency_weapons_kill {
    width : 60px;
}

#entete_ency_weapons_reussite, #entete_ency_weapons_casse {
    width : 100px
}