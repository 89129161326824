.infoBulle:hover span[class~=infoInscription] {
    padding : 3px;
    width   : 250px;
    z-index : 999;

    p {
        margin : 0;
    }
}

#inscription_jump_lvlRuine_help {
    margin  : 0;
    padding : 4px;
    width   : 100%;
}

#feuilleInscription {
    border        : 1px solid var(--primary-border-color);
    border-radius : 10px;
    font-size     : 1rem;
    margin        : auto;
    width         : 85%;
}

.recapJumpInscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    flex-wrap       : nowrap;
    justify-content : center;

    > span {
        display    : inline-block;
        min-width  : 100px;
        text-align : center;
    }
}


#formInscriptionJump {
    display        : flex;
    flex-direction : column;
    gap            : 10px;
    padding-bottom : 10px;

    fieldset {
        border-radius : 10px;
    }
}

#nomJumpInscription {
    font-size   : 1.4rem;
    font-weight : bold;
}

#boutonInscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    margin          : 5px;

    button {
        font-size   : 14px;
        height      : 30px;
        line-height : 14px;
    }
}


#roleInscription > div {
    align-items     : flex-start;
    display         : flex;
    flex-direction  : column;
    justify-content : center;

    > div {
        display : flex;
        gap     : 5px;
    }

    > label {
        display : inline-block;
        width   : 180px;
    }
}

#inscription_jump_roleVille > label, #inscription_jump_apprentiLead > label, #inscription_jump_lead > label {
    margin : 2px 10px 2px 0;
}

.textAreaJumpInscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : center;
    width           : 99%;

    label {
        display : block;
        width   : 100%;
    }

    div.ck-editor {
        width : 100%;
    }

    div.ck-editor__editable_inline:not(.ck-comment__input *) {
        min-height : 100px;
    }
}


#inscription_jump_motivations {
    height : 100px;
    width  : 938px;

    > div {
        margin-bottom : 5px;
        margin-top    : 5px;
    }
}

#generalInfoInscriptionJoueur > div:not(#voeuxMetierInscription), #voeuxMetierInscription > div:not(#infoMetierInscriptionJump) {
    align-content : center;
    align-items   : center;
    display       : flex;
    flex-wrap     : wrap;
}

#generalInfoInscriptionJoueur > div {
    margin : 4px 0;
}

#voeuxMetierInscription > div > div {
    width : 33%;
}

#infoMetierInscriptionJump {
    font-size : 0.8rem;
}

#gouleBanCommuInscription {
    width : 100%;
}

#gouleBanCommuInscription > div {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
    width           : 50%;
}

#pouvoirLevlRuineInscriptionJump > div {
    width : 50%;
}

#retourInscriptionOrganisateur {
    background-color : white;
    border           : 1px solid var(--primary-border-color);
    color            : black;
    min-height       : 100px;
    width            : 100%;
}

.textAreaCandidature {
    background-color : white;
    border           : 1px solid var(--primary-border-color);
    color            : black;
    min-height       : 100px;
    overflow-wrap    : break-word;
    width            : 960px;

    p {
        margin : 2px;
    }
}

.ligneDispoInscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : center;
}

.colonneDispoInsciption {
    align-content   : center;
    align-items     : center;
    border          : 1px solid var(--primary-border-color);
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    width           : 115px;

    select {
        margin : 5px;
        width  : 105px;
    }
}

.colonneDispoInsciption div:not(.dispoCandidature) {
    background-color : var(--tertiary-color);
}

.colonneDispoInsciption div {
    align-content   : center;
    align-items     : center;
    border-bottom   : 1px solid var(--primary-border-color);
    display         : flex;
    height          : 40px;
    justify-content : center;
    width           : 100%;

    span {
        display    : inline-block;
        text-align : center;
        width      : 100%;
    }
}

.intituleDispoInscription {
    margin-bottom   : 2px;
    text-align      : center;
    text-decoration : solid var(--primary-border-color);
    width           : 100%;
}

#titreDispoWeekendInscription {
    margin-top : 10px;
}

.errorFormInscription {
    color       : var(--error-color);
    font-weight : bold;
}

.labelCandidature {
    text-decoration : underline var(--primary-border-color);
}

#ensembleBoutonCandidature {
    display : flex;
    width   : 100%;

    button {
        height      : 52px;
        white-space : normal;
        width       : 124px;
        word-wrap   : break-word;
    }
}

.listChoixMetier_inscription {
    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-evenly;

        > div {
            width : 140px;
        }
    }

}

.listPouvoir_inscription > div {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-evenly;
}

#listLevelRuine_inscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : flex-start;
}

.inscription_bloc_text {
    background-color : white;
    color            : black;
    margin           : 2px;
    min-height       : 50px;
    padding          : 1px;
    text-align       : justify;
    width            : 99%;
}

#bloc_choix_diffusion {
    align-content : center;
    align-items   : center;
    display       : flex;
    gap           : 5px;
}

.list_lead_inscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 8px;
    justify-content : flex-start;
}

.groupement_lead_label_input {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 3px;
    justify-content : center;

    label {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 2px;
        justify-content : center;
    }

}

.valeur_historique_log_inscription {
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
}

.fuseau_inscription {
    align-content   : center;
    align-items     : center;
    display         : flex;
    gap             : 5px;
    justify-content : center;
    margin-top      : 10px;
}