.videItem {
    -webkit-align-content   : center;
    align-content           : center;
    align-items             : center;
    background-image        : url(../images/iconHordes/vide.svg);
    -webkit-box-pack        : end;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : end;
    font-size               : 14px;
    height                  : 16px;
    -webkit-justify-content : flex-end;
    justify-content         : flex-end;
    margin                  : 3px 1px;
    width                   : 50px;
    gap                     : 2px;
}

.videListing {
    -webkit-align-content   : center;
    align-content           : center;
    align-items             : center;
    -webkit-box-pack        : end;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : end;
    font-size               : 14px;
    height                  : 16px;
    -webkit-justify-content : flex-end;
    justify-content         : flex-end;
    margin                  : 3px 2px;
    width                   : 18px;
}

.videListingStockItem {
    -webkit-align-content   : center;
    align-content           : center;
    align-items             : center;
    -webkit-box-pack        : end;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : end;
    font-size               : 14px;
    gap                     : 2px;
    height                  : 16px;
    -webkit-justify-content : flex-end;
    justify-content         : flex-end;
    margin                  : 3px 2px;
    width                   : 45px;
}

.videImg {
    background-image : url(../images/iconHordes/vide.svg);
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : -webkit-flex;
    display          : flex;
    height           : 16px;
    margin           : 0;
    width            : 16px;
    /*    position: relative;
        z-index: 300;*/
}

.videImg2 {
    -webkit-align-content   : center;
    align-content           : center;
    background-image        : url(../images/iconHordes/vide.svg);
    border                  : 2px dashed var(--error-color);
    -webkit-box-pack        : center;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : center;
    height                  : 16px;
    -webkit-justify-content : center;
    justify-content         : center;
    margin                  : 0;
    width                   : 16px;
    /*    position: relative;
        z-index: 300;*/
}

.videImg3 {
    -webkit-align-content   : center;
    align-content           : center;
    background-image        : url(../images/iconHordes/vide.svg);
    border                  : 2px dashed green;
    -webkit-box-pack        : center;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : center;
    height                  : 16px;
    -webkit-justify-content : center;
    justify-content         : center;
    margin                  : 0;
    width                   : 16px;
    /*
        position: relative;
        z-index: 300;
    */
}

.videImg4 {
    -webkit-align-content   : center;
    align-content           : center;
    background-image        : url(../images/iconHordes/vide.svg);
    border                  : 2px dashed blue;
    -webkit-box-pack        : center;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : center;
    height                  : 16px;
    -webkit-justify-content : center;
    justify-content         : center;
    margin                  : 0;
    width                   : 16px;
    /*
        position: relative;
        z-index: 300;
    */
}

.videImg5 {
    -webkit-align-content   : center;
    align-content           : center;
    background-image        : url(../images/iconHordes/vide.svg);
    border                  : 2px dashed yellow;
    -webkit-box-pack        : center;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : center;
    height                  : 16px;
    -webkit-justify-content : center;
    justify-content         : center;
    margin                  : 0;
    width                   : 16px;
    /*
        position: relative;
        z-index: 300;
    */
}

.avatar {
    border  : 2px solid black;
    display : -webkit-box;
    display : -ms-flexbox;
    display : -webkit-flex;
    display : flex;
    height  : 80px;
    margin  : 0 auto auto;
    width   : 80px;
}

.itemHordes {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-shrink     : 0; // Empêche le rétrécissement
    height          : 16px;
    justify-content : center;
    min-height      : 16px; // Ajout de min-height
    min-width       : 16px; // Ajout de min-width
    width           : 16px;
}

.itemExpe {
    display     : inline-flex;
    flex-shrink : 0; // Empêche le rétrécissement
    height      : 32px;
    min-height  : 16px; // Ajout de min-height
    min-width   : 16px; // Ajout de min-width
    width       : 32px;
}

.icon_bat {
    max-height : 100%;
    max-width  : 100%;
}


.direcDroite, .direcGauche {
    background-repeat : no-repeat;
    display           : -ms-inline-flexbox;
    display           : -webkit-inline-flex;
    display           : inline-flex;
    height            : 16px;
    margin            : 0;
    width             : 16px;
}

.direcFDroite, .direcFGauche, .direcFBas, .direcFHaut, .signMoins, .signPlus {
    background-repeat : no-repeat;
    background-size   : cover;
    display           : -ms-inline-flexbox;
    display           : -webkit-inline-flex;
    display           : inline-flex;
    height            : 32px;
    margin            : 0;
    width             : 32px;
}

.fa-1-5x {
    font-size : 1.5em;
}

.icon-green {
    color : var(--success-color);
}

.icon-red {
    color : var(--error-color);
}

.icon-disabled {
    filter  : grayscale(100%); /* Icône en niveaux de gris */
    opacity : 0.7; /* Opacité réduite */
}