.caseRuineZoom {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 274px;
    justify-content : center;
    width           : 274px;
}

.ruineZoom, .ruineZoomPerso {
    height      : 200px;
    line-height : 200px;
    margin      : 0;
    position    : absolute;
    width       : 200px;
}

.ruineZoom {
    z-index : 0;
}

.ruineZoomPerso {
    z-index : 2;
}

.ruineCarte {
    height   : 24px;
    margin   : 0;
    position : absolute;
    width    : 24px;
    z-index  : 10
}

.ruineCarteAdmin {
    height  : 24px;
    margin  : 0;
    width   : 24px;
    z-index : 10
}


.ruinePerso0Z, .ruinePerso1Z, .ruinePerso2Z, .ruinePerso3Z, .ruinePerso4Z {
    display  : -ms-inline-flexbox;
    display  : -webkit-inline-flex;
    display  : -moz-inline-box;
    display  : inline-flex;
    height   : 200px;
    margin   : 0;
    position : absolute;
    width    : 200px;
}


#zoneRuine {
    /*    height: 480px;*/
    border                 : 1px solid black;
    -moz-box-direction     : normal;
    -webkit-box-direction  : normal;
    -moz-box-orient        : vertical;
    -webkit-box-orient     : vertical;
    display                : -webkit-box;
    display                : -webkit-flex;
    display                : -moz-box;
    display                : -ms-flexbox;
    display                : flex;
    -ms-flex-direction     : column;
    -webkit-flex-direction : column;
    flex-direction         : column;
    height                 : 420px;
    margin                 : auto;
    width                  : 65%;
}

#zoneRuineCarte {
    /*    height: 85%;*/
    height : 95%;
    width  : 100%;
}

#infoCreaCarte {
    font-size : 0.8em;
    height    : 20px;
    width     : 100%;
}

#zoneRuineZoom {
    width : 274px;
}

#carteRuine {
    align-content   : center;
    align-items     : center;
    border          : 1px solid black;
    display         : flex;
    flex-direction  : column;
    justify-content : flex-start;
    width           : 460px;
}

#menuRuine {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    justify-content : flex-start;
    width           : 460px;

    > fieldset {
        width : 420px;
    }
}

#zonePorteRuine {
    border : 1px solid black;
    height : 20%;
    width  : 100%;
}

.bordCarteRuine {
    background-color : black;
    border           : 1px solid var(--primary-border-color);
    color            : white;
    font-size        : 0.9em;
    margin           : 0;
    max-height       : 24px;
    max-width        : 24px;
    min-height       : 24px;
    min-width        : 21px;
    text-align       : center;
}

.caseCarteRuine {
    background-color : rgba(255, 255, 255, 0.4);
    border           : 1px solid rgb(169, 169, 169);
    height           : 24px;
    margin           : 0;
    padding          : 0;
    position         : relative;
    width            : 24px;
}

.ruineCarte_items {
    height   : 20px;
    margin   : 0;
    padding  : 0;
    position : relative;
    width    : 20px;
    z-index  : 20;
}

.caseCarteRuineD {
    height   : 24px;
    margin   : 0;
    padding  : 0;
    position : relative;
    width    : 24px;
}

#blocHautDir, #blocGlobalDir, #blocBasDir {
    width : 100%;
}

#blocHautDir, #blocBasDir {
    height : 72px;
}

#blocGaucheDir, #blocDroiteDir {
    width : 72px;
}

#blocHautDir, #blocBasDir, #blocGaucheDir, #blocDroiteDir {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

#blocGlobalDir {
    align-content   : center;
    align-items     : center;
    display         : flex;
    height          : 130px;
    justify-content : space-between;
}

.blocCentraleImg {
    border   : 1px solid var(--primary-border-color);
    height   : 200px;
    position : absolute;
    width    : 200px;
    z-index  : 0;
}

.blocDirectionPlusMinus {
    height   : 274px;
    position : absolute;
    width    : 274px;
    z-index  : 2;
}

.ruine_bas, .ruine_droite, .ruine_gauche, .ruine_haut {
    align-content   : center;
    align-items     : center;
    cursor          : pointer;
    display         : flex;
    height          : 36px;
    justify-content : center;
    position        : relative;
    width           : 36px;
    z-index         : 20;
}


#direction_ruine_bas, #direction_ruine_haut, #plus_ruine_haut, #plus_ruine_bas {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    flex-wrap       : nowrap;
    justify-content : center;
    position        : relative;
}

#direction_ruine_gauche, #direction_ruine_droite, #plus_ruine_gauche, #plus_ruine_droite {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    flex-wrap       : nowrap;
    justify-content : space-around;
    position        : relative;
}

.ruineEntry, .ruineMasque {
    display : none;
}

.ruineCache {
    visibility : hidden;
}

#rangeNbZomb {
    -webkit-align-content : space-around;
    align-content         : space-around;
    display               : -webkit-box;
    display               : -webkit-flex;
    display               : -moz-box;
    display               : -ms-flexbox;
    display               : flex;
    width                 : 100%;
}

.nbZombie_ruineCarte {
    left       : -1px;
    position   : relative;
    text-align : center;
    width      : 20%;
}

#range_zombie {
    width : 85%;
}

#groupGestionPorte {
    -webkit-align-content : space-between;
    align-content         : space-between;
    display               : -webkit-box;
    display               : -webkit-flex;
    display               : -moz-box;
    display               : -ms-flexbox;
    display               : flex;
}

.addPorteBout, .suppPorteBout {
    /*    width: px;*/
    padding-left  : 2px;
    padding-right : 2px;
}

.ruineCarte_zomb {
    height   : 24px;
    margin   : 0;
    position : absolute;
    width    : 24px;
}

.ruineCarte_porte {
    height   : 16px;
    margin   : 4px;
    position : absolute;
    width    : 16px;
    z-index  : 5;
}

.ruineCarte_escalier {
    position : absolute;
    z-index  : 9;
}

.ruineCarte_z_0 {
    background-color : rgba(0, 140, 0, 0.3);
}

.ruineCarte_z_1 {
    background-color : rgba(255, 255, 0, 0.4);
}

.ruineCarte_z_2 {
    background-color : rgba(255, 162, 0, 0.6);
}

.ruineCarte_z_3 {
    background-color : rgba(255, 0, 0, 0.4);
}

.ruineCarte_z_4 {
    background-color : rgba(215, 0, 255, 0.4);
}


.largeIcone {
    font-size : 32px;
}

.optionCarteRuine {
    padding : 1px 1px 2px 2px;

    span.range-slider__wrap {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;

        > input {
            width : 70%;
        }
    }
}

.optionCarteRuineText {
    font-size : 0.8em;
}

#popUpRuine {
    height : 140px;
    width  : 430px;
}

#popUpRuineSuccess {
    height : 65px;
    width  : 430px;
}

.maPositionRuine {
    background-color : aqua;
    height           : 100%;
    position         : absolute;
    width            : 100%;
}

#popUpRuineSuccess div.zoneTextPopUp p {
    text-align : center;
    width      : 410px;
}

.blocRuine {
    align-content    : center;
    align-items      : center;
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    height           : 70px;
    justify-content  : flex-start;
    margin           : 4px 0;
    width            : 500px;
}

.iconRuine {
    background-color : rgb(121, 78, 34);
    height           : 62px;
    margin           : 2px;
    width            : 62px;

}

.caracRuine {
    padding : 2px;
    width   : 70%;
}

.infoPlansRuine {
    display        : flex;
    flex-direction : column;
}

.infoPlansRuine span {
    font-size : 0.9rem;
}

.boutonRuine {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    width           : 20%;
}

.boutonRuine button {
    width : 80%;
}

#planRuine {
    margin : auto;
    width  : 1200px;
}

#blocMappingRuine {
    background-color : var(--secondary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : var(--radius-value);
    display          : flex;
}

#descMappingRuine {
    width : 100%;
}

#descMappingRuine p {
    background-color : var(--tertiary-color);
    border           : 1px solid var(--primary-border-color);
    border-radius    : var(--radius-value);
    margin           : 5px 0 3px;
    text-align       : center;
}

#menuRuine p {
    margin          : 4px 0 2px;
    text-align      : center;
    text-decoration : underline black;
}

#menuRuine button {
    margin : 2px;
}

#listRuines {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-around;
    margin          : auto;
    width           : 85%;

    h2 {
        text-align : center;
    }
}

.caseFilAriane {
    color    : var(--error-color);
    position : absolute;
    z-index  : 500;
}

.filAriane {
    height   : 24px;
    position : absolute;
    width    : 24px;
}

.nomCoordRuine {
    display : flex;
    gap     : 5px;
}

.caseRuineClique {
    cursor : pointer;
}

.titre_etage_ruine {
    font-size  : 14px;
    margin     : 3px 0;
    text-align : center;
}

#btn-gest-etage {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 2px;
    justify-content : center;
}

#bloc_opt_RuineZoom {
    align-content   : center;
    display         : flex;
    flex-direction  : column;
    gap             : 5px;
    justify-content : center;
}

#ruine_mode_mapping {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;

    > div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;
    }
}

#popUpMajCaseRuine {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    left             : 50%; /* positionnement au milieu de l'écran */
    min-height       : 500px;
    position         : fixed; /* positionnement fixe pour maintenir le centrage lorsque vous faites défiler */
    top              : 50%; /* positionnement en haut de l'écran */
    transform        : translate(-50%, -50%); /* décalage pour centrer l'élément */
    width            : 900px;
    z-index          : 1110;

    #zoneInfoCaseRuine {
        padding-left : 5px;
        width        : 25%;

        h2 {
            margin     : 3px 0;
            text-align : center;
            width      : 100%;
        }

        form > p {
            margin : 0 0 2px;
        }
    }

    #objetCoffreMaj {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;

        > div {
            cursor : pointer;
        }
    }

    #boutonMajCaseRuine {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;
        width           : 100%;
    }

    #retourAjaxMajCaseRuineObjet {
        margin-top : 5px;
    }

    #listingObjetMajCaseRuineObjet {
        padding : 5px;
        width   : 75%;
    }


}

#listingObjetsInRuine {
    display         : flex;
    flex-wrap       : wrap;
    justify-content : flex-start;
    margin          : auto;

    > div {
        cursor : pointer;
    }
}

#listingObjetsInCaseRuine {
    display         : flex;
    flex-wrap       : wrap;
    justify-content : flex-start;
    margin          : auto;
}

#zone_bouton_maj_objetCaseRuine {
    display         : flex;
    flex-wrap       : wrap;
    gap             : 10px;
    justify-content : flex-start;
    margin          : auto;

    > em {
        font-size : 10px;
    }
}

.caseCarteRuineEffacer {
    background-color : rgb(255, 0, 0);
}