.listCitoyensHV, .listCitoyensHVVivant, .listCitoyensHVMort, .lignCitoyenV, .listCitoyensVVivant {
    margin : auto;
    width  : 100%;

}


.listCitoyensHVVivant, .listCitoyensHVMort {
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
}

.lignCitoyenHV {
    border  : 1px solid var(--primary-border-color);
    display : flex;
    margin  : 0;
    width   : 24%;
}

.lignCitoyenV {
    border     : 1px solid var(--primary-border-color);
    display    : flex;
    font-size  : 0.8em;
    margin     : 0;
    min-height : 145px;
    width      : 100%;

    label {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : flex-start;
    }

}

.pseudoCitoyenHV a {
    color           : var(--primary-font-color);
    text-decoration : none;
}

.avatarCitoyenHV, .avatarCitoyenV {
    align-items : center;
    display     : flex;
    padding     : 0;
}

.avatarCitoyenHV {
    height : 50px;
    margin : 5px 5px;
    width  : 50px;

}

.avatarCitoyenV {
    height : 90px;
    margin : 5px 0;
    width  : 90px;
}

.avatarCitoyenHV img {
    border     : 1px solid var(--primary-border-color);
    margin     : auto;
    max-height : 50px;
    max-width  : 50px;
}

.avatarCitoyenV img {
    margin     : auto;
    max-height : 90px;
    max-width  : 90px;
}

.infoBulleJourMort {
    padding : 0;
    width   : 45px;
}

.infoBulleMessage {
    padding : 4px;
    width   : 400px;
}

.infoBulleJourMort span, .infoBulleMessage span {
    font-size : 0.8em;
    margin    : 0;
}

.jourMort {
    font-size      : 0.8em;
    vertical-align : middle;
}

.listCitoyensHV > p {
    text-decoration : underline;
}

.mortCitoyenHV {
    align-items     : center;
    display         : flex;
    height          : 20px;
    justify-content : flex-start;
    vertical-align  : middle;
    width           : 100%;

    .jourMort {
        display     : inline-block;
        height      : 20px;
        line-height : 20px;
        min-width   : 40px;
    }


    .nom_cleaneur_citoyen {
        font-weight : bold;
    }

    .cause_mort_citoyen {
        display : flex;
    }
}


.zoneAvatarCitoyenV {
    border-right : 1px solid var(--primary-border-color);
    width        : 150px;
}

.nomPouvHeros {
    color     : var(--primary-font-color);
    font-size : 0.8em;
}

.zoneAvatIconeCitoyenV {
    display : flex;
}

.avatarPouvCitoyenV {
    border-right : 1px solid var(--primary-border-color);
    height       : 121px;
    width        : 115px;
}

.avatarCitoyenV {
    width : 100%;
}

.positionCitoyenV {
    font-size : 0.8em;
}

.zonePseudoCitoyenV {
    align-items      : center;
    background-color : var(--tertiary-color);
    border-bottom    : 1px solid var(--primary-border-color);
    display          : flex;
    font-weight      : bold;
    height           : 23px;
    justify-content  : center;
    margin-left      : -1px;
    margin-right     : -1px;
    margin-top       : -1px;
}

.zoneIconCitoyenV {
    width : 35px;
}

.zoneIconCitoyenV div {
    display         : flex;
    height          : 19px;
    justify-content : center;
}

.zonePouvHeros {
    display         : flex;
    justify-content : center;
}

.firstLineCitoyen {
    border-top : 1px solid var(--primary-border-color);
}

.zonePouvHerosCitoyenV {
    align-content   : flex-start;
    border-right    : 1px solid var(--primary-border-color);
    display         : flex;
    flex-wrap       : wrap;
    justify-content : center;
    min-height      : 145px;
    padding         : 2px;
    width           : 249px;

    #pouvoirs_extend_citoyens {
        align-content   : flex-start;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;
    }


    > div.formVisible {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : space-evenly;
        padding-bottom  : 2px;


    }
}

.zoneAmeMaisonCitoyenV {
    align-content   : flex-start;
    border-right    : 1px solid var(--primary-border-color);
    display         : flex;
    flex-wrap       : wrap;
    justify-content : center;
    min-height      : 145px;
    padding         : 2px;
    width           : 249px;

    input[type="number"] {
        width : 32px;
    }

    div {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;
    }
}

.zoneInfoComplCitoyenV {
    align-content   : flex-start;
    border-right    : 1px solid var(--primary-border-color);
    display         : flex;
    flex-wrap       : wrap;
    justify-content : center;
    min-height      : 145px;
    padding         : 2px;
    width           : 140px;

    div {
        align-content   : flex-start;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;
        width           : 100%;

        > div {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : space-between;
            padding-bottom  : 2px;


            label {
                width : 90px;
            }

            select {
                width : 40px;
            }
        }
    }
}

.zoneCoffreDispoCitoyenV {
    align-content   : space-between;
    align-items     : flex-start;
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
    width           : 410px;

    .regroupementCoffre {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : flex-start;

        .zoneCoffre {
            align-content   : flex-start;
            align-items     : center;
            display         : flex;
            flex-wrap       : wrap;
            justify-content : flex-start;
            margin-right    : 10px;
            min-height      : 45px;
            width           : 360px;
        }
    }

    .infoMaj {
        width : 100%;

        > i {
            margin-left  : 5px;
            margin-right : 5px;
        }
    }
}

.maLigne {
    background-color : var(--myLine-color);
    border           : solid 1px var(--error-color);
}

.doubleChoixCit {
    width : 50%;

    label {
        width : 80px;
    }

    select {
        width : 40px;
    }
}


.simpleChoixCit {
    width : 100%;

    label {
        width : 160px;
    }

    select:not([id^="habitation_lvlMaison"]) {
        width : 40px;
    }

    select[id^="habitation_lvlMaison"] {
        width : 85px;
    }
}

.inputAmelio {
    width : 30px;
}

#compteurCitoyen {
    display   : flex;
    font-size : 0.9em;

}

#compteurJob, #compteurPouvoir, #compteurMaison {
    width : 33%;
}

.zoneTitreCompteurCitoyen, .zoneTitreFiltreCitoyen {
    display : flex;
}

.conteneurCompteurCitoyen {
    border-bottom : solid 1px var(--primary-border-color);
    border-left   : solid 1px var(--primary-border-color);
    display       : flex;
    flex-wrap     : wrap;
    height        : 106px;
    padding-top   : 3px;
    width         : 100%;
}

.conteneurCompteurCitoyen div {
    display         : flex;
    justify-content : space-between;
    padding-left    : 5px;
    padding-right   : 5px;
    width           : 47%;
}

.titreCompteurCitoyen, .titreFiltreCitoyen {
    border-left  : solid 1px var(--primary-border-color);
    border-right : solid 1px var(--primary-border-color);
    border-top   : solid 1px var(--primary-border-color);
    text-align   : center;
}

.titreCompteurCitoyen {
    width : 30%;
}

.titreFiltreCitoyen {
    width : 40%;
}


.borderBottomCitoyen {
    border-bottom : solid 1px var(--primary-border-color);
    width         : 70%;
}

.borderBottomCitoyenFiltre {
    border-bottom : solid 1px var(--primary-border-color);
    width         : 70%;
}

#finConteneurCompteur, #endFiltreCitoyen {
    border-right : solid 1px var(--primary-border-color);
}

#filtreCitoyen {
    display       : flex;
    font-size     : 0.8em;
    margin-bottom : 5px;
    margin-top    : 5px;
    width         : 100%;
}

.conteneurFiltreCitoyenParent {
    height : 190px;
    width  : 25%;
}

.conteneurFiltreCitoyen, .conteneurFiltreCitoyenDiv {
    border-bottom   : solid 1px var(--primary-border-color);
    border-left     : solid 1px var(--primary-border-color);
    display         : flex;
    flex-wrap       : wrap;
    height          : 170px;
    justify-content : space-between;
}

.conteneurFiltreCitoyen {
    align-content : flex-start;
}


.spanFiltreCitoyen {
    height : 18px;
    width  : 50%;
}

.spanFiltreCitoyenDiv {
    align-items     : center;
    display         : flex;
    height          : auto;
    justify-content : space-around;
    width           : 50%;
}

#frm-fDiv_APAG, #frm-fDiv_LvlRuine {
    min-width      : 30px;
    overflow       : hidden;
    padding-bottom : 0;
    padding-top    : 0;
    text-align     : center;
}


.titreZoneCitoyen {
    border-bottom : 1px solid var(--primary-border-color);
    margin        : auto;
    text-align    : center;
    width         : 100px;
}

#boutonGenerationCitoyen {
    display         : flex;
    justify-content : center;
    width           : 100%;
}

#boutonGestionListCitoyen {
    display         : flex;
    gap             : 30px;
    justify-content : center;
    width           : 100%;
}

#zoneListCitoyens {
    color : black;
    width : 100%;
}

#popUpCitoyens {
    height : 100px;
    width  : 400px;
}

.listCitoyensVVivantReduit {
    margin : auto;

    tr, td, th {
        border    : 1px solid var(--primary-border-color);
        font-size : 0.8rem;
    }

    thead {
        background-color : var(--tertiary-color);
        border           : 1px solid var(--primary-border-color);
        position         : sticky;
        top              : var(--top-decalage);
        z-index          : 1000;

        th {
            border   : 1px solid var(--primary-border-color);
            position : sticky;
            top      : 0;
            z-index  : 1000;
        }
    }

    tr.lignCitoyenV {
        font-size : 0.8em;
        height    : 20px;
        margin    : 0;
        width     : 100%;
    }

    .citoyen_tab_reduit_avatar {
        .img_cit_reduit {
            height : 30px;
            margin : auto;
            width  : 30px;
        }

        .img_cit_zoom {
            height : 100px;
            width  : 100px;
        }
    }

    .citoyen_tab_reduit_pseudo {
        width : 160px;
    }

    .citoyen_tab_reduit_input {
        width : 27px;
    }

    .citoyen_tab_reduit_icone {
        margin  : 0;
        padding : 0;
        width   : 18px;

        .icone_citoyen_centre {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : center;
        }
    }

    .citoyen_tab_reduit_select select, .citoyen_tab_reduit_selectHab select {
        appearance : none;
        font-size  : 0.8rem;
    }

    .citoyen_tab_reduit_select div, .citoyen_tab_reduit_input div {
        align-items     : center;
        display         : flex;
        justify-content : center;
    }

    .citoyen_tab_reduit_input input::-webkit-outer-spin-button,
    .citoyen_tab_reduit_input input::-webkit-inner-spin-button {
        -webkit-appearance : none;
        margin             : 0;
    }

    .citoyen_tab_reduit_input input[type=number] {
        -moz-appearance : textfield;
    }

    .citoyen_tab_reduit_input input {
        font-size  : 0.8rem;
        text-align : center;
        width      : 73%;
    }

    .citoyen_tab_reduit_select select {
        text-align : center;
        width      : 20px;
    }

    .citoyen_tab_reduit_selectHab select {
        text-align : center;
        width      : 65px;
    }

    .citoyen_tab_reduit_coffre {
        width : 280px;

        div.regroupementCoffre {
            align-content   : center;
            align-items     : center;
            display         : flex;
            justify-content : flex-start;

            .zoneCoffre {
                align-content   : center;
                align-items     : flex-start;
                display         : flex;
                flex-wrap       : wrap;
                justify-content : flex-start;
                width           : 85%;
            }

            .majCoffre {
                padding : 0;
                width   : 60px;
            }
        }


    }
}

.item_home {
    height : 30px;
    width  : 30px;
}

.maj_48 {
    color : var(--error-color);
}

.maj_24 {
    color : orange;
}

.maj_0 {
    color : var(--success-color);
}

#popUpMajCoffre {
    background-color : var(--primary-color);
    border           : 1px solid var(--primary-border-color);
    display          : flex;
    min-height       : 500px;
    width            : 900px;

    #zoneInfoCoffre {
        padding-left : 5px;
        width        : 25%;

        h2 {
            margin     : 3px 0;
            text-align : center;
            width      : 100%;
        }

        form > p {
            margin : 0 0 2px;
        }
    }

    #objetCoffreMaj {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;

        > div {
            cursor : pointer;
        }
    }

    #boutonMajCoffre {
        align-content   : center;
        align-items     : center;
        display         : flex;
        justify-content : center;
        width           : 100%;
    }

    #retourAjaxMajCoffre {
        margin-top : 5px;
    }

    #listingObjetMajCoffre {
        padding : 5px;
        width   : 75%;
    }


}

#ligneChamanGuide {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
}

.updated_cit_form {
    border : 2px solid var(--success-color);
}

.updated_cit_form_exp {
    color : var(--success-color);
}


.svg-font-color svg > path {
    fill : var(--ifm-font-color-base);
}

.queryBuilder {
    min-width : 420px;
}

// Styles for when "Use validation" option is selected
#filtreJson {

    border : 1px solid var(--primary-border-color);
    width  : 100%;

    div.btn-group {
        align-items     : center;
        display         : flex;
        justify-content : center;

        button {
            margin : 3px 10px;
        }
    }

    .queryBuilder {
        // Invalid rules
        .queryBuilder-invalid {
            // Purple background for empty text fields
            span.rule-value {
                border : 3px solid var(--error-color);
            }

        }
    }

    // Styles for when "Justified layout" option is selected
    .justifiedLayout {
        .queryBuilder {
            // Push the clone, lock, and remove buttons to the right edge
            .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
            .ruleGroup-addGroup + button.ruleGroup-lock,
            .ruleGroup-addGroup + button.ruleGroup-remove,
            .rule-operators + button.rule-cloneRule,
            .rule-operators + button.rule-lock,
            .rule-operators + button.rule-remove,
            .rule-value + button.rule-cloneRule,
            .rule-value + button.rule-lock,
            .rule-value + button.rule-remove,
            .control + button.rule-cloneRule,
            .control + button.rule-lock,
            .control + button.rule-remove,
            .chakra-select__wrapper + button.rule-cloneRule,
            .chakra-select__wrapper + button.rule-lock,
            .chakra-select__wrapper + button.rule-remove {
                margin-left : auto;
            }
        }
    }
}

.citoyen_checkbox {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-around;

    label {
        margin-left  : 3px;
        margin-right : 3px;
    }
}

.numCptCitoyen {
    padding-right : 40px;
}

.icone_citoyen_bloc {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

.pseudoMetierCitoyenHV {
    display : flex;
    gap     : 3px;
}

.competence-badge {
    align-items     : center;
    border-radius   : 10%;
    color           : var(--primary-font-color);
    cursor          : pointer;
    display         : flex;
    font-size       : 13px;
    font-weight     : bold;
    height          : 20px;
    justify-content : center;
    position        : relative;
    transition      : transform 0.2s;
    width           : 20px;
}

.competence-badge:hover {
    transform : scale(1.1);
}

.compact-badge {
    align-items     : center;
    border-radius   : 50%;
    cursor          : pointer;
    display         : flex;
    height          : 16px; /* Diamètre du badge */
    justify-content : center;
    position        : relative;
    width           : 16px; /* Diamètre du badge */
}

.badge-segment {
    border-radius : 50%;
    height        : 16px; // Ajustez selon la taille
    overflow      : hidden;
    width         : 16px; // Ajustez selon la taille
}

.badge-center {
    align-items      : center;
    background-color : rgba(255, 255, 255, 0.8);
    border-radius    : 50%;
    color            : #333;
    display          : flex;
    font-size        : 8px; /* Ajusté pour la taille réduite */
    font-weight      : bold;
    height           : 12px; /* Centre du badge, ajusté pour la taille */
    justify-content  : center;
    position         : absolute;
    text-align       : center;
    width            : 12px; /* Centre du badge, ajusté pour la taille */
}