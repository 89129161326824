#corpsAdminDefaut {
    display : flex;
}

#subContent {
    //background-color : var(--secondary-color);
    //color            : var(--primary-font-color);
    height : 100%;
    width  : 100%;
}

.adminCorps {
    > table {
        border          : 1px solid var(--primary-border-color);
        border-collapse : collapse;
        margin          : auto;
        text-align      : center;

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        th, td, tr {
            border : 1px solid var(--primary-border-color);
        }

        td {
            font-size : 0.8em;
            padding   : 3px;
        }
    }

    form {
        width : 100%;
    }
}

div#adminMenu {
    font-family : var(--primary-font-family), serif;
    font-size   : 1em;
    font-weight : bold;
    margin      : 0;
    padding     : 0;
    width       : 120px;

    ul {
        line-height : 40px; /* on définit une hauteur pour chaque élément */
        list-style  : none; /* on supprime le style par défaut de la liste */
        margin      : 0;
        padding     : 0; /* Pas de marge intérieure */
        text-align  : center; /* on centre le texte qui se trouve dans la liste */
    }

    a {
        background-color : var(--primary-color);
        border           : 1px solid var(--primary-border-color);
        border-radius    : var(--radius-value);
        box-shadow       : 2px 2px var(--shadow-color);
        display          : table-cell;
        height           : 40px;
        padding          : 0; /* Aucune marge intérieure */
        text-decoration  : none; /* on supprime le style par défaut des liens  */
        vertical-align   : middle;
        width            : 120px; /* Largeur */
    }

    a:hover {
        background-color : var(--tertiary-color);
        color            : var(--hover-font-color);
    }

}

.adminAction {
    display : flex;

    form {
        margin : 2px;
    }
}

.boutonFormAdmin {
    align-items     : center;
    display         : flex;
    justify-content : center;
    justify-items   : center;
    margin          : 2px;

    div {
        align-items     : center;
        display         : flex;
        justify-content : center;
        justify-items   : center;
        margin          : 2px;
        width           : 50%;
    }
}

.textAreaAlign > div {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : space-around;
    width           : 100%;
}

#admin-graph-theme {
    height : 500px;
    width  : 500px;
}

#admin-groupe-stats-graph-table {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : row-reverse;
    justify-content : center;
}

#admin-table-stats-theme {
    > table {
        border          : 1px solid var(--primary-border-color);
        border-collapse : collapse;
        margin          : auto;
        text-align      : center;

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        th, td, tr {
            border : 1px solid var(--primary-border-color);
        }

        td {
            font-size : 0.8em;
            padding   : 3px;
        }

        tbody tr:nth-child(odd) {
            background-color : var(--primary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--secondary-row-color);
        }
    }
}

.admin_generaux {
    text-align : center;

    h2 {
        margin-bottom : 5px;
    }

    #admin_generaux_btn {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 10px;
        justify-content : center;
    }

    #admin_maj_api_button {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 12px;
        justify-content : center;
    }

    #admin_maj_param_generaux {
        align-content   : center;
        align-items     : center;
        display         : flex;
        flex-direction  : column;
        gap             : 20px;
        justify-content : center;

        #param_generaux {
            align-items           : center;
            display               : grid;
            gap                   : 5px;
            grid-template-columns : 1fr 1fr;
            justify-content       : center;
            justify-items         : center;
        }

        #param_habil {
            align-content   : center;
            align-items     : center;
            display         : flex;
            flex-direction  : column;
            justify-content : center;

            h2 {
                margin : 0 0 5px;
            }

            table {
                tr, td, th {
                    border : 1px solid white;
                }

                thead tr {
                    background-color : var(--tertiary-color);
                }

                tbody tr:nth-child(even) {
                    background-color : var(--primary-row-color);
                }

                tbody tr:nth-child(odd) {
                    background-color : var(--secondary-row-color);
                }

                th.clef, td.clef {
                    width : 200px;
                }

                th.role, td.role {
                    width : 70px;
                }
            }
        }
    }
}

#stats_maj {
    align-content   : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
    margin-top      : 20px;

    table {
        border          : 1px solid var(--primary-border-color);
        border-collapse : collapse;
        margin          : auto;
        text-align      : center;

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        th, td, tr {
            border : 1px solid var(--primary-border-color);
            width  : 160px;
        }

        td {
            font-size : 0.8em;
            padding   : 3px;
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }
    }
}

@import "./ville/_villeInfo.scss";
@import "./generaux/_chantier.scss";