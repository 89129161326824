.ensCatItemListing {
    font-size : 0.9rem;
    width     : 100%;
}

.lignItemListing {
    align-items     : center;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : flex-start;
}

.catItemListing {
    font-weight : bold;
    text-align  : center;
    width       : 16%;
}

.tdItemListing {
    width : 84%;
}

#tabItemListing {
    border-collapse : collapse;
}

#tabItemListing td {
    border : 1px solid var(--primary-border-color);
}

#searchItemsListing {
    align-content   : center;
    align-items     : center;
    display         : flex;
    font-size       : 0.9rem;
    gap             : 5px;
    justify-content : center;
}