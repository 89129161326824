#encyclopedie_villes {
    margin : auto;
    width  : 1000px;

    > ul > li {
        cursor           : pointer;
        float            : left;
        line-height      : 26px; /* on définit une hauteur pour chaque élément */
        list-style       : none; /* on supprime le style par défaut de la liste */
        margin           : 0; /* ni extérieure */
        padding          : 0; /* Pas de marge intérieure */
        text-align       : center; /* on centre le texte qui se trouve dans la liste */
        width            : 200px;

        > button {
            width : 200px;
        }
    }
}

#ency_habs {
    width : 100%;;

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);

            tr {
                height : 35px;
            }
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

        .tab_ency_habs_icone {
            font-size : 0.8rem;
            width     : 45px;
        }

        td.tab_ency_habs_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_habs_nom {
            width : 140px;
        }

        td.tab_ency_habs_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_habs_ress {
            width : 350px;
        }

        td.tab_ency_habs_ress {
            div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-wrap       : wrap;
                justify-content : flex-start;
            }
        }

    }

    #entete_ency_habs_name {
        width : 120px;
    }
}

#ency_ups {
    width : 100%;;

    table {
        font-size : 0.9rem;
        margin    : auto;
        width     : 100%;

        td, tr, th {
            border     : 1px solid var(--primary-border-color);
            text-align : center;
        }

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);

            tr {
                height : 35px;
            }
        }

        tbody tr.odd-upHome {
            background-color : var(--secondary-row-color);
        }

        tbody tr.even-upHome {
            background-color : var(--primary-row-color);
        }

        .tab_ency_ups_icone {
            font-size : 0.8rem;
            width     : 45px;
        }

        td.tab_ency_ups_icone {
            > div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-direction  : column;
                justify-content : center;
            }

        }

        .tab_ency_ups_nom {
            width : 140px;
        }

        td.tab_ency_ups_nom {
            padding    : 4px;
            text-align : left;
        }

        .tab_ency_ups_ress {
            width : 350px;
        }

        td.tab_ency_ups_ress {
            div {
                align-content   : center;
                align-items     : center;
                display         : flex;
                flex-wrap       : wrap;
                justify-content : flex-start;
            }
        }

    }

    #entete_ency_ups_name {
        width : 120px;
    }
}

.item_ency {
    -webkit-align-content   : center;
    align-content           : center;
    align-items             : center;
    background-image        : url(../images/iconHordes/vide.svg);
    -webkit-box-pack        : end;
    display                 : -webkit-box;
    display                 : -ms-flexbox;
    display                 : -webkit-flex;
    display                 : flex;
    -ms-flex-pack           : end;
    font-size               : 0.9em;
    height                  : 16px;
    -webkit-justify-content : flex-end;
    justify-content         : flex-end;
    margin                  : 3px 1px;
    width                   : 35px;
}