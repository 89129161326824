#stats-graph-theme {
    height : 300px;
    width  : 300px;
}

#jobs-graph-theme, #typeMort-graph-theme, #typeVille-graph-theme {
    height : 300px;
    width  : 300px;
}

#power-graph-theme {
    height : 300px;
    width  : 300px;
}

#morts-graph-theme, #day_ville-graph-theme {
    height : 400px;
    width  : 1200px;
}

#stats-groupe-stats-graph-table, #power-groupe-stats-graph-table, #jobs-groupe-stats-graph-table, #morts-groupe-stats-graph-table, #typeMort-groupe-stats-graph-table, #day_ville-groupe-stats-graph-table, #typeVille-groupe-stats-graph-table {
    align-content   : center;
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    gap             : 10px;
    justify-content : center;
}

#stats-table-stats-theme, #power-table-stats-theme, #jobs-table-stats-theme, #typeMort-table-stats-theme, #day_ville-table-stats-theme, #typeVille-table-stats-theme {
    > table {
        border          : 1px solid var(--primary-border-color);
        border-collapse : collapse;
        margin          : auto;
        text-align      : center;

        thead {
            background-color : var(--tertiary-color);
            color            : var(--primary-font-color);
        }

        th, td, tr {
            border : 1px solid var(--primary-border-color);
        }

        td {
            font-size : 0.8em;
            padding   : 3px;
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }
    }
}

#statistique_site {
    margin : auto;
    width  : 1200px;

    > ul > li {
        cursor           : pointer;
        float            : left;
        line-height      : 26px; /* on définit une hauteur pour chaque élément */
        list-style       : none; /* on supprime le style par défaut de la liste */
        margin           : 0; /* ni extérieure */
        padding          : 0; /* Pas de marge intérieure */
        text-align       : center; /* on centre le texte qui se trouve dans la liste */
        width            : 250px;

        > button {
            width : 250px;
        }

    }

    div.container-tabs {
        background-color : var(--secondary-color);
        border           : 1px solid var(--primary-font-color);
        padding-top      : 15px;
        width            : 1198px;
    }

}

#theme-pouvoir-stats {
    align-content   : center;
    align-items     : flex-start;
    display         : flex;
    gap             : 10px;
    justify-content : center;
    margin-bottom   : 15px;
}

#theme-cities-part1 {
    align-content   : center;
    align-items     : flex-start;
    display         : flex;
    gap             : 10px;
    justify-content : center;
    margin-bottom   : 15px;
}

.stats-vertical-separator {
    background-color : white;
    width            : 1px;
}

#stats-user-seperator {
    height : 860px;
}

#stats-cities-seperator {
    height : 770px;
}

#stats-ruins-seperator {
    height : 770px;
}

#ruins_stats_1 {
    display : flex;

    > div:not(#stats-ruins-seperator) {
        margin-bottom : 10px;
        width         : 50%;

        h2 {
            margin     : 3px;
            text-align : center;
        }

        table {
            border : 1px solid var(--primary-border-color);
            margin : auto;

            tr, td, th {
                border : 1px solid var(--primary-border-color);
            }

            tfoot, thead {
                tr, td, th {
                    background-color : var(--tertiary-color);
                    border           : 2px solid var(--primary-border-color);
                }
            }

            tbody tr:nth-child(odd) {
                background-color : var(--secondary-row-color);
            }

            tbody tr:nth-child(even) {
                background-color : var(--primary-row-color);
            }

            td.numberStats {
                text-align : center;
            }
        }
    }
}

#building_stats_1 {
    display : flex;

    > div {
        margin-bottom : 10px;
        width         : 50%;

        h2 {
            margin     : 3px;
            text-align : center;
        }

        table {
            border : 1px solid var(--primary-border-color);
            margin : auto;

            tr, td, th {
                border : 1px solid var(--primary-border-color);
            }

            tfoot, thead {
                tr, td, th {
                    background-color : var(--tertiary-color);
                    border           : 2px solid var(--primary-border-color);
                }
            }

            tbody tr:nth-child(odd) {
                background-color : var(--secondary-row-color);
            }

            tbody tr:nth-child(even) {
                background-color : var(--primary-row-color);
            }

            td.numberStats {
                text-align : center;
            }
        }
    }
}