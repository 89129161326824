$enable-cssgrid: true;
@import "../../node_modules/bootstrap/scss/_functions.scss";

:root {
    --stats-bg-color       : #252525FF;
    --button-bg-img        : none;
    --entete-bg-img        : url(../img/MapAndCompass3.png) no-repeat;
    --html-bg-img          : url(../img/topography02hordes1.jpg);
    --primary-bg-img       : none;
    --map-bg-img-option    : url(../img/desert3.jpg);
    --gold-border-color    : #ffd700;
    --primary-border-color : #DDDDDDFF;
    --menu-button-bg-img   : none;
    --alert-color          : #FF6347FF;
    --background-color     : #00000000;
    --error-color          : #ff0000FF;
    --myLine-color         : rgba(35, 0, 180, 0.3);
    --primary-color        : #5C2B20FF;
    --secondary-color      : #7E4D2AFF;
    --shadow-color         : none;
    --special-color        : #47271CFF;
    --specifique-color     : #ff0000FF;
    --success-color        : #00f300FF;
    --tertiary-color       : #47271CFF;
    --top-decalage         : 64px;
    --hover-font-color     : #FFFFFFFF;
    --primary-font-color   : #FFFFFFFF;
    --tertiary-font-color  : #eba475FF;
    --primary-font-family  : Arial, Verdana, Helvetica;
    --title-font-family    : edo_szregular, serif;
    --primary-row-color    : #653A26FF;
    --secondary-row-color  : #8A5432FF;
    --radius-value         : 0px;
    --desktop-width        : 1300px;
}

@import "theme-light.scss";
@import "theme-dark.scss";
@import "theme-hordes.scss";
@import "theme-perso.scss";
@import "theme-vintage.scss";

// Basic
$rqb-spacing                            : 0.5rem !default;
$rqb-background-color                   : #3A416166 !default;
$rqb-border-color                       : var(--primary-border-color) !default;
$rqb-border-style                       : solid !default;
$rqb-border-radius                      : 0.25rem !default;
$rqb-border-width                       : 1px !default;

// Drag-and-drop
$rqb-dnd-hover-border-bottom-color      : rebeccapurple !default;
$rqb-dnd-hover-copy-border-bottom-color : #669933 !default;
$rqb-dnd-hover-border-bottom-style      : dashed !default;
$rqb-dnd-hover-border-bottom-width      : 2px !default;

// Branches
$rqb-branch-indent                      : $rqb-spacing !default;
$rqb-branch-color                       : $rqb-border-color !default;
$rqb-branch-width                       : $rqb-border-width !default;
$rqb-branch-radius                      : $rqb-border-radius !default;
$rqb-branch-style                       : $rqb-border-style !default;

/* Coloration spécifique */
@import "generality/_import_boots.scss";
$green-success                          : $green-600;
@import "generality/_import_style.scss";
