.jumpCorps {
    table {
        border          : 2px solid var(--primary-border-color);
        border-collapse : collapse;
        margin          : 5px auto auto;
        text-align      : center;
        width           : 1000px;

        tr, td {
            border : 1px solid var(--primary-border-color);
        }

        th {
            background-color : var(--tertiary-color);
            border           : 2px solid var(--primary-border-color);
        }

        tbody tr:nth-child(odd) {
            background-color : var(--secondary-row-color);
        }

        tbody tr:nth-child(even) {
            background-color : var(--primary-row-color);
        }

    }
}

.th_nomJump, .td_nomJump {
    width : 350px;
}

.td_nomJump {
    padding-left : 2px;
    text-align   : left;
}

.td_dateJump {
    width : 155px;
}

.td_typeJump {
    width : 70px;
}

.td_objectifgaJump {
    width : 100px;

    > span {
        align-content   : center;
        align-items     : center;
        display         : flex;
        gap             : 5px;
        justify-content : center;
    }
}

.td_orgaJump {
    width : 130px;
}

.td_lienInscipJump {
    width : 100px;
}

.td_masquageJump {
    width : 70px;
}

.th_commu, .td_commu {
    width : 22px;
}

.jumpEventMasque {
    display : none;
}


#popUpInscription {
    height : 140px;
    width  : 430px;
}

.bouton_masquer {
    display : none;
}

#aff_jump_event, #masque_jump_event {
    margin-left : 58px;
}

.modal_description {
    max-width : none !important;
    width     : 800px;
}

.modal_description_content {
    max-height : 450px !important;
}

#zone-btn-inscription {
    margin : auto;
    width  : 1000px;
}