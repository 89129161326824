html[data-theme='light'] {
    --stats-bg-color       : #FFFFFFFF;
    --button-bg-img        : none;
    --entete-bg-img        : url(../img/MapAndCompass3.png) no-repeat;
    --html-bg-img          : url(../img/topography02c.jpg);
    --primary-bg-img       : none;
    --map-bg-img-option    : url(../img/desert3.jpg);
    --gold-border-color    : #ffd700;
    --primary-border-color : #000000FF;
    --menu-button-bg-img   : none;
    --alert-color          : #FF6347FF;
    --background-color     : #00000000;
    --error-color          : #ff0000FF;
    --myLine-color         : #FFA4004C;
    --primary-color        : #fff2d4FF;
    --secondary-color      : #f7dcb1FF;
    --shadow-color         : none;
    --special-color        : #2d755bFF;
    --success-color        : #198754FF;
    --tertiary-color       : #339966FF;
    --hover-font-color     : #fffFFFFF;
    --primary-font-color   : #000000FF;
    --tertiary-font-color  : #2d755bFF;
    --primary-font-family  : Arial, Verdana, Helvetica;
    --title-font-family    : edo_szregular, serif;
    --primary-row-color    : #d6c3aeFF;
    --secondary-row-color  : #fff2d4FF;
    --radius-value         : 0px;
    --desktop-width        : 1300px;
}
